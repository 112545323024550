import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.css";
import { loginAPI, niveisGET } from "../../../api/api-portal";
import LoadingSpinner from "../../../Components/LoadingComponents/Loading";
import UseAuth from "../../../hooks/UseAuth";
import { connectToApi } from "../../../api/DinamicAPI";
import { getVersionManifest } from "../../../api/manifest";

export default function Login() {
  const { setAuth } = UseAuth();
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const userRef = useRef();
  const errRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [version, setVersion] = useState("");

  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  useEffect(() => {
    async function fetchVersion() {
      setVersion(await getVersionManifest());
    }
    document.title = "Login";
    fetchVersion();
    userRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    localStorage.removeItem("user");
    localStorage.removeItem("cargo");
    if (localStorage.getItem("apiUrl") == null) {
      await connectToApi();
    }
    const response = await loginAPI({ username, password });
    if(response.data){
      const niveis = await niveisGET(response.data.accessToken);
      niveis.forEach((level) => {
        if (String(level[0]) == response.data.user.level) {
          localStorage.setItem("cargo", level[1]);
        }
      });

      localStorage.setItem(
        "user",
        JSON.stringify([
          response.data.accessToken,
          response.data.user._id,
          username,
          response.data.user.name,
          response.data.user.level,
          response.data.user.email,
          response.data.user.equipe,
          response.data.user.codigo_representante,
          response.data.refreshToken,
        ])
      );

      setAuth({
        user: username,
        pwd: password,
        level: response.data.user.level,
        token: response.data.accessToken,
      });
      setUsername("");
      setPassword("");
      navigate("/home");
    }else{
      setIsLoading(false);
      try {
        setErrMsg(response.response.data.message);
      } catch (error) {
        setErrMsg("Servidor não está respondendo. Tente novamente."); 
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      <section className={styles.login_geral}>
        <div className={styles.container_login}>
          <span className={styles.titulo_login}>Entrar</span>
          <form onSubmit={handleSubmit}>
            <div className={styles.input_group}>
              <input
                className={styles.input_text}
                type="text"
                ref={userRef}
                placeholder="Usuário"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoComplete="username"
                disabled={isLoading}
              />
              <input
                className={styles.input_password}
                type="password"
                placeholder="Senha"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                disabled={isLoading}
              />
              <p
                ref={errRef}
                className="errmsg"
                id="errmsg"
                aria-live="assertive"
              >
                {errMsg}
              </p>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div className={styles.btn_geral}>
                  <input
                    className={styles.btn_entrar}
                    type="submit"
                    value="Entrar"
                    disabled={isLoading}
                  />
                </div>
              )}
            </div>
          </form>
          <footer className={styles.footer}>
            <span>&copy; ZIMΛ {version && <span>- v.{version}</span>}</span>
          </footer>
        </div>
      </section>
    </>
  );
}
