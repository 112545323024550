import styled from "styled-components";

export const ContainerTable = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.651);
  margin-top: 2rem;
  padding: 1em;
  border-radius: 0.5em;
  /* box-shadow: 0 4px 4px -2px #989898;
  -moz-box-shadow: 0 4px 4px -2px #989898;
  -webkit-box-shadow: 0 4px 4px -2px #989898; */
`;
export const TH = styled.th`
  padding: 1em 3em;
`;
export const ThFinal = styled.th`
  padding: 0.1em 1em;
`;
export const TD = styled.td`
  padding-top: 10px;
  padding-bottom: 10px;
`;
export const TdFinal = styled.td`
  padding-top: 10px;
  padding-bottom: 10px;
`;
