import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import NavBar from '../Navbar';
import './index.css'
import { axiosInstance } from '../../../api/axios';
import UseToken from '../../../hooks/UseToken';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)',
		width: '500px',
		height: '500px',
	},
};

const TabelaUsuarios = ({tkn}) => {
	const [logins, setLogins] = useState([]);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [usuarioSelecionado, setUsuarioSelecionado] = useState({
		nome: '',
		email: '',
		usuarios: []
	});
	const [novoLogin, setNovoLogin] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
	const [usuarioExcluir, setUsuarioExcluir] = useState(null);

	const openConfirmModal = (usuario) => {
		setUsuarioExcluir(usuario);
		setConfirmModalIsOpen(true);
	};

	const closeConfirmModal = () => {
		setUsuarioExcluir(null);
		setConfirmModalIsOpen(false);
	};

	const handleDeleteUser = async () => {
		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `inbursaweb/${usuarioExcluir._id}`,
			headers: {
				'Authorization': `Bearer ${tkn}`
			}
		};

		try {
			const response = await axiosInstance.request(config);
			console.log(JSON.stringify(response.data));
			toast.success('Usuário excluído com sucesso.');
			closeConfirmModal();
		} catch (error) {
			console.log(error);
			toast.error('Erro ao excluir o usuário. Por favor, tente novamente.');
			closeConfirmModal();
		}
	};

	const handleChangeNovoLogin = (e) => {
		setNovoLogin(e.target.value);
	};

	const handleAdicionarLogin = () => {
		if (novoLogin.trim() !== '') {
			// Clonando o objeto de usuário selecionado e adicionando o novo usuário
			const novoUsuarioSelecionado = { ...usuarioSelecionado };
			novoUsuarioSelecionado.usuarios.push(novoLogin);
			// Atualizando o estado com o novo usuário adicionado
			setUsuarioSelecionado(novoUsuarioSelecionado);
			// Limpar o campo de entrada
			setNovoLogin('');
		}
	};

	const listarLogins = async () => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: 'inbursaweb',
			headers: {
				'Authorization': `Bearer ${tkn}`
			}
		};

		try {
			const result = await axiosInstance(config).then(function (response) {
				var json = JSON.parse(JSON.stringify(response.data));
				return json;
			});

			return result;
		} catch (error) {
			console.error(error);
			return error;
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const loginsData = await listarLogins();
				setLogins(loginsData);
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();
	}, []);

	const openModal = (usuario) => {
		setUsuarioSelecionado(usuario);
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setUsuarioSelecionado(null);
		setModalIsOpen(false);
	};

	const handleSaveEdit = () => {
		let data = JSON.stringify(
			usuarioSelecionado
		);

		let config = {
			method: 'put',
			maxBodyLength: Infinity,
			url: `inbursaweb/${usuarioSelecionado._id}`,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${tkn}`
			},
			data: data
		};

		axiosInstance.request(config)
			.then((response) => {
				console.log(JSON.stringify(response.data));
				toast('Usuario adicionado com sucesso.')
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Ocorreu um erro: " + error
				);
			});

	}

	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
	};

	const filteredLogins = logins.filter(login =>
		login.nome.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<>
			<input
				type="text"
				placeholder="Pesquisar pela empresa"
				value={searchTerm}
				onChange={handleSearch}
				className='searchEmpresas'
			/>
			<Modal
				isOpen={confirmModalIsOpen}
				onRequestClose={closeConfirmModal}
				ariaHideApp={false}
				contentLabel="Confirmar Exclusão"
				style={{
					overlay: {
						backgroundColor: 'rgba(0, 0, 0, 0.5)'
					},
					content: {
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '300px',
						height: '50%',
						padding: '20px',
						borderRadius: '8px',
						boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
						backgroundColor: 'white',
						textAlign: 'center'
					}
				}}
			>
				{usuarioExcluir && (
					<div className="modal-content">
						<h1 style={{ fontSize: '24px', marginBottom: '20px' }}>Confirmar Exclusão</h1>
						<p style={{ fontSize: '16px', marginBottom: '30px' }}>Deseja realmente excluir o usuário {usuarioExcluir.nome}?</p>
						<div className="buttons" style={{ display: 'flex', justifyContent: 'center' }}>
							<button style={{ backgroundColor: '#ff4d4f', color: 'white', padding: '10px 20px', borderRadius: '4px', marginRight: '10px', border: 'none' }} onClick={handleDeleteUser}>Confirmar</button>
							<button style={{ backgroundColor: '#d9d9d9', padding: '10px 20px', borderRadius: '4px', border: 'none' }} onClick={closeConfirmModal}>Cancelar</button>
						</div>
					</div>
				)}
			</Modal>
			<div className='container_users'>
				<table>
					<thead>
						<tr>
							<th>Empresa</th>
							<th>E-mail</th>
							<th>Ações</th>
						</tr>
					</thead>
					<tbody>
						{filteredLogins.map(login => (
							<tr key={login.id}>
								<td>{login.nome}</td>
								<td>{login.email}</td>
								<td>
									<a onClick={() => openModal(login)}>Usuários</a>
									<a onClick={() => openConfirmModal(login)}>Excluir</a>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				ariaHideApp={false}
				contentLabel="Editar Usuário"
				style={customStyles}
			>
				{usuarioSelecionado && (
					<div className="modal-content">
						<h1>Editar Usuários</h1>
						<div className="form-group">
							<label htmlFor="nome">NOME:</label>
							<input type="text" id="nome" placeholder={usuarioSelecionado.nome} disabled />
						</div>
						<div className="form-group">
							<label htmlFor="email">E-MAIL:</label>
							<input type="email" id="email" defaultValue={usuarioSelecionado.email} disabled />
						</div>
						<div className="form-group">
							<label htmlFor="usuarios" style={{ fontStyle: 'italic' }}>USUÁRIOS (AGILUS)</label>
							<ul>
								{usuarioSelecionado.usuarios.map(login => (
									<li key={login}>{login}</li>
								))}
							</ul>
							<div className='adicionarLogin'>
								<input type="text" value={novoLogin} placeholder='Novo Usuario' onChange={handleChangeNovoLogin} />
								<button id="adicionarLoginBtn" onClick={handleAdicionarLogin}>+</button>
							</div>
						</div>
						<div className="buttons">
							<button id='salvarBtn' onClick={handleSaveEdit}>Salvar</button>
							<button onClick={closeModal}>Cancelar</button>
						</div>
					</div>
				)}
			</Modal >
		</>
	)
}

const NovoUsuario = ({tkn}) => {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [empresa, setEmpresa] = useState('');
	const [email, setEmail] = useState('');
	const [senha, setSenha] = useState('');
	const [usuarios, setUsuarios] = useState([]);
	const [novoUsuario, setNovoUsuario] = useState('');

	const handleAddUsuario = (e) => {
		e.preventDefault();
		if (novoUsuario.trim() !== '') {
			setUsuarios([...usuarios, novoUsuario]);
			setNovoUsuario('');
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const data = JSON.stringify({
			nome: empresa,
			email: email,
			senha: senha,
			usuarios: usuarios
		});

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: 'inbursaweb',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${tkn}`
			},
			data: data
		};

		try {
			const response = await axiosInstance.request(config);
			console.log(JSON.stringify(response.data));
			toast.success('Dados enviados com sucesso!');
		} catch (error) {
			console.log(error);
			toast.error('Erro ao enviar os dados. Por favor, tente novamente.');
		}
	};

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	return (
		<>
			<div className="NovoCliente">
				<button className='NovoClienteBtn' onClick={openModal}>Adicionar Novo Usuário +</button>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					ariaHideApp={false}
					contentLabel="Novo Usuário"
					style={customStyles}
				>
					<div className="NovoClienteModal">
						<h1>Adicionar Novo Usuário</h1>
						<form onSubmit={handleSubmit}>
							<input className="InputField" type="text" placeholder="Nome da Empresa" value={empresa} onChange={(e) => setEmpresa(e.target.value)} />
							<input className="InputField" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
							<input className="InputField" type="password" placeholder="Senha" value={senha} onChange={(e) => setSenha(e.target.value)} />
							<div className="AddUsuarioContainer">
								<input className="InputField" type="text" placeholder="Novo Usuário" value={novoUsuario} onChange={(e) => setNovoUsuario(e.target.value)} />
								<button className="AddUsuarioBtn" onClick={handleAddUsuario}>Adicionar Usuário</button>
							</div>
							<ul className="UsuariosList">
								{usuarios.map((usuario, index) => (
									<li key={index} className="UsuarioItem">
										<div className="UsuarioIcon">U</div>
										<span className="UsuarioNome">{usuario}</span>
									</li>
								))}
							</ul>
							<button className='EnviarBtn' type="submit">Enviar</button>
							<button className='FecharBtn' onClick={closeModal}>Fechar</button>
						</form>
					</div>
				</Modal>
			</div>
		</>
	);
};


const LoginParceiro = () => {
	const tkn = UseToken();

	return (
		<>
			<ToastContainer
				position="bottom-right"
				style={{ zIndex: 9999, position: "fixed" }}
			/>
			<NavBar />
			<div className='container_main'>
				<div className='titulo'>
					<h1>Login Parceiro</h1>
				</div>
				<NovoUsuario tkn={tkn}/>
				<TabelaUsuarios tkn={tkn}/>
			</div>
		</>
	)
}

export default LoginParceiro;
