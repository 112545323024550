import styled from "styled-components";

export const ContainerFullExtrato = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
`;

export const ContainerID = styled.fieldset`
  border: 1px solid #9a9a9a;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.17);
`;
export const ContainerDB = styled.fieldset`
  border: 1px solid #9a9a9a;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.17);
`;
export const ContainerMC = styled.fieldset`
  border: 1px solid #9a9a9a;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.17);
`;
export const ContainerIP = styled.fieldset`
  border: 1px solid #9a9a9a;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.17);
`;
export const ContainerCE = styled.fieldset`
  border: 1px solid #9a9a9a;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.17);
`;
export const ContainerRMC = styled.fieldset`
  border: 1px solid #9a9a9a;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.17);
`;
export const ContainerRCC = styled.fieldset`
  border: 1px solid #9a9a9a;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.17);
`;
export const IdFiliado = styled.legend`
  color: #9a9a9a;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0 5px;
`;
export const DadosBeneficio = styled.legend`
  color: #9a9a9a;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0 5px;
`;
export const MargemConsig = styled.legend`
  color: #9a9a9a;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0 5px;
`;
export const ContratoEmprestimo = styled.legend`
  color: #9a9a9a;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0 5px;
`;
export const ContratoEmprestimos = styled.legend`
  color: #9a9a9a;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0 5px;
`;
export const TitleRMC = styled.legend`
  color: #9a9a9a;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0 5px;
`;
export const TitleRCC = styled.legend`
  color: #9a9a9a;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0 5px;
`;
export const LinhaA = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;
export const LinhaBA = styled.div`
  display: flex;
  /* justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px; */
`;
export const LinhaB = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;
export const LinhaC = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;
export const LinhaD = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;
export const Nome = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const NB = styled.div`
  display: flex;
  margin-right: 7rem;
  span {
    font-weight: 800;
  }
`;
export const CPF = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const NomeMae = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const RG = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const Endereco = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const Bairro = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const Cidade = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;

export const Especie = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const Situacao = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const Caracter = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const RepLegal = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const Pensao = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const BloqEmprestimo = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const Elegivel = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const BaseCalc = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const MargemEmp = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const MargemRMC = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const MargemIRMC = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const BancoIP = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const Tipo = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const Ag = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;
export const Cc = styled.div`
  display: flex;
  span {
    font-weight: 800;
  }
`;

export const Ajuste = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin: 0 auto;
`;
export const Center = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;

  @media screen and (min-width: 425px) {
    flex-direction: row;
  }
`;
export const Emprestimo = styled.div`
  margin-bottom: 5px;
  margin-top: 10px;
  font-weight: 800;
`;
export const Banco = styled.div`
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 5px;
`;
export const PrimeiraParcela = styled.div`
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 5px;
`;
export const UltimaParcela = styled.div`
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 5px;
`;
export const SituacaoContratos = styled.div`
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 5px;
`;
export const ValorEmprestado = styled.div`
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 5px;
`;
export const ValorParcela = styled.div`
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 5px;
`;
export const DataInclusao = styled.div`
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 5px;
`;
export const Parcelas = styled.div`
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 5px;
`;
export const NumContrato = styled.div`
  span {
    font-weight: 800;
  }
`;
export const BancoRCC = styled.div`
  span {
    font-weight: 800;
  }
`;
export const DataInclusaoRMC = styled.div`
  span {
    font-weight: 800;
  }
`;
export const DataInclusao2 = styled.div`
  span {
    font-weight: 800;
  }
`;
export const SituacaoRCC = styled.div`
  span {
    font-weight: 800;
  }
`;
export const LimitRMC = styled.div`
  span {
    font-weight: 800;
  }
`;

export const Limite = styled.div`
  span {
    font-weight: 800;
  }
`;
