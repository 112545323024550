import { axiosInstance } from "../../../api/axios.js";
import UseToken from "../../../hooks/UseToken";
import { useState } from "react";
import NavBar from "../Navbar";
import styles from "./AtualizarFrase.module.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function AtualizarFrase() {
  const navigate = useNavigate();
  const [inputFrase, setInput] = useState("");

  useEffect(() => {
    document.title = "Atualizar Frase";
  }, []);

  async function onChangeSubmit(e) {
    e.preventDefault();
    var data = JSON.stringify({
      frase: inputFrase,
    });

    var config = {
      method: "put",
      url: "frase",
      headers: {
        Authorization: `Bearer ${UseToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    var atualizado = await axiosInstance(config)
      .then(function (_response) {
        return 1;
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.msg);
        return 0
    });

    if(atualizado) {
      localStorage.setItem("frase", inputFrase);
      navigate('/home');
    }
  }

  return (
    <>
      <NavBar />
      <section className={styles.containe_all_att}>
        <div className={styles.container_attFrase}>
          <div className={styles.container_title}>
            <h1 className={styles.title_att}>Atualizar Frase do Dia</h1>
          </div>
          <div className={styles.frase_put}></div>
          <form className={styles.form_att}>
            <input
              type="text"
              className={styles.fraseForm}
              placeholder="Escreva uma frase inspiradora aqui."
              value={inputFrase}
              onChange={(e) => setInput(e.target.value)}
            />
            <div className={styles.container_button}>
              <button
                className={styles.submit_button}
                onClick={onChangeSubmit}
                type="submit"
              >
                Atualizar
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
