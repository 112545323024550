// incluir.js

import { agilusAPI } from "../../../../api/axios";

// Função para enviar os dados para a API
export const postDados = async (formData) => {
  try {
    const url = 'customapi/executar?customApiId=34';
    const response = await agilusAPI.post(url, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return response.data; // Retorna os dados de resposta da API
  } catch (error) {
    console.error('Erro ao fazer a requisição POST:', error);
    throw error; // Lança o erro para ser tratado onde a função for chamada
  }
};
