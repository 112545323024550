import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import './style.css'

export default function modalDadosBancarioPromo(props) {
  const { dados } = props;
  return (
    <>
      <Dialog.Root>
        <Dialog.Trigger asChild>
          {/* <button className="Button violet">Dados Bancarios</button> */}
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Dialog.Title className="DialogTitle">Dados Bancarios</Dialog.Title>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="banco">
                Banco
              </label>
              <span className="Input" id="banco" defaultValue="Pedro Duarte">
                {dados.DADOS_BANCARIOS?.BANCO} - {dados.DADOS_BANCARIOS?.NOME_BANCO}
              </span>
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="Meio de Pagamento">
                Meio de Pag.
              </label>
              <span className="Input" id="Meio de Pagamento">
                {dados.DADOS_BANCARIOS?.NOME_TIPO_PAGTO}
              </span>
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="Agência">
                Agência
              </label>
              <span className="Input" id="Agência">
                {dados.DADOS_BANCARIOS?.AGENCIA_COMPLETO}
              </span>
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="Conta Corrente">
                CONTA
              </label>
              <span className="Input" id="Conta Corrente">
              {dados.DADOS_BANCARIOS?.CONTA_PAGTO}
              </span>
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="DIB">
                DIB
              </label>
              <span className="Input" id="DIB">
              {dados.DADOS_BANCARIOS?.DIB_FORMATADO}
              </span>
            </fieldset>
            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              <Dialog.Close asChild>
                <button className="Button green">Voltar</button>
              </Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <Cross2Icon />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}
