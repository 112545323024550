import React, { useRef, useState } from 'react';
import './style.css'; // Estilos do modal
import { uploadFile } from '../JS/uploadFile'; // Importa a função de upload
import ModalVend from '../ModalVend'; // Importa o novo modal de vendedores

const ModalDoc = ({ isOpen, onClose, onConfirm, documentoInfo, setResultado }) => {
  const fileInputFrente = useRef(null);
  const fileInputVerso = useRef(null);

  const [fileFrente, setFileFrente] = useState(null);
  const [fileVerso, setFileVerso] = useState(null);
  const [fileIdFrente, setFileIdFrente] = useState(null);
  const [fileIdVerso, setFileIdVerso] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalVendOpen, setModalVendOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileClick = (inputRef) => {
    inputRef.current.click();
  };

  const handleClose = () => {
    setFileFrente(null);
    setFileVerso(null);
    setErrorMessage("");
    setFileIdFrente(null);
    setFileIdVerso(null);
    onClose();
  };

  const handleConfirm = async () => {
    setLoading(true);
    setErrorMessage("");
    try {
      let idFrente, idVerso;

      if (fileFrente) {
        console.log("Enviando arquivo da frente...");
        idFrente = await uploadFile(fileFrente);
        setFileIdFrente(idFrente);
      } else {
        setErrorMessage("Por favor, selecione o arquivo da frente.");
        setLoading(false);
        return;
      }

      if (fileVerso) {
        console.log("Enviando arquivo do verso...");
        idVerso = await uploadFile(fileVerso);
        setFileIdVerso(idVerso);
      } else {
        setErrorMessage("Por favor, selecione o arquivo do verso.");
        setLoading(false);
        return;
      }

      if (!idFrente || !idVerso) {
        setErrorMessage("Erro ao enviar um dos arquivos. Tente novamente.");
        setLoading(false);
        return;
      }

      setResultado((prevResultado) => {
        const novoResultado = {
          ...prevResultado,
          informacoesobrigatorias: {
            ...prevResultado.informacoesobrigatorias,
            idFrente,
            idVerso,
          },
        };
        console.log("Resultado atualizado após upload de arquivos:", novoResultado);
        return novoResultado;
      });
      

      setModalVendOpen(true);
    } catch (error) {
      setErrorMessage("Erro ao enviar os arquivos. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleVendConfirm = (vendedorId) => {
    console.log("Vendedor selecionado:", vendedorId);
    setModalVendOpen(false);
    onConfirm({ fileIdFrente, fileIdVerso, vendedorId });
    handleClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Documentos</h2>

        <p>{documentoInfo || 'Informações do documento não disponíveis'}</p>

        <div className="file-upload-wrapper">
          <button
            className="file-upload-button"
            onClick={() => handleFileClick(fileInputFrente)}
            disabled={loading}
          >
            <span className="plus-icon">+</span>
          </button>
          <input
            type="file"
            ref={fileInputFrente}
            style={{ display: 'none' }}
            onChange={(e) => setFileFrente(e.target.files[0])}
            disabled={loading}
          />
          <p>Frente {fileFrente && `(${fileFrente.name})`}</p>
        </div>

        <div className="file-upload-wrapper">
          <button
            className="file-upload-button"
            onClick={() => handleFileClick(fileInputVerso)}
            disabled={loading}
          >
            <span className="plus-icon">+</span>
          </button>
          <input
            type="file"
            ref={fileInputVerso}
            style={{ display: 'none' }}
            onChange={(e) => setFileVerso(e.target.files[0])}
            disabled={loading}
          />
          <p>Verso {fileVerso && `(${fileVerso.name})`}</p>
        </div>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <div className="modal-actions">
          <button className="btn-cancel" onClick={handleClose} disabled={loading}>
            Cancelar
          </button>
          <button
            className="btn-confirm"
            onClick={handleConfirm}
            disabled={loading || !fileFrente || !fileVerso}
          >
            {loading ? 'Enviando...' : 'Confirmar'}
          </button>
        </div>

        {loading && (
          <div className="loading-spinner">
            <div className="spinner"></div>
            <p>Enviando arquivos, por favor aguarde...</p>
          </div>
        )}

        <ModalVend
          isOpen={isModalVendOpen}
          onClose={() => setModalVendOpen(false)}
          onConfirm={handleVendConfirm}
          setResultado={setResultado}
        />
      </div>
    </div>
  );
};

export default ModalDoc;

