import './style.css'

export function ProgressBar() {
  return (
    <section>
      <div className="progress">
        <div className="progress-bar"></div>
      </div>
    </section>
  )
}
