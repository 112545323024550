import React, { useState, useEffect } from "react";
import NavBar from "../../../pages/Navbar";
import styles from "./Crefisa.module.css";
// import {
//   estadoCivilGET,
//   estadoGET,
//   orgaoEmissorGET,
//   bancoGET,
//   valorPatrimonioGET,
//   tipoBeneficioGET,
//   cidadeGET,
// } from "../../../../api/metodosComplementares";
// import { consultarCliente } from "../../../../api/api-portal";
// import MaskedInput from "./js/Mask";
// import { reformatDate } from "./js/ConvertDataNasc";
// import { simulacaoValoresOperacaoPOST } from "../../../../api/api-facta";
// import UseToken from "../../../../hooks/UseToken";
// import Tabela from "../Facta/Tabela/Table";
// import { ContainerButton, Button } from "./ContainerButton";
// import LoadingSpinner from "../../../../Components/LoadingComponents/Loading";
// import styled from "styled-components";
// import LoadingSpinnerV2 from "../../../../Components/LoadingComponents/LoadingV2";

export default function DigitacaoCrefisa() {
  // useEffect(() => {
  // document.title = "Digitação do Crefisa";
  //   const fetchData = async () => {
  //     const tkn = UseToken();
  //     return [
  //       await estadoCivilGET(tkn),
  //       await estadoGET(tkn),
  //       await orgaoEmissorGET(tkn),
  //       await valorPatrimonioGET(tkn),
  //       await bancoGET(tkn),
  //       await tipoBeneficioGET(tkn),
  //     ];
  //   };

  //   fetchData()
  //     .then((result) => {
  //       if (result) {
  //         setEstadoCivil(result[0]);
  //         setEstados(result[1]);
  //         setOrgaosEmissores(result[2]);
  //         setValoresPatrimonio(result[3]);
  //         setBancos(result[4]);
  //         setTiposBene(result[5]);
  //       }
  //       setLoading(false);
  //     })
  //     .catch(console.error);
  // }, []);

  // useEffect(() => {
  //   if (estadoNatural) {
  //     const fetchCidades = async () => {
  //       const tkn = UseToken();
  //       return await cidadeGET(estadoNatural, tkn);
  //     };

  //     fetchCidades()
  //       .then((result) => {
  //         if (result) {
  //           setCidadesNATURAL(result);
  //         }
  //       })
  //       .catch(console.error);
  //   }
  // }, [estadoNatural]);

  // useEffect(() => {
  //   if (estado) {
  //     const fetchCidades = async () => {
  //       const tkn = UseToken();
  //       return await cidadeGET(estado, tkn);
  //     };

  //     fetchCidades()
  //       .then((result) => {
  //         if (result) {
  //           setCidades(result);
  //         }
  //       })
  //       .catch(console.error);
  //   }
  // }, [estado]);

  // useEffect(() => {
  //   if (cpf.includes("_") == false) {
  //     var cpf_alt = cpf.replaceAll(".", "");
  //     cpf_alt = cpf_alt.replaceAll("-", "");
  //     if (cpf_alt.length == 11) {
  //       setLoadingConsulta(true);
  //       const fetchCliente = async () => {
  //         const tkn = UseToken();
  //         return await consultarCliente(tkn, cpf_alt);
  //       };
  //       fetchCliente()
  //         .then((result) => {
  //           if (result) {
  //             setNome(result.cliente.NOME_CLIENTE);
  //             setRenda(result.cliente.RENDA);
  //             setSexo(result.cliente.SEXO);

  //             estadoCivis.map((estado) => {
  //               if (
  //                 estado[1].includes(result.cliente.ESTADO_CIVIL.toUpperCase())
  //               ) {
  //                 setEstadoCivilCliente(estado[0]);
  //               }
  //             });

  //             setCEP(result.cliente.CEP);
  //             setEndereco(result.cliente.ENDERECO);
  //             setBairro(result.cliente.BAIRRO);
  //             setNumEndereco(result.cliente.NUM_ENDERECO);
  //             setCelular(result.cliente.CELULAR);
  //             setDataNasc(result.cliente.DATA_NASCIMENTO);
  //             setAgencia(result.cliente.AGENCIA);

  //             estados.map((estado) => {
  //               if (estado[0] == result.cliente.UF) {
  //                 setEstado(estado[0]);
  //               }
  //             });

  //             orgaos.map((orgao) => {
  //               if (result.cliente.ORGAO_EMISSOR.includes(orgao[0])) {
  //                 setOrgaoEmissorRG(orgao[0]);
  //               }
  //             });

  //             setNomeMae(result.cliente.NOME_MAE);
  //             setNomePai(result.cliente.NOME_PAI);
  //             setDataExp(result.cliente.DATA_EMISSAO);
  //             setRG(result.cliente.RG);
  //             setBanco(result.cliente.BANCO_CLIENTE_CODIGO);
  //             setConta(result.cliente.CONTA_CORRENTE_CLIENTE);
  //             setMatricula(result.cliente.MATRICULA_BANCO_CLIENTE);
  //             if (result.cliente.CONTA_POUPANCA == 0) {
  //               setTipoCredito(1);
  //             } else {
  //               setTipoCredito(2);
  //             }
  //             setLoadingConsulta(false);
  //           } else {
  //             alert("Falha na consulta de dados do Cliente com CPF dado.");
  //           }
  //         })
  //         .catch(console.error);
  //     }
  //   }
  // }, [cpf]);

  // useEffect(() => {
  //   var cel = celular.replace(/\D/g, "");
  //   cel = cel.replace(/^(\d{3})(\d)/g, "($1) $2");
  //   cel = cel.replace(/(\d)(\d{4})$/, "$1-$2");
  //   setCelular(cel);
  // }, [celular]);

  // function handleContent() {
  //   setShowContent(!showContent);
  // }

  // function ExclusiveContent(infoTabela) {
  //   return (
  //     <Tabela
  //       setTab={infoTabela.setTab}
  //       valorAF={infoTabela.valorAF}
  //       cpf={infoTabela.cpf}
  //       dataNasc={infoTabela.dataNasc}
  //       prazo={infoTabela.prazo}
  //     />
  //   );
  // }

  // const handleFinalizarProposta = async (e) => {
  //   e.preventDefault();
  //   const tkn = UseToken();
  //   const cliente = {
  //     valorAF,
  //     cpf,
  //     prazo,
  //     dataNasc,
  //     nome,
  //     sexo,
  //     rg,
  //     estadoRG,
  //     orgaoEmissorRG,
  //     estadoCivil,
  //     dataExpedicao,
  //     estadoNatural,
  //     cidadeNatural,
  //     estado,
  //     cidade,
  //     endereco,
  //     numEndereco,
  //     bairro,
  //     cep,
  //     nacionalidade,
  //     paisOrigem,
  //     nomeMae,
  //     nomePai,
  //     celular,
  //     renda,
  //     valorPatrimonio,
  //     estado_beneficio,
  //     banco,
  //     agencia,
  //     conta,
  //     matricula,
  //     tipo_credito,
  //     tipo_beneficio,
  //     cliente_iletrado,
  //   };

  //   console.log("cliente", cliente);
  //   console.log("operacao", operacao);

  //   const result = await simulacaoValoresOperacaoPOST(tkn, operacao, cliente);
  //   if (result.code == "ERR_BAD_REQUEST") {
  //     alert(result.response.data.mensagem);
  //   } else {
  //     console.log(result);
  //   }
  // };

  return (
    <>
      <NavBar />
      <section className={styles.perfil_all}>
        <section className={styles.banner}>
          <div className={styles.container_profile}>
            <h1 className={styles.banner_title}>Digitação Crefisa</h1>
          </div>
        </section>
      </section>
      <section className={styles.crefisa_all}>
        <fieldset>
          <legend className={styles.titulo_crefisa}>Consulta De Cliente</legend>
          <div className={styles.container_crefisa_all}>
            <div className={styles.crefisa_container_all}>
              <div className={styles.inputs_consulta_Crefisa}>
                <div>
                  <label htmlFor="CPF"> CPF: </label>
                  <br />
                  <input
                    className={styles.input_crefisa}
                    type="number"
                    name=""
                    id="CPF"
                  />
                </div>

                <div>
                  <label htmlFor="ValorAf">Valor da AF:</label>
                  <br />
                  <input
                    className={styles.input_crefisa}
                    type="number"
                    min="0"
                    name=""
                    id="ValorAf"
                  />
                </div>

                <div>
                  <label htmlFor="">Prazo:</label>
                  <br />
                  <input
                    className={styles.input_crefisa}
                    type="number"
                    min="0"
                    value="Prazo"
                  />
                </div>

                <div>
                  <label htmlFor="Nascimento">Data de Nascimento</label>
                  <br />
                  <input
                    className={styles.input_crefisa}
                    type="date"
                    name=""
                    id="Nascimento"
                  />
                </div>
              </div>
              <div className={styles.container_btn_crefisa}>
                <button className={styles.btn_consultar_crefisa} type="submit">
                  {" "}
                  Consultar{" "}
                </button>
              </div>
            </div>
          </div>
        </fieldset>

        <div className={styles.container_fieldsetDois}>
          <fieldset>
            <legend className={styles.titulo_crefisa}>
              Cadastro De Proposta
            </legend>
            <div className={styles.container_crefisa_all}>
              <div className={styles.crefisa_container_all}>
                <div className={styles.nome_renda_tell}>
                  <div>
                    <label htmlFor="Nome">Nome:</label>
                    <br />
                    <input
                      className={styles.nome}
                      type="text"
                      name=""
                      id="Nome"
                    />
                  </div>

                  <div>
                    <label htmlFor="Renda">Renda:</label>
                    <br />
                    <input
                      className={styles.renda}
                      value="renda"
                      type="number"
                      min="0"
                      name=""
                      id="Renda"
                    />
                  </div>

                  <div className={styles.renda2}>
                    <label htmlFor="Celular">Celular:</label>
                    <br />
                    <input
                      className={styles.tell}
                      type="tell"
                      name=""
                      id="Celular"
                    />
                  </div>
                </div>
                <div className={styles.sexo_estadoCivil_iletrado_nacionalidade}>
                  <div>
                    <label htmlFor="Sexo">Sexo:</label>
                    <br/>
                    <select className={styles.dropdown_sexo} name="" id="Sexo">
                      <option hidden value=""></option>
                      <option value="">Feminino</option>
                      <option value="">Masculino</option>
                    </select>
                  </div>
                
                
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        <div className={styles.container_fieldsetTres}>
          <fieldset>
            <legend className={styles.titulo_crefisa}>
              Residencia Do Cleite
            </legend>
            <div className={styles.container_crefisa_all}>
              <div className={styles.crefisa_container_all}>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                  accusamus perferendis, temporibus neque, quis odit dolore
                  voluptate distinctio eos excepturi molestias dicta fugiat
                  pariatur. Voluptatibus?
                </p>
              </div>
            </div>
          </fieldset>
        </div>
        <div className={styles.container_fieldsetQuatro}>
          <fieldset>
            <legend className={styles.titulo_crefisa}>Dados Bancários</legend>
            <div className={styles.container_crefisa_all}>
              <div className={styles.crefisa_container_all}>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                  accusamus perferendis, temporibus neque, quis odit dolore
                  voluptate distinctio eos excepturi molestias dicta fugiat
                  pariatur. Voluptatibus?
                </p>
              </div>
            </div>
          </fieldset>
        </div>
      </section>
    </>
  );
}
