import {axiosInstance} from "./axios.js";

export async function cidadeGET(str_estado, token) {
  if(str_estado == ''){
    return false;
  }

  var config = {
    method: 'get',
    url: `facta/cidade?str_estado=${str_estado}`,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
  };

  try {
    var result = await axiosInstance(config).then(function (response) {
      var json = JSON.parse(JSON.stringify(response.data));
      if(json[7665000]){
        delete json[7665000];
      }
      return Object.entries(json);
    });
    return Object.entries(result);
  } catch (error) {
    console.log(error);
    return false;
  }
}
export async function estadoCivilGET(token) {
  var config = {
    method: 'get',
    url: 'agille/estadoCivil',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  };

  try {
    var result = await axiosInstance(config).then(function (response) {
      var json = JSON.parse(JSON.stringify(response.data.estado_civil));
        return Object.entries(json);
    });
    return result;
  } catch (error) {
    console.log(error);
  }
}
export async function estadoGET(token) {
  var config = {
    method: 'get',
    url: 'agille/estados',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  var result = await axiosInstance(config).then(function (response) {
    var json = JSON.parse(JSON.stringify(response.data.estado));
        return Object.entries(json);
  });

  return result;
}
export async function orgaoEmissorGET(token) {
  var config = {
    method: 'get',
    url: 'agille/orgao',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  var result = await axiosInstance(config).then(function (response) {
    var json = JSON.parse(JSON.stringify(response.data.orgao_emissor));
    return Object.entries(json);
  });

  return result;
}
export async function bancoGET(token) {
  var config = {
    method: 'get',
    url: 'agille/banco',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  var result = await axiosInstance(config).then(function (response) {
    var json = JSON.parse(JSON.stringify(response.data.lista_banco));
    return Object.entries(json);
  });

  return result;
}
export async function valorPatrimonioGET(token) {
  var config = {
    method: 'get',
    url: 'agille/valores',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  var result = await axiosInstance(config).then(function (response) {
    var json = JSON.parse(JSON.stringify(response.data.valor_patrimonio));
    return Object.entries(json);
  });

  return result;
}
export async function tipoBeneficioGET(token) {
  var config = {
    method: 'get',
    url: 'agille/tipos',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  var result = await axiosInstance(config).then(function (response) {
    var json = JSON.parse(JSON.stringify(response.data.tipo_beneficio));
    return Object.entries(json);
  });

  return result;
}
