import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function NaoAutorizado() {
  useEffect(() => {
    document.title = ('Não autorizado')
  }, [])

  return (
    <>
    <div id="error-page">
      <div className="content">
        <h2 className="header" data-text="403">
            403
        </h2>
        <h4 data-text="Opps! Página não pode ser acessada">Opps! Página não pode ser acessada</h4>
        <p>
          Desculpe, você não tem permissão para acessar esta página.
        </p>
        <div className="btns">
          <Link to="/home">Voltar para Home</Link>
        </div>
      </div>
    </div>
    </>
  );
}
