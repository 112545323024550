import React, { useState, useEffect } from 'react';
import './style.css'; // Estilos do modal
import { fetchProducts } from '../JS/tableQuali'; // Importando a função que faz o GET

const ModalSimulation = ({ isOpen, onClose, onConfirm, contratoNumber, handleSelectTabela, handleSalvarSelecionado }) => {
  const [loading, setLoading] = useState(false); // Estado para o loading
  const [products, setProducts] = useState([]); // Estado para armazenar os produtos da API
  const [selectedProduct, setSelectedProduct] = useState(""); // Estado para armazenar o produto selecionado
  const [errorMessage, setErrorMessage] = useState(""); // Estado para armazenar erros

  // Função para buscar os produtos da API ao abrir o modal
  useEffect(() => {
    if (isOpen) {
      const fetchProductData = async () => {
        try {
          const productData = await fetchProducts(); // Chama a API para buscar os produtos
          if (productData.length === 0) {
            setErrorMessage("Nenhuma tabela disponível."); // Exibe mensagem de erro se não houver produtos
          } else {
            setProducts(productData); // Armazena os produtos no estado
            setErrorMessage(""); // Limpa qualquer mensagem de erro anterior
          }
        } catch (error) {
          console.error("Erro ao buscar os produtos:", error);
          setErrorMessage("Erro ao buscar tabelas. Tente novamente mais tarde.");
        }
      };

      fetchProductData();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleConfirmClick = async () => {
    setLoading(true); // Ativa o loading
    try {
      const resultado = await handleSalvarSelecionado(); // Aguarda o salvamento e obtém o resultado atualizado
      console.log("Resultado salvo na simulação:", resultado); // Verifique o que está sendo retornado
      onConfirm(resultado); // Passa o resultado atualizado para a função de confirmação
    } catch (error) {
      console.error("Erro ao confirmar simulação:", error);
      setErrorMessage("Erro ao confirmar simulação. Tente novamente.");
    } finally {
      setLoading(false); // Desativa o loading ao final do processo
    }
  };
  

  // Atualiza o produto selecionado e também chama handleSelectTabela para passar o valor do produto
  const handleProductChange = (e) => {
    const selectedValue = e.target.value;  // Esse será o idAgille
    const selectedProduct = products.find(product => product.extraFields.idAgille === parseInt(selectedValue));
  
    if (selectedProduct) {
      setSelectedProduct(selectedValue);  // Armazena o idAgille
  
      // Passa o número do contrato, o product.code e o idAgille para handleSelectTabela
      handleSelectTabela(contratoNumber, {
        productCode: selectedProduct.code,
        idAgille: selectedProduct.extraFields.idAgille
      });
    }
  };
  
  

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Simulação</h2>

        {/* Exibindo o número do contrato selecionado */}
        <p>Contrato Número: {contratoNumber || 'Número não disponível'}</p>

        {/* Exibe mensagem de erro, se houver */}
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        {/* Seleção de tabela usando os produtos da API */}
        <div className="table-selection">
          <label htmlFor="table-select" className='label'>Selecione uma Tabela:</label>
          <select
            id="table-select"
            className="custom-select"
            onChange={handleProductChange} // Chama a função que lida com a seleção de produto
            value={selectedProduct} // Atualiza o valor do select com o produto selecionado
            disabled={loading || products.length === 0} // Desativa o select enquanto está carregando ou sem produtos
          >
            <option value="">Selecione uma tabela</option>
            {products.map((product) => (
              <option key={product.code} value={product.extraFields.idAgille}>
                {product.name}
              </option>
            ))}
          </select>
        </div>

        <div className="modal-actions">
          <button className="btn-cancel" onClick={onClose} disabled={loading}>Cancelar</button>
          <button className="btn-confirm" onClick={handleConfirmClick} disabled={loading || products.length === 0}>
            {loading ? 'Confirmando...' : 'Confirmar'}
          </button>
        </div>

        {loading && (
          <div className="loading-spinner">
            {/* Aqui você pode usar um spinner CSS ou um GIF */}
            <div className="spinner"></div>
            <p>Processando, por favor aguarde...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalSimulation;
