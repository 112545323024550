import React, { useState, useEffect } from 'react';
import './style.css';

const ModalComplemento = ({ isOpen, onClose, onConfirm, setResultado }) => {
  const [ddd, setDdd] = useState('');
  const [telefone, setTelefone] = useState('');
  const [rg, setRg] = useState(''); // Estado para o RG
  const [email, setEmail] = useState('');
  const [dtEmissao, setEmissao] = useState('');
  const [orgaoExpedidor, setExpedidor] = useState('SSP/SP'); // Estado inicial para o select
  const [erro, setErro] = useState('');

  // Função para permitir apenas números e limitar a dois caracteres no campo DDD
  const handleDddChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 2) {
      setDdd(value);
    }
  };

  // Função para permitir apenas números e garantir exatamente 9 caracteres no campo Telefone
  const handleTelefoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove qualquer caractere não numérico
    if (value.length <= 9) { // Garante no máximo 9 caracteres
      setTelefone(value);
    }
  };

  // Handler para o campo RG (permitir letras e números, mas limitado a 9 caracteres)
  const handleRgChange = (e) => {
    const value = e.target.value; // Permitir letras e números
    if (value.length <= 9) { // Limita a 9 caracteres
      setRg(value);
    }
  };

  // Handler para o campo Email
  const handleEmailChange = (e) => {
    setEmail(e.target.value); // Atualiza o estado com o valor do email
  };

  // Handler para o campo Data de Emissão
  const handleEmissaoChange = (e) => {
    setEmissao(e.target.value); // Atualiza o estado com o valor da data de emissão
  };

  // Handler para o campo Órgão Expedidor
  const handleExpedidorChange = (e) => {
    setExpedidor(e.target.value); // Atualiza o estado com o valor do órgão expedidor
  };

  const handleConfirm = () => {
    // Validação separada para cada campo
    if (!ddd) {
      setErro('Por favor, preencha o campo DDD.');
      return;
    }
    if (telefone.length !== 9) { // Garante que o telefone tenha exatamente 9 dígitos
      setErro('O telefone deve ter exatamente 9 dígitos.');
      return;
    }
    if (rg.length !== 9) { // Garante que o RG tenha exatamente 9 caracteres
      setErro('O RG deve ter exatamente 9 caracteres.');
      return;
    }

    // Limpa a mensagem de erro após a validação
    setErro('');

    // Verifica se setResultado é uma função antes de chamá-la
    if (typeof setResultado === 'function') {
      setResultado((prevResultado) => {
        const novoResultado = {
          ...prevResultado,
          informacoesobrigatorias: {
            ...prevResultado.informacoesobrigatorias,
            ddd,
            telefone,
            rg,
            email,
            dtEmissao,
            orgaoExpedidor,
          },
        };
        console.log('Resultado atualizado após complemento:', novoResultado);
        return novoResultado;
      });
    } else {
      console.error('setResultado não é uma função');
    }

    console.log('Complemento adicionado:', { ddd, telefone, rg, email, dtEmissao, orgaoExpedidor });

    // Chama a função onConfirm para realizar qualquer outra ação
    onConfirm({ ddd, telefone });

    // Fecha o modal após confirmar
    onClose();
  };

  // Limpa os campos quando o modal é fechado ou aberto
  useEffect(() => {
    if (isOpen) {
      setDdd('');
      setTelefone('');
      setRg('');
      setEmail('');
      setEmissao('');
      setExpedidor('SSP/SP'); // Valor inicial do select
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Informações de Contato</h2>

        <div className="input-group">
          <label htmlFor="ddd">DDD:</label>
          <input
            type="text"
            id="ddd"
            value={ddd}
            onChange={handleDddChange}
            placeholder="Digite o DDD"
            maxLength="2"
            className={erro && !ddd ? 'input-error' : ''}
            aria-label="Campo para inserir o código DDD"
          />
        </div>

        <div className="input-group">
          <label htmlFor="telefone">Telefone:</label>
          <input
            type="text"
            id="telefone"
            value={telefone}
            onChange={handleTelefoneChange}
            placeholder="Digite o telefone"
            maxLength="9"
            className={erro && telefone.length !== 9 ? 'input-error' : ''}
            aria-label="Campo para inserir o número de telefone"
          />
        </div>

        <div className="input-group">
          <label htmlFor="rg">RG:</label>
          <input
            type="text"
            id="rg"
            value={rg}
            onChange={handleRgChange} // Agora permite letras e números
            placeholder="Digite o RG"
            maxLength="9" // Garante que o usuário não possa digitar mais de 9 caracteres
            className={erro && rg.length !== 9 ? 'input-error' : ''}
          />
        </div>

        <div className="input-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email" // Tipo de input "email" para validação automática
            id="email"
            value={email}
            onChange={handleEmailChange} // Handler para o campo Email
            placeholder="Digite o email"
            className={erro && !email ? 'input-error' : ''}
          />
        </div>

        <div className="input-group">
          <label htmlFor="Emissao">Data Emissão:</label>
          <input
            type="date" // Tipo de input date para exibir o calendário
            id="Emissao"
            value={dtEmissao}
            onChange={handleEmissaoChange} // Handler para o campo Data de Emissão
            placeholder="Digite a Data de Emissão"
          />
        </div>

        {/* Select para Órgão Expedidor */}
        <div className="input-group">
          <label htmlFor="expedidor">Órgão Expedidor:</label>
          <select
            id="expedidor"
            value={orgaoExpedidor}
            onChange={handleExpedidorChange} // Handler para o campo Órgão Expedidor
          >
            <option value="SSP/SP">SSP/SP</option>
            <option value="IFP">IFP</option>
            <option value="IIPM">IIPM</option>
            <option value="DPT">DPT</option>
          </select>
        </div>

        {erro && <p className="error-message">{erro}</p>}

        <div className="modal-actions">
          <button className="btn-cancel" onClick={onClose}>
            Cancelar
          </button>
          <button
            className="btn-confirm"
            onClick={handleConfirm}
            disabled={!ddd || telefone.length !== 9 || rg.length !== 9} // Desabilita o botão até que os campos estejam preenchidos corretamente
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalComplemento;