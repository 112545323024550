import axios from 'axios';

// URL para upload de arquivos
const url = "https://api.simulator.agillecred.com.br/files";
const apiKey = "superseguro";

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-api-key': apiKey
      }
    });
    return response.data.id; // Retorna o ID do arquivo
  } catch (error) {
    console.error("Erro ao fazer upload do arquivo:", error);
    throw error;
  }
};
