import { useLocation, Navigate, Outlet } from "react-router-dom";
import UseToken from "../hooks/UseToken";
import UseLevel from "../hooks/UseLevel";

const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();

  try {
    var t = UseToken();
    var l = UseLevel();
  } catch (error) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return !!t && allowedRoles.includes(l) ? (
    <Outlet />
  ) : (
    <Navigate to="/naoAutorizado" state={{ from: location }} replace />
  );
};

export default RequireAuth;
