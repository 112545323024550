import React, { useState } from "react";
import NavBar from "../Navbar";
import styles from "./Home.module.css";
import { useEffect } from "react";
import Feed from "../../../Components/News/feed";
import { axiosInstance } from "../../../api/axios.js";
import UseToken from "../../../hooks/UseToken";
import ObjetoFrase from "../../../Components/frase/frase";

export default function Home() {
  const [posts, setPosts] = useState([]);
  const [frase, setFrase] = useState("");
  const [carregar, setCarregar] = useState(false);

  async function fetchFrase() {
    if(!localStorage.getItem("frase")){
      var config = {
        method: "get",
        url: "frase",
        headers: {
          Authorization: `Bearer ${UseToken()}`,
        },
      };

      await axiosInstance(config)
        .then(function (response) {
          setFrase(response.data.frase);
          localStorage.setItem("frase", response.data.frase);
        })
        .catch(function (error) {
          console.log(error);
        });
    }else{
      setFrase(localStorage.getItem("frase"));
    }
  }

  async function fetchFeed() {
    var config = {
      method: "get",
      url: "feed",
      headers: {
        Authorization: `Bearer ${UseToken()}`,
      },
    };

    await axiosInstance(config)
      .then(function (response) {
        setPosts(JSON.parse(JSON.stringify(response.data.posts)));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    setCarregar(true);
    document.title = "Home";
    fetchFrase();
    fetchFeed();
    setCarregar(false);
  }, []);

  return (
    <>
      <NavBar carregar={carregar}/>
      <section className={styles.home_geral}>
        <div className={styles.container_home}>
          <ObjetoFrase frase={frase} />
          <Feed posts={posts} />
        </div>
      </section>
    </>
  );
}
