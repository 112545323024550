import React, { useState, useEffect } from "react";
import NavBar from "../../../pages/Navbar";
import styles from "./Facta2.module.css";
import {
  estadoCivilGET,
  estadoGET,
  orgaoEmissorGET,
  bancoGET,
  valorPatrimonioGET,
  tipoBeneficioGET,
  cidadeGET,
} from "../../../../api/metodosComplementares";
import { consultarCliente } from "../../../../api/api-portal";
import UseToken from "../../../../hooks/UseToken";
import Tabela from "../Facta/Tabela/Table";
import MaskedInput from "../Facta/js/Mask";
import {
  cadastroDadosPessoaisPOST,
  cadastroPropostaPOST,
  simulacaoValoresOperacaoPOST,
} from "../../../../api/api-facta";
import LoadingSpinner from "../../../../Components/LoadingComponents/Loading";
import LoadingSpinnerV2 from "../../../../Components/LoadingComponents/LoadingV2";

export default function Facta() {
  const [showContent, setShowContent] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingConsulta, setLoadingConsulta] = useState(false);

  // dados complementares
  const [estadoCivis, setEstadoCivil] = useState([]);
  const [estados, setEstados] = useState([]);
  const [orgaos, setOrgaosEmissores] = useState([]);
  const [valores, setValoresPatrimonio] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [tipos, setTiposBene] = useState([]);
  const [cidadesNATURAL, setCidadesNATURAL] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [operacao, setOperacaoProposta] = useState([]);

  // Dados do Cliente
  const [cpf, setCPF] = useState("");
  const [valor, setValor] = useState("");
  const [prazo, setPrazo] = useState("84");
  const [dataNasc, setDataNasc] = useState("");
  const [nome, setNome] = useState("");
  const [sexo, setSexo] = useState("");
  const [estadoCivil, setEstadoCivilCliente] = useState("");
  const [rg, setRG] = useState("");
  const [estadoRG, setEstadoRG] = useState("");
  const [orgaoEmissorRG, setOrgaoEmissorRG] = useState("");
  const [dataExpedicao, setDataExp] = useState("");
  const [estadoNatural, setEstadoNatural] = useState("");
  const [cidadeNatural, setCidadeNatural] = useState("");
  const [nacionalidade, setNacionalidade] = useState("1");
  const [paisOrigem] = useState("26");
  const [celular, setCelular] = useState("");
  const [renda, setRenda] = useState("5000");
  const [cep, setCEP] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numEndereco, setNumEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [nomeMae, setNomeMae] = useState("");
  const [nomePai, setNomePai] = useState("");
  const [valorPatrimonio, setValorPatrimonio] = useState("");
  const [cliente_iletrado, setIletrado] = useState("N");
  const [banco, setBanco] = useState("");
  const [agencia, setAgencia] = useState("");
  const [conta, setConta] = useState("");
  const [matricula, setMatricula] = useState("");
  const [tipo_credito, setTipoCredito] = useState("");
  const [estado_beneficio, setEstadoBene] = useState("");
  const [tipo_beneficio, setTipoBeneficio] = useState("");
  const [produto, setProduto] = useState(0);
  const [opcao_valor, setOpcaoValor] = useState(0);
  const [tipo_operacao, setTipoOperacao] = useState(0);
  const [validacao, setValidacao] = useState([]);

  // Ao iniciar página, atualizar dropdowns
  useEffect(() => {
    document.title = "Digitação do Facta";
    const fetchData = async () => {
      const tkn = UseToken();
      return [
        await estadoCivilGET(tkn),
        await estadoGET(tkn),
        await orgaoEmissorGET(tkn),
        await valorPatrimonioGET(tkn),
        await bancoGET(tkn),
        await tipoBeneficioGET(tkn),
      ];
    };

    fetchData()
      .then((result) => {
        if (result) {
          setEstadoCivil(result[0]);
          setEstados(result[1]);
          setOrgaosEmissores(result[2]);
          setValoresPatrimonio(result[3]);
          setBancos(result[4]);
          setTiposBene(result[5]);
        }
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  // Atualizar dropdown de cidade natural
  useEffect(() => {
    if (estadoNatural) {
      const fetchCidades = async () => {
        const tkn = UseToken();
        return await cidadeGET(estadoNatural, tkn);
      };

      fetchCidades()
        .then((result) => {
          if (result) {
            setCidadesNATURAL(result);
          }
        })
        .catch(console.error);
    }
  }, [estadoNatural]);

  // Atualizar dropdown de cidade
  useEffect(() => {
    if (estado) {
      const fetchCidades = async () => {
        const tkn = UseToken();
        return await cidadeGET(estado, tkn);
      };

      fetchCidades()
        .then((result) => {
          if (result) {
            setCidades(result);
          }
        })
        .catch(console.error);
    }
  }, [estado]);

  // Realizar consulta de cliente com CPF
  useEffect(() => {
    if (cpf.includes("_") === false) {
      var cpf_alt = cpf.replaceAll(".", "");
      cpf_alt = cpf_alt.replaceAll("-", "");
      if (cpf_alt.length === 11) {
        setLoadingConsulta(true);
        const fetchCliente = async () => {
          const tkn = UseToken();
          return await consultarCliente(tkn, cpf_alt);
        };
        fetchCliente()
          .then((result) => {
            if (result) {
              setNome(result.cliente.NOME_CLIENTE);
              setRenda(result.cliente.RENDA ? result.cliente.RENDA : "5000");
              setSexo(result.cliente.SEXO);

              estadoCivis.forEach((estado) => {
                if (
                  estado[1].includes(result.cliente.ESTADO_CIVIL.toUpperCase())
                ) {
                  setEstadoCivilCliente(estado[0]);
                }
              });

              setCEP(result.cliente.CEP);
              setEndereco(result.cliente.ENDERECO);
              setBairro(result.cliente.BAIRRO);
              setNumEndereco(result.cliente.NUM_ENDERECO);
              setCelular(result.cliente.CELULAR);
              setDataNasc(result.cliente.DATA_NASCIMENTO);
              setAgencia(result.cliente.AGENCIA);

              estados.forEach((estado) => {
                if (estado[0] === result.cliente.UF) {
                  setEstado(estado[0]);
                }
              });

              orgaos.forEach((orgao) => {
                if (result.cliente.ORGAO_EMISSOR.includes(orgao[0])) {
                  setOrgaoEmissorRG(orgao[0]);
                }
              });

              setNomeMae(result.cliente.NOME_MAE);
              setNomePai(result.cliente.NOME_PAI);
              setDataExp(result.cliente.DATA_EMISSAO);
              setRG(result.cliente.RG);
              setTipoBeneficio(result.cliente.ESPECIE);
              setBanco(result.cliente.BANCO_CLIENTE_CODIGO);
              setConta(result.cliente.CONTA_CORRENTE_CLIENTE);
              setMatricula(result.cliente.MATRICULA_BANCO_CLIENTE);
              if (result.cliente.CONTA_POUPANCA === 0) {
                setTipoCredito(1);
              } else {
                setTipoCredito(2);
              }
              // comentar para ficar aparecendo o loadin
              setLoadingConsulta(false);
            } else {
              // comentar para ficar aparecendo o loadin
              setLoadingConsulta(false);
              alert("Falha na consulta de dados do Cliente com CPF dado.");
            }
          })
          .catch(console.error);
      }
    }
  }, [cpf]);

  // Formatar Celular, com DDD
  useEffect(() => {
    var cel = celular.replace(/\D/g, "");
    cel = cel.replace(/^(\d{3})(\d)/g, "($1) $2");
    cel = cel.replace(/(\d)(\d{4})$/, "$1-$2");
    setCelular(cel);
  }, [celular]);

  // function handleContent() {
  //   setShowContent(!showContent);
  // }

  // function ExclusiveContent(infoTabela) {
  //   if (infoTabela)
  //     return (
  //       <Tabela
  //         setTab={infoTabela.setTab}
  //         valorAF={infoTabela.valorAF}
  //         cpf={infoTabela.cpf}
  //         dataNasc={infoTabela.dataNasc}
  //         prazo={infoTabela.prazo}
  //       />
  //     );
  // }

  const handleFinalizarProposta = async (e) => {
    e.preventDefault();
    if (
      e.nativeEvent.submitter.textContent != "Fechar" &&
      operacao.length != 0
    ) {
      const tkn = UseToken();
      const cliente = {
        valor,
        cpf,
        prazo,
        dataNasc,
        nome,
        sexo,
        rg,
        estadoRG,
        orgaoEmissorRG,
        estadoCivil,
        dataExpedicao,
        estadoNatural,
        cidadeNatural,
        estado,
        cidade,
        endereco,
        numEndereco,
        bairro,
        cep,
        nacionalidade,
        paisOrigem,
        nomeMae,
        nomePai,
        celular,
        renda,
        valorPatrimonio,
        estado_beneficio,
        banco,
        agencia,
        conta,
        matricula,
        tipo_credito,
        tipo_beneficio,
        cliente_iletrado,
      };

      console.log("cliente", cliente);
      console.log("operacao", operacao);

      const errors = [
        "ERR_NETWORK",
        "ERR_BAD_REQUEST",
        "ERR_FR_TOO_MANY_REDIRECTS",
      ];

      const result = await simulacaoValoresOperacaoPOST(tkn, operacao, cliente);
      if (errors.includes(result.code)) {
        console.log(result.response.data.mensagem);
      } else {
        console.log("ID-Simulador");
        console.log(result.id);
        const result_cliente = await cadastroDadosPessoaisPOST(
          tkn,
          result.id,
          cliente
        );
        if (errors.includes(result_cliente.code)) {
          console.log(result_cliente.code);
          console.log(result_cliente.response.data.error.message);
        } else {
          console.log("ID-Cliente");
          console.log(result_cliente);
          const proposta = await cadastroPropostaPOST(
            tkn,
            result.id,
            result_cliente.codigo_cliente
          );
          if (errors.includes(proposta.code)) {
            console.log(proposta.code);
            console.log(proposta.response.data.error.message);
          } else {
            console.log("ID-Proposta");
            console.log(proposta.codigo);
          }
        }
      }
    }
  };

  return (
    <>
      <NavBar />
      <section className={styles.banner}>
        <div className={styles.container_profile}>
          <h1 className={styles.banner_title}>Digitação Facta</h1>
        </div>
      </section>

      {/* container Cosulta de cliente */}
      <section className={styles.container_section}>
        <div className={styles.typeProducts}>
          <div>
            <label htmlFor="select-produto">Produto</label>
            <select
              required
              onChange={(e) => setProduto(e.target.value)}
              name="Escolha de Produto"
              id="select_produto"
              value={produto}
            >
              <option value="" hidden>
                {" "}
                Produto{" "}
              </option>
              <option value={1}> INSS </option>
              <option value={2}> INSS Cartão Benefício </option>
              <option value={3}> Auxílio Brasil </option>
            </select>
          </div>
          <div>
            <label htmlFor="select_opcao_valor">Valor</label>
            <select
              required
              onChange={(e) => setOpcaoValor(e.target.value)}
              name="Opção de Valor"
              id="select_opcao_valor"
              value={opcao_valor}
            >
              <option value="" hidden>
                {" "}
                Opção de Valor{" "}
              </option>
              <option value={1}> Contrato </option>
              <option value={2}> Parcela </option>
            </select>
          </div>
          {produto == 1 ? (
            <div>
              <label htmlFor="select_tipo_operacao">Tipo Operação</label>
              <br />
              <select
                required
                onChange={(e) => setTipoOperacao(e.target.value)}
                name="Tipo de Operação"
                id="select_tipo_operacao"
                value={tipo_operacao}
              >
                <option value="" hidden>
                  {" "}
                  Tipo de Operação{" "}
                </option>
                <option value={13}> Novo Digital </option>
                <option value={27}> Margem Compl. </option>
              </select>
            </div>
          ) : (
            <span></span>
          )}
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (produto == 1) & (opcao_valor !== 0) & (tipo_operacao !== 0) ||
          (produto == 2 || produto == 3) & (opcao_valor !== 0) ? (
          <section className={styles.container_all_banco}>
            <div className={styles.container_title_consulta}>
              <span className={styles.title_consulta}>Consulta de Tabela</span>
            </div>

            <div className={styles.container_consulta_all}>
              <div className={styles.container_all_consulta}>
                <div className={styles.container_inputs_consulta}>
                  <div className={styles.inputs_consulta}>
                    <div className={styles.teste_row}>
                      <div>
                        <label htmlFor="CPF">CPF</label>
                        <br />
                        <MaskedInput
                          className={styles.cpf_facta2}
                          id="input_cpf"
                          name="CPF"
                          mask="999.999.999-99"
                          value={cpf}
                          onChange={(event) => setCPF(event.target.value)}
                          pattern="/^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/"
                          required
                        />
                      </div>
                      {isLoadingConsulta ? <LoadingSpinnerV2 /> : <span></span>}
                    </div>

                    <div>
                      <label htmlFor="input_valor_af">Valor</label>
                      <br />
                      <input
                        className={styles.input_padrao}
                        type="number"
                        min="0"
                        step="0.01"
                        name="Valor"
                        id="input_valor"
                        value={valor}
                        required
                        onChange={(e) => setValor(e.target.value)}
                      />
                    </div>
                    <div>
                      <label htmlFor="input_prazo">Prazo</label>
                      <br />
                      <input
                        className={styles.input_padrao}
                        type="number"
                        min="0"
                        name="Prazo / Quantidade de Parcelas"
                        id="input_prazo"
                        placeholder="Prazo"
                        value={prazo}
                        required
                        onChange={(e) => setPrazo(e.target.value)}
                      />
                    </div>
                    <div>
                      <label htmlFor="input_data_nascimento">
                        Data de Nascimento
                      </label>
                      <br />
                      <input
                        className={styles.dataNasc}
                        type="date"
                        name="Data de Nascimento"
                        id="input_data_nascimento"
                        placeholder="Data de Nascimento"
                        value={dataNasc}
                        required
                        onChange={(e) => setDataNasc(e.target.value)}
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Preencha a data de nascimento"
                          )
                        }
                      />
                    </div>
                  </div>
                  <br />
                  <div>
                    <Tabela
                      className={styles.tabelinhadejesus}
                      produto={produto}
                      opcao_valor={opcao_valor}
                      setTab={setOperacaoProposta}
                      valor={(valor * 0.05 * 27.5 * 0.7).toFixed(2)}
                      cpf={cpf}
                      dataNasc={dataNasc}
                      prazo={prazo}
                      tipo_operacao={tipo_operacao ? tipo_operacao : 0}
                    />
                    {operacao[0] ? (
                      <>
                        <h3>Tabela Selecionada: {operacao[0]}</h3>
                        <h3>
                          Taxa: {operacao[2]} Parcela: {operacao[3]} Prazo:{" "}
                          <br />
                          {operacao[4]} Coeficiente: {operacao[6]} Contrato:{" "}
                          {operacao[5]}{" "}
                        </h3>
                      </>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* container cadastro de proposta */}
            <div className={styles.container_title_cadastro}>
              <span className={styles.title_cadastro}>
                Cadastro de Proposta
              </span>
            </div>
            {operacao.length === 7 ? (
              <form onSubmit={handleFinalizarProposta}>
                <div className={styles.container_cadastro_all}>
                  <div className={styles.container_all_cadastro}>
                    <div className={styles.container_inputs_cadastro}>
                      {/* container = nome, renda e celular */}
                      <div className={styles.name_renda_celular}>
                        <div>
                          <label htmlFor="input_nome">Nome</label>
                          <br />
                          <input
                            className={styles.name}
                            type="text"
                            name="nome"
                            id="input_nome"
                            value={nome}
                            required
                            onChange={(e) => setNome(e.target.value)}
                          />
                        </div>
                        <div>
                          <label htmlFor="Renda">Renda</label>
                          <br />
                          <input
                            className={styles.renda}
                            type="number"
                            name="renda"
                            id="input_renda"
                            value={renda}
                            required
                            onChange={(e) => setRenda(e.target.value)}
                          />
                        </div>
                        <div>
                          <label htmlFor="input_celular">Celular</label>
                          <br />
                          <input
                            className={styles.celular}
                            required
                            type="tel"
                            min="0"
                            id="input_celular"
                            value={celular}
                            maxLength="16"
                            pattern="\(\d{3}\)\s*\d{5}-\d{4}"
                            onChange={(e) => setCelular(e.target.value)}
                          />
                        </div>
                        <div>
                          <label htmlFor="">Valor do Patrimônio</label>
                          <br />
                          <select
                            className={styles.valorPatrimonio}
                            id={valores}
                            required
                            onChange={(e) => setValorPatrimonio(e.target.value)}
                          >
                            <option hidden value=""></option>
                            {valores?.map((val) => (
                              <option value={val[0]} key={val[0]}>
                                {val[1]}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <input
                            className={styles.iletrado}
                            type="checkbox"
                            name="cliente"
                            id="Iletrado"
                            value={"S"}
                            onSelect={(e) => setIletrado(e.target.value)}
                          />
                          <label htmlFor="Iletrado">
                            Iletrado / <br /> Impossibilitado
                          </label>
                        </div>
                      </div>
                      {/* contianer = RG,  orgão emissor, estado civil, iletrado*/}
                      <div className={styles.RG_orgaoEmi_estadoCivil_iletrado}>
                        <div>
                          <label htmlFor="RG">RG</label>
                          <br />
                          <input
                            required
                            type="text"
                            name="RG"
                            id=""
                            placeholder="RG"
                            value={rg}
                            onChange={(e) => setRG(e.target.value)}
                          />
                        </div>
                        <div className={styles.nacionalidade}>
                          <input
                            defaultChecked
                            type="radio"
                            name="nacionalidade"
                            id="op1"
                            value={1}
                            onSelect={(e) => setNacionalidade(e.target.value)}
                          />
                          <label htmlFor="Brasileiro">Brasileiro</label>
                          <br />
                          <input
                            type="radio"
                            name="nacionalidade"
                            id="op2"
                            value={2}
                            onSelect={(e) => setNacionalidade(e.target.value)}
                          />
                          <label htmlFor="Estrangeiro">Estrangeiro</label>
                        </div>
                        <div>
                          <label htmlFor="orgao-emissor">Orgão Emissor</label>
                          <br />
                          <select
                            className={styles.orgaoEmissor}
                            value={orgaoEmissorRG}
                            id="orgao-emissor"
                            required
                            onChange={(e) => setOrgaoEmissorRG(e.target.value)}
                          >
                            <option hidden value=""></option>
                            {orgaos
                              ?.sort((a, b) => (a[1] > b[1] ? 1 : -1))
                              .map((orgao) => (
                                <option value={orgao[0]} key={orgao[0]}>
                                  {orgao[0]}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div>
                          <label htmlFor="">Estado do RG</label>
                          <br />
                          <select
                            className={styles.estado_rg}
                            id="estado-cliente"
                            required
                            onChange={(e) => setEstadoRG(e.target.value)}
                          >
                            <option hidden value=""></option>
                            {estados?.map((estado) => (
                              <option value={estado[0]} key={rg + estado[0]}>
                                {estado[0]}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label htmlFor="input_data_emissao">
                            Data de Emissão
                          </label>
                          <br />
                          <input
                            required
                            type="date"
                            name="emissao"
                            id="input_data_emissao"
                            placeholder="Data de Emissão"
                            value={dataExpedicao}
                            onChange={(e) => setDataExp(e.target.value)}
                          />
                        </div>
                      </div>

                      {/* container = nome da mãe, nome do pai,estado natural e cidade natural */}
                      <div className={styles.mae_pai_estadoNatu_cidadeNatu}>
                        <div>
                          <label htmlFor="Mãe">Nome da Mãe</label>
                          <br />
                          <input
                            type="text"
                            required
                            placeholder="Mãe"
                            value={nomeMae}
                            onChange={(e) => setNomeMae(e.target.value)}
                          />
                        </div>
                        <div>
                          <label htmlFor="Pai">Nome do Pai</label>
                          <br />
                          <input
                            required
                            type="text"
                            placeholder="Pai"
                            value={nomePai}
                            onChange={(e) => setNomePai(e.target.value)}
                          />
                        </div>
                        <div>
                          <label htmlFor="EstadoNatu">Estado Natural</label>
                          <br />
                          <select
                            className={styles.dropdown_estado_natural}
                            required
                            onChange={(e) => setEstadoNatural(e.target.value)}
                          >
                            <option hidden value=""></option>
                            {estados
                              ?.sort((a, b) => (a[1] > b[1] ? 1 : -1))
                              .map((estado) => (
                                <option value={estado[0]} key={estado[0]}>
                                  {estado[1]}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div>
                          <label htmlFor="CidadeNatu">Cidade Natural</label>
                          <br />
                          <select
                            className={styles.dropdown_cidade_natural}
                            id="cidade-cliente"
                            required
                            onChange={(e) => setCidadeNatural(e.target.value)}
                          >
                            <option hidden value="">
                              {" "}
                              Cidade Natural{" "}
                            </option>
                            {cidadesNATURAL
                              ?.sort((a, b) =>
                                a[1][1].nome > b[1][1].nome ? 1 : -1
                              )
                              .map((cidadeN) => (
                                <option
                                  value={cidadeN[1][0]}
                                  key={cidadeN[1][0]}
                                >
                                  {cidadeN[1][1].nome}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      {/* container = sexo, estado civil, beneficio*/}
                      <div className={styles.sexo_estadoCivil_beneficio}>
                        <div>
                          <label htmlFor="Sexo">Sexo</label>
                          <br />
                          <select
                            value={sexo}
                            className={styles.sexo}
                            required
                            onChange={(e) => setSexo(e.target.value)}
                          >
                            <option value="" hidden>
                              {" "}
                              Sexo{" "}
                            </option>
                            <option value="M"> Masculino </option>
                            <option value="F"> Feminino </option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="EstadoCivil">Estado Civil</label>
                          <br />
                          <select
                            className={styles.estado_civil}
                            id="estados-civis"
                            required
                            value={estadoCivil}
                            onChange={(e) =>
                              setEstadoCivilCliente(e.target.value)
                            }
                          >
                            <option hidden value="">
                              {" "}
                              Estado Civil{" "}
                            </option>
                            {estadoCivis
                              ?.sort((a, b) => (a[1] > b[1] ? 1 : -1))
                              .map((estado) => (
                                <option value={estado[0]} key={estado[0]}>
                                  {estado[1]}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div>
                          <label htmlFor="">Estado Benefício</label>
                          <br />
                          <select
                            className={styles.estado_beneficio}
                            required
                            onChange={(e) => setEstadoBene(e.target.value)}
                          >
                            <option value=""></option>
                            {estados
                              ?.sort((a, b) => (a[1] > b[1] ? 1 : -1))
                              .map((estadoBenef) => (
                                <option
                                  value={estadoBenef[0]}
                                  key={estadoBenef[0]}
                                >
                                  {estadoBenef[1]}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div>
                          <label htmlFor="">Benefício</label>
                          <br />
                          <select
                            className={styles.beneficio}
                            required
                            onChange={(e) => setTipoBeneficio(e.target.value)}
                          >
                            <option hidden value=""></option>
                            {tipos?.map((tipoBene) => (
                              <option
                                value={tipoBene[0]}
                                key={tipoBene[0]}
                                hover={tipoBene[1]}
                              >
                                {tipoBene[0] + " - " + tipoBene[1]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.container_title_endereco}>
                  <span className={styles.title_endereco}>
                    Endereço do cliente
                  </span>
                </div>
                <div className={styles.container_endereco_all}>
                  <div className={styles.container_all_endereco}>
                    <div className={styles.container_inputs_endereco}>
                      <div>
                        <label htmlFor="CEP">CEP</label>
                        <br />
                        <MaskedInput
                          className={styles.cep}
                          mask="99999-999"
                          placeholder="CEP"
                          name="CEP"
                          value={cep}
                          onChange={(event) => setCEP(event.target.value)}
                          id="input_cep"
                          pattern="[0-9]{5}-[0-9]{3}"
                          oninvalid="alert('CEP incorreto.');"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="Endereco">Endereço</label>
                        <br />
                        <input
                          className={styles.endereco}
                          required
                          type="text"
                          placeholder="Endereço"
                          value={endereco}
                          onChange={(e) => setEndereco(e.target.value)}
                        />
                      </div>
                      <div>
                        <label htmlFor="Número">Número</label>
                        <br />
                        <input
                          className={styles.numero}
                          required
                          type="number"
                          min="1"
                          placeholder="Número"
                          value={numEndereco}
                          onChange={(e) => setNumEndereco(e.target.value)}
                        />
                      </div>
                      <div>
                        <label htmlFor="Bairro">Bairro</label>
                        <br />
                        <input
                          className={styles.bairro}
                          required
                          type="text"
                          placeholder="Bairro"
                          value={bairro}
                          onChange={(e) => setBairro(e.target.value)}
                        />
                      </div>
                      <div>
                        <label htmlFor="Estado">Estado</label>
                        <br />
                        <select
                          className={styles.inputs_residencia_estado}
                          required
                          value={estado}
                          onChange={(e) => setEstado(e.target.value)}
                        >
                          {estados
                            ?.sort((a, b) => (a[1] > b[1] ? 1 : -1))
                            .map((estado) => (
                              <option value={estado[0]} key={estado[0]}>
                                {estado[1]}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div>
                        <label htmlFor="Cidade">Cidade</label>
                        <br />
                        <select
                          className={styles.inputs_residencia_cidade}
                          id="cidade-cliente"
                          required
                          onChange={(e) => setCidade(e.target.value)}
                        >
                          <option hidden value="">
                            {" "}
                            Cidade{" "}
                          </option>
                          {cidades
                            ?.sort((a, b) =>
                              a[1][1].nome > b[1][1].nome ? 1 : -1
                            )
                            .map((cidadeCli) => (
                              <option
                                value={cidadeCli[1][0]}
                                key={cidadeCli[1][0]}
                              >
                                {cidadeCli[1][1].nome}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* container Dados Bancários */}
                <div className={styles.container_title_dadosBanca}>
                  <span className={styles.title_dadosBanca}>
                    Dados Bancários
                  </span>
                </div>
                <div className={styles.container_dadosBanca_all}>
                  <div className={styles.container_all_dadosBanca}>
                    <div className={styles.container_inputs_dadosBanca}>
                      <div className={styles.container_banco}>
                        <label htmlFor="Banco">Banco</label>
                        <br />
                        <select
                          value={banco}
                          className={styles.inputUm}
                          required
                          onChange={(e) => setBanco(e.target.value)}
                        >
                          <option hidden value=""></option>
                          {bancos?.map((banco) => (
                            <option value={banco[0]} key={banco[0]}>
                              {banco[0] + " - " + banco[1]}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label htmlFor="Conta">Conta</label>
                        <br />
                        <input
                          required
                          type="text"
                          placeholder="Conta"
                          value={conta}
                          onChange={(e) => setConta(e.target.value)}
                        />
                      </div>
                      <div>
                        <label htmlFor="Agencia">Agência</label>
                        <br />
                        <input
                          required
                          type="number"
                          min="0"
                          placeholder="Agência"
                          value={agencia}
                          onChange={(e) => setAgencia(e.target.value)}
                        />
                      </div>
                      <div>
                        <label htmlFor="Matricula">Matricula</label>
                        <br />
                        <input
                          required
                          type="number"
                          min="0"
                          placeholder="Matricula"
                          value={matricula}
                          onChange={(e) => setMatricula(e.target.value)}
                        />
                      </div>
                      <div className={styles.tipoDeConta}>
                        <div className={styles.container_radio}>
                          <div className={styles.corrente}>
                            <input
                              checked
                              type="radio"
                              name="tipoConta"
                              required
                              id="contaCorrente"
                              value={1}
                              onChange={(e) => setTipoCredito(e.target.value)}
                            />
                            <label htmlFor="ContaCorrente">
                              Conta Corrente
                            </label>
                          </div>
                          <div className={styles.cartaoMag}>
                            <input
                              type="radio"
                              name="tipoConta"
                              id="CartaoMagnetico"
                              value={2}
                              onChange={(e) => setTipoCredito(e.target.value)}
                            />
                            <label htmlFor="CartaoMag">Cartão Magnético</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.container_finalizar}>
                  <button className={styles.btn_finalizar} type="submit">
                    Finalizar Proposta
                  </button>
                </div>
              </form>
            ) : (
              <span></span>
            )}
          </section>
        ) : (
          <span></span>
        )}
      </section>
    </>
  );
}
