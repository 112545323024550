/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../Modal";
import ModalConfig from "../ModalConfig";
import styles from "./Navbar.module.css";
import { BsFillGearFill, BsFillPersonFill } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import AuthContext from "../../../context/AuthProvider";
import UseLevel from "../../../hooks/UseLevel";
import { ProgressBar } from "../../../Components/ProgressBar";
import { axiosInstance } from "../../../api/axios";

export default function NavBar(props, children) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showModalConfig, setShowModalConfig] = useState(false);
  const [nivelUsuario, setNivelUsuario] = useState(null);

  useEffect(() => {
    setNivelUsuario(UseLevel());
  }, []);

  const openModalConfig = () => {
    setShowModalConfig((prev) => !prev);
  };

  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const logout = async () => {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "logout",
      headers: {
        cookies: `${JSON.parse(localStorage.getItem("user"))[8]}`,
      },
    };

    await axiosInstance(config).then(function (_response) {});

    function limparLocalStorageExceto(guardarCampos) {
      var keys = []
      for (let i = 0; i < localStorage.length; i++) {
        keys.push(localStorage.key(i))
      }
      keys.forEach(key => {
        if (!guardarCampos.includes(key)) {
          localStorage.removeItem(key);
        }
      })
    }

    limparLocalStorageExceto(['apiUrl','ally-supports-cache']);
    setAuth({});
    navigate("/");
  };

  return (
    <>
      <div className={styles.navbar_geral} id="animate_charcter">
        <div className={styles.content_nav}>
          <nav>
            <div className={styles.content}>
              <Link to="/home">
                {/* <h2>Ágille</h2>
                <h2>Ágillé</h2> */}
              </Link>
            </div>
            <label htmlFor="btn" className={styles.icon}>
              <span id="icon" className="fa fa-bars"></span>
            </label>
            <input className={styles.navbar_input} type="checkbox" id="btn" />
            <ul>
              <li>
                <Link className={styles.tag_a} id={styles.cursor} to="/home">
                  INICIO
                </Link>
              </li>
              <li>
                <label htmlFor="btn-1" className={styles.show}>
                  Ferramentas +
                </label>
                <a className={styles.tag_a} href="#">
                  {" "}
                  Ferramentas{" "}
                </a>
                <input
                  className={styles.navbar_input}
                  type="checkbox"
                  id="btn-1"
                />

                <ul>
                  {/* <li>
                    <label htmlFor="btn-2" className={styles.show}>
                      Digitação
                    </label>
                    <a className={styles.tag_a} href="#">
                      Digitação
                    </a>
                    <input
                      className={styles.navbar_input}
                      type="checkbox"
                      id="btn-2"
                    />
                    <ul>
                      <li>
                        <Link
                          className={styles.tag_a}
                          id={styles.cursor}
                          to="/facta"
                        >
                          Facta
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                  <li>
                    <a
                      className={styles.tag_a}
                      id={styles.cursor}
                      href="/consulta/incluirAF"
                    >
                      Digitação
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.tag_a}
                      id={styles.cursor}
                      href="/logins-parceiros"
                    >
                      Logins p.
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <label htmlFor="btn-4" className={styles.show}>
                  Consultas +
                </label>
                <a className={styles.tag_a} href="#">
                  Consultas
                </a>
                <input
                  className={styles.navbar_input}
                  type="checkbox"
                  id="btn-4"
                />
                <ul>
                <li>
                    <a
                      className={styles.tag_a}
                      id={styles.cursor}
                      href="/consulta/roteiro"
                    >
                      Roteiros
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.tag_a}
                      id={styles.cursor}
                      href="/consulta/facta"
                    >
                      Facta
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.tag_a}
                      id={styles.cursor}
                      href="/consulta/extrato"
                    >
                      Extrato
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.tag_a}
                      id={styles.cursor}
                      href="/consulta/crefaz"
                    >
                      Crefaz
                    </a>
                  </li>
                  <li>
                    <label htmlFor="btn-3" className={styles.show}>
                      FGTS <i className="fa-solid fa-plus" />
                    </label>
                    <a className={styles.tag_a} href="#">
                      {" "}
                      FGTS
                    </a>
                    <input
                      className={styles.navbar_input}
                      type="checkbox"
                      id="btn-3"
                    />
                    <ul>
                      <li>
                        <Link
                          className={styles.tag_a}
                          id={styles.cursor}
                          to="/consulta/FGTS/facta"
                        >
                          Facta
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                {nivelUsuario === 1 ? (
                  <a className={styles.tag_a}>
                    <button
                      aria-label="Cadastrar Novo Usuário"
                      className={styles.btn_create_account}
                      onClick={() => setIsModalVisible(true)}
                    >
                      {" "}
                      Cadastrar{" "}
                    </button>
                  </a>
                ) : (
                  <></>
                )}
                {isModalVisible ? (
                  <Modal onClose={() => setIsModalVisible(false)}></Modal>
                ) : null}
              </li>

              <li className={styles.container_inputs_icons}>
                <li className={styles.test}>
                  <Link
                    className={styles.person}
                    to="/perfil"
                    aria-label="Perfil"
                  >
                    {" "}
                    {<BsFillPersonFill title="Perfil" />}{" "}
                  </Link>
                </li>

                <li>
                  {/* modal novo teste */}
                  <button
                    onClick={openModalConfig}
                    className={styles.config}
                    aria-label="Configurações"
                  >
                    {" "}
                    {<BsFillGearFill title="Configurações" />}{" "}
                  </button>
                  <ModalConfig
                    showModalConfig={showModalConfig}
                    setShowModalConfig={setShowModalConfig}
                  />
                </li>
                <li className={styles.test}>
                  <button
                    id="logout"
                    title="Sair"
                    aria-label="Fazer Logout"
                    className={styles.logout}
                    onClick={logout}
                  >
                    {<FiLogOut />}
                  </button>
                </li>
              </li>
            </ul>
          </nav>
        </div>
        {props.carregar ? <ProgressBar /> : <></>}
      </div>
    </>
  );
}
