import {axiosInstance} from "./axios.js";

export async function consultaOperacoesGET(
  token,
  produto,
  opcao_valor,
  valor,
  cpf,
  dt_nasc,
  prazo,
  tipo_operacao = 0,
  renda,
  setErrMsg
) {
  try {
    var data = JSON.stringify({
      produto: `${produto}`,
      tipo_operacao: produto === 2 ? 33 : produto === 3 ? 13 : tipo_operacao,
      dt_nasc: `${dt_nasc}`,
      cpf: `${cpf}`,
      valor: `${valor}`,
      prazo: `${prazo}`,
      opcao_valor: `${opcao_valor}`,
      valor_renda: `${renda}`
    });

    var config = {
      method: "post",
      url: "facta/consulta-operacoes",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const result = await axiosInstance(config).then(function (response) {
      var json = JSON.parse(JSON.stringify(response.data));
      return json;
    });
    return result;
  } catch (error) {
    if (error.response.data.mensagem == 'Authorization incorreto'){
      setErrMsg('Erro no servidor. Tente novamente mais tarde.');
    }else{
      error.response.data.mensagem
      ? setErrMsg(error.response.data.mensagem)
      : setErrMsg("Erro " + error.response.status + ": Tente novamente.");
    }
  }
}

export async function simulacaoValoresOperacaoPOST(
  token,
  produto,
  tipo_operacao,
  operacao,
  cliente
) {
  try {
    var data = JSON.stringify({
      cliente: {
        cpf: cliente.cpf,
        data_nascimento: cliente.dataNasc,
      },
      login_certificado: "54448_MURILO",
      operacao: {
        prazo: operacao[4],
        codigo_tabela: operacao[1],
        valor_operacao: operacao[5],
        valor_parcela: operacao[3],
        coeficiente: operacao[6],
      },
      produto: produto,
      tipo_operacao: tipo_operacao,
      valor_renda: cliente.renda
    });

    var config = {
      method: "post",
      url: "facta/simulacao-valores/",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const result = await axiosInstance(config).then(function (response) {
      var json = JSON.parse(JSON.stringify(response.data));
      return json;
    });

    return result;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function cadastroDadosPessoaisPOST(token, id_simulador, cliente) {
  try {
    var data = JSON.stringify({
      cliente: {
        cpf: cliente.cpf,
        data_nascimento: cliente.dataNasc,
        nome: cliente.nome,
        sexo: cliente.sexo,
        estado_civil: cliente.estadoCivil,
        rg: cliente.rg,
        estado_rg: cliente.estadoRG,
        orgao_emissor: cliente.orgaoEmissorRG,
        data_expedicao: cliente.dataExpedicao,
        estado_natural: cliente.estadoNatural,
        cidade_natural: cliente.cidadeNatural,
        nacionalidade: cliente.nacionalidade,
        pais_origem: cliente.paisOrigem,
        celular: cliente.celular,
        renda: cliente.renda,
        cep: cliente.cep,
        endereco: cliente.endereco,
        numero: cliente.numEndereco,
        bairro: cliente.bairro,
        cidade: cliente.cidade,
        estado: cliente.estado,
        nome_mae: cliente.nomeMae,
        nome_pai: cliente.nomePai,
        valor_patrimonio: cliente.valorPatrimonio,
        cliente_iletrado_impossibilitado: cliente.cliente_iletrado,
        banco: cliente.banco,
        agencia: cliente.agencia,
        conta: cliente.conta,
        matricula: cliente.matricula,
        tipo_credito_nb: cliente.tipo_credito,
        tipo_beneficio: cliente.tipo_beneficio,
        estado_beneficio: cliente.estado_beneficio,
      },
      operacao: {
        id_simulador: id_simulador,
      },
    });
    var config = {
      method: "post",
      url: "facta/cadastro-dados-pessoais/",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const result = await axiosInstance(config).then(function (response) {
      var json = JSON.parse(JSON.stringify(response.data));
      return json;
    });

    return result;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function cadastroPropostaPOST(
  token,
  id_simulador,
  codigo_cliente
) {
  try {
    var data = JSON.stringify({
      id_simulador: id_simulador,
      codigo_cliente: codigo_cliente,
    });
    var config = {
      method: "post",
      url: "facta/cadastro-proposta/",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const result = await axiosInstance(config).then(function (response) {
      var json = JSON.parse(JSON.stringify(response.data));
      return json;
    });

    return result;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function checkFactaToken(token) {
  var config = {
    method: "get",
    url: "facta/token",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const tknFacta = await axiosInstance(config)
    .then(function (response) {
      return JSON.parse(JSON.stringify(response.data.token));
    })
    .catch(function (error) {
      console.log(error);
    });

  return tknFacta;
}

export async function consultarAndamento(AF ,token) {
  var config = {
    method: 'get',
    url: `facta/consulta-ocorrencias?af=${AF}`,
    headers: { 
      'Authorization': `Bearer ${token}`
    }
  };
  
  axiosInstance(config)
  .then(function (response) {
    const json = JSON.parse(JSON.stringify(response.data));
    return json
  })
  .catch(function (error) {
    console.log(error);
  });
}

export async function consultarProposta(token, cpf) {
  var config = {
    method: "get",
    url: "facta/consulta-proposta",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      cpf: cpf,
    },
  };

  const result = await axiosInstance(config)
    .then(function (response) {
      var json = JSON.parse(JSON.stringify(response.data));
      return json;
    })
    .catch(function (error) {
      return error;
    });

  return result;
}

export async function consultaFGTS(token, cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `facta/consulta-fgts?cpf=${cpf}`,
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  };

  const result = await axiosInstance(config)
    .then(function (response) {
      var json = JSON.parse(JSON.stringify(response.data));
      console.log(json);
      return json;
    })
    .catch(function (error) {
      if(!!error.response.data.msg){
        alert(error.response.data.msg);
      }
      console.error(error);
      return null;
    });

  return result;
}
