import React, { Component } from 'react'
import styles from './frase.module.css'

class ObjetoFrase extends Component {
  render() {
    return (
      <div className={styles.FrasedoDia}>
        {this.props?.frase ? 
        <>
          <h1>Frase Do Dia!</h1>
          <p className={styles.frase}>
            {this.props?.frase}
          </p>
        </>
        : <p></p>
        }
      </div>
    )
  }
}

export default ObjetoFrase
