import { axiosInstance } from "../../../api/axios.js";
import { useState } from "react";
import NavBar from "../Navbar";
import UseToken from "../../../hooks/UseToken";
import styles from "./AtualizarUsers.module.css";
import { useEffect } from "react";
import {
  niveisGET,
  equipesGET,
  listaUsuariosAPI,
  infoUsuario,
  deletarUsuario,
} from "../../../api/api-portal";

export default function AtualizarUsuarios() {
  const [niveis, setNiveis] = useState([]);
  const [equipes, setEquipes] = useState([]);
  const [select, setSelect] = useState("");
  const [users, setUsers] = useState([]);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [equipe, setEquipe] = useState("");
  const [nivel, setNivel] = useState("");
  const [rep_codigo, setRepCodigo] = useState("");
  const [password, setPassword] = useState("");
  const [carregar, setCarregar] = useState(false);

  async function iniciarPagina() {
    setCarregar(true);
    setUsers(await listaUsuariosAPI(UseToken()));
    setNiveis(await niveisGET(UseToken()));
    setEquipes(await equipesGET(UseToken()));
    setCarregar(false);
  }

  async function fetchUser(id) {
    var json = await infoUsuario(UseToken(), id);
    setName(json.name ? json.name : "");
    setUsername(json.user);
    setEmail(json.email ? json.email : "");
    setEquipe(json.equipe ? json.equipe : "");
    setNivel(json.level ? json.level : "");
    setRepCodigo(json.codigo_representante ? json.codigo_representante : "");
  }

  const handleDeletar = async (e) => {
    e.preventDefault();
    if (await deletarUsuario(UseToken(), select)) {
      alert("Usuário deletado");
      await iniciarPagina();
      setName("");
      setUsername("");
      setEmail("");
      setEquipe("");
      setNivel("");
      setPassword("");
      setRepCodigo("");
    }
  };

  const handleAtualizar = async (e) => {
    e.preventDefault();
    var data = JSON.stringify({
      level: nivel,
      user: username,
      name: name,
      email: email,
      password: password ? password : "",
      equipe: equipe,
      codigo_representante: rep_codigo,
    });

    var config = {
      method: "put",
      url: `user/${select}`,
      headers: {
        Authorization: `Bearer ${UseToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axiosInstance(config)
      .then(function (response) {
        alert(response.data.msg);
      })
      .catch(function (error) {
        alert(error.response.data.msg);
      });

    setUsers(await listaUsuariosAPI(UseToken()));
  };

  useEffect(() => {
    document.title = "Usuários";
    iniciarPagina();
  }, []);

  useEffect(() => {
    if (select) {
      fetchUser(select);
    }
  }, [select]);

  return (
    <>
      <NavBar carregar={carregar} />
      <div className={styles.container_title}>
        <h1 className={styles.title_usuario}>Atualizar Usuário</h1>
      </div>
      <div className={styles.divAtualizar}>
        <div className={styles.container_form}>
          <form onSubmit={handleAtualizar}>
            {/* <label className={styles.att_usuario} htmlFor="select-usuario">
              Usuário
            </label> */}
            <select
              className={styles.select_usuario}
              onChange={(e) => setSelect(e.target.value)}
              name="Selecionar Usuário"
              id="select_usuario"
              value={select}
              disabled={carregar}
              title="Selecione o usuário que vai ser alterado"
            >
              <option hidden value=""></option>
              {users
                ?.sort((a, b) => (a.user > b.user ? 1 : -1))
                .map((user) => (
                  <option value={user._id} key={user._id}>
                    {user.user}
                  </option>
                ))}
            </select>

            <div className={styles.input_atualizar}>
              <label>Usuário</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />

              <label>Nome</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <label>E-mail</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>Código do Representante</label>
              <input
                type="number"
                value={rep_codigo}
                onChange={(e) => setRepCodigo(e.target.value)}
              />
              <div className={styles.nivel_equipe}>
                <div className={styles.container_nivel}>
                  <label>Nível</label>
                  :&ensp;
                  <select
                    value={nivel}
                    onChange={(e) => setNivel(e.target.value)}
                    disabled={carregar}
                  >
                    <option value=""></option>
                    {niveis?.map((nivel) => (
                      <option value={nivel[0]} key={nivel[0]}>
                        {nivel[0]} - {nivel[1]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={styles.container_nivel}>
                  <label>Equipe</label>
                  :&ensp;
                  <select
                    value={equipe}
                    onChange={(e) => setEquipe(e.target.value)}
                    disabled={carregar}
                  >
                    <option value=""></option>
                    {equipes?.map((equipe) => (
                      <option value={equipe[0]} key={equipe[0]}>
                        {equipe[0]} - {equipe[1]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={styles.container_senha}>
                <label>
                  Senha <strong>(opcional)</strong>
                </label>
              </div>
              <input
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <div className={styles.container_btn_atualizar}>
                <button
                  className={styles.btn_atualizar}
                  type="submit"
                  value="atualizar"
                  disabled={carregar}
                >
                  Atualizar
                </button>
                <button
                  onClick={handleDeletar}
                  className={styles.btn_deletar}
                  type="button"
                  value="deletar"
                  disabled={carregar}
                >
                  Deletar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
