import { React, useState, useEffect } from "react";
import { extratoGET } from "../../../api/api-portal";
import Navbar from "../../pages/Navbar";
import ModalDadosBancariosPromo from "./ModalDadosBanc";
import ModalIncluirAF from "./ModalIncluirAF";
import TablePromoSys from "./Table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import "./style.css";
import "./extrato.css";
export default function PromoSys() {
  const [botaoSelecionado, setBotaoSelecionado] = useState("extrato");
  const [carregar, setCarregar] = useState(false);
  const { cpf } = useParams();
  const [value, setValue] = useState("");
  const [extrato, setExtrato] = useState();
  const [itemAtivo, setItemAtivo] = useState(0);

  async function Consultar() {
    setCarregar(true);
    setExtrato(await extratoGET(value ? value : cpf));
    setCarregar(false);
  }

  function handleClick() {
    Consultar();
  }

  useEffect(() => {
    document.title = "Inclusão de AF";
    if (cpf != null) {
      setValue(cpf);
      setTimeout(() => {
        Consultar();
      }, 500);
    }
  }, []);

  function formatarCPF(cpf) {
    return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  const renderizarElemento = () => {
    if (botaoSelecionado === "extrato") {
      return (
        <>
          {!extrato ? (
            <></>
          ) : (
            <>
              <div className="informacoes-container-holder">
                <div className="container-dados-pessoais">
                  <h3>Dados Pessoais</h3>
                </div>
                <div className="block-infos">
                  <div className="line">
                    <div className="lineCell">
                      <div className="lineTitle">Nome: &ensp;</div>
                      <div className="result">{extrato[itemAtivo].name}</div>
                    </div>
                    <div className="lineCell">
                      <div className="lineTitle">CPF: &ensp;</div>
                      <div className="result">
                        {formatarCPF(
                          extrato[itemAtivo].documentNumber.toString()
                        )}
                      </div>
                    </div>
                    <div className="lineCell">
                      <div className="lineTitle">
                        Data de Nascimento: &ensp;
                      </div>
                      <div className="result">
                        {extrato[itemAtivo].birthDate}
                      </div>
                    </div>
                  </div>
                  <div className="line_problema">
                    <div className="lineCell">
                      <div className="lineTitle">Espécie: &ensp;</div>
                      <div className="result_problema">
                        {extrato[itemAtivo].typeCode} -{" "}
                        {extrato[itemAtivo].typeCode}
                      </div>
                    </div>
                    <div className="lineCell">
                      <div className="lineTitle">Matrícula: &ensp;</div>
                      <div className="result">
                        {extrato[itemAtivo].benefitNumber}
                      </div>
                    </div>
                    <div className="lineCell">
                      <div className="lineTitle">Situação: &ensp;</div>
                      <div className="situacao">
                        {extrato[itemAtivo].status}
                      </div>
                    </div>
                  </div>
                  <div className="line">
                    <div className="lineCell">
                      <div className="lineTitle">Endereço: &ensp;</div>
                      <div className="result">
                        {extrato[itemAtivo].address.address}
                      </div>
                    </div>
                    <div className="lineCell">
                      <div className="lineTitle">Bairro: &ensp;</div>
                      <div className="result">
                        {extrato[itemAtivo].address.city}
                      </div>
                    </div>
                    <div className="lineCell">
                      <div className="lineTitle">CEP: &ensp;</div>
                      <div className="result">
                        {extrato[itemAtivo].address.zipCode}
                      </div>
                    </div>
                  </div>
                  <div className="line2">
                    <div className="lineCell">
                      <div className="lineTitle">Cidade: &ensp;</div>
                      <div className="result2">
                        {extrato[itemAtivo].address.city}
                      </div>
                    </div>
                    <div className="lineCell">
                      <div className="lineTitle">UF: &ensp;</div>
                      <div className="result2">
                        {extrato[itemAtivo].address.state}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container-representante">
                  <h3>Detalhes do benefício</h3>
                </div>

                <div className="block-infos">
                  <div className="line">
                    <div className="lineCell">
                      <div className="lineTitle">
                        Possui Representante: &ensp;
                      </div>
                      <div className="result">
                        {extrato[itemAtivo].hasRepresentative ?? "Não possui"}
                      </div>
                    </div>
                    <div className="lineCell">
                      <div className="lineTitle">
                        Bloqueado p/ Empréstimo: &ensp;
                      </div>
                      <div className="situacao">
                        {extrato[itemAtivo].isBrockedLoan ? "SIM" : "NÃO"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    } else if (botaoSelecionado === "contratos") {
      return (
        <>
          <section className="container-contratos-promo">
            <div className="box-all-infos-promo">
              <div className="infos-cliente">
                <div className="wrapper">
                  <p>Nome: &ensp;</p>
                  <span>{extrato[itemAtivo].name}</span>
                </div>
                <div className="wrapper">
                  <p>CPF: &ensp;</p>
                  <span>
                    {formatarCPF(extrato[itemAtivo].documentNumber.toString())}
                  </span>
                </div>
                <div className="wrapper">
                  <p>Benefício: &ensp;</p>
                  <span>{extrato[itemAtivo].benefitNumber}</span>
                </div>
                <div className="wrapper">
                  <p>Espécie: &ensp;</p>
                  <span>{extrato[itemAtivo].typeCode}</span>
                </div>
              </div>
              <TablePromoSys
                dados={extrato[itemAtivo]}
                setCarregar={setCarregar}
              />
              <div className="container-title-margem">
                <h3>Margem</h3>
              </div>
              <div className="container-margem-promo">
                <div className="container-infos-margem">
                  <div className="wrapper-margem">
                    <p>Base de Cálculo: </p>
                    <span>R$ {extrato[itemAtivo].summary.baseValue}</span>
                  </div>
                  <div className="wrapper-margem">
                    <p>Margem Disponível: </p>
                    <span>
                      R$ {extrato[itemAtivo].summary.availableLoanMargin}
                    </span>
                  </div>
                  <div className="wrapper-margem">
                    <p> Margem Disponível Cartão RCC: </p>
                    <span>
                      R$ {extrato[itemAtivo].summary.availableRccMargin}
                    </span>
                  </div>
                </div>
              </div>
              <div className="dados-bancarios-promo">
                <ModalDadosBancariosPromo dados={extrato} />
              </div>

              <div className="dados-bancarios-promo">
                <ModalIncluirAF dados={extrato} />
              </div>
            </div>
          </section>
        </>
      );
    }
  };

  return (
    <>
      <Navbar carregar={carregar} />
      <ToastContainer
        position="bottom-right"
        style={{ zIndex: 9999, position: "fixed" }}
      />
      <section className="container-all-promosys">
        <div className="container-input-extrato2">
          <input
            placeholder="Digite somente CPF"
            className="searchBox2"
            type="search"
            maxLength={14}
            value={value}
            onChange={(e) => {
              const value = e.target.value;
              let formattedValue = value.replace(/\D/g, "");

              if (formattedValue.length === 10) {
                formattedValue = formattedValue.replace(/[^\d]/g, "");
              } else if (formattedValue.length <= 11) {
                formattedValue = formattedValue
                  .replace(/(\d{3})(\d)/, "$1.$2")
                  .replace(/(\d{3})(\d)/, "$1.$2")
                  .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
              } else if (formattedValue.length <= 10) {
                formattedValue = formattedValue.replace(/(\d{5})(\d)/, "$1-$2");
              }

              setValue(formattedValue);
            }}
            disabled={carregar}
          />
        </div>

        <div className="container-btn-extrato2">
          <button className="button-search" onClick={handleClick}>
            Pesquisar
          </button>
        </div>

        {!extrato ? (
          <></>
        ) : (
          <>
            <div className="btn-dados-historico">
              <button
                className={`btn-dados ${
                  botaoSelecionado === "extrato" ? "selecionado" : ""
                }`}
                type="submit"
                onClick={() => setBotaoSelecionado("extrato")}
              >
                Dados Pessoais
              </button>
              <button
                className={`btn-hist ${
                  botaoSelecionado === "contratos" ? "selecionado" : ""
                }`}
                type="submit"
                onClick={() => setBotaoSelecionado("contratos")}
              >
                Contratos
              </button>
            </div>
            {renderizarElemento()}
          </>
        )}
      </section>
    </>
  );
}
