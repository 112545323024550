import { useState, useEffect } from "react";
import Navbar from "../../../pages/Navbar";
import MaskedInput from "./Mask";
import {consultaFGTS} from "../../../../api/api-facta";
import "./style.css";
import UseToken from "../../../../hooks/UseToken";

export default function FGTSFacta() {
  const [carregar, setCarregar] = useState(false);
  const [cpf, setCPF] = useState("");
  const [consulta, setConsulta] = useState(null);

  useEffect(() => {
    document.title = 'Consulta FGTS'
  }, [])

  const handleFGTS = async (_e) => {
    setCarregar(true);
    setConsulta(await consultaFGTS(UseToken(), cpf));
    setCarregar(false);
  };

  return (
    <>
      <Navbar carregar={carregar}/>
      <section className="container-fgts-all">
        <div className="container-title-fgts">
          <h2 className="title-fgts">Consulta de saldo FGTS</h2>
        </div>

        <div className="container-input-btn-fgts">
          <div className="container-input-cpf-fgts">
            <label htmlFor="CPF">cpf</label>
            <MaskedInput
              type="search"
              value={cpf}
              onChange={(event) => setCPF(event.target.value)}
            />
          </div>
          <div className="container-btn-fgts">
            <button className="btn-fgts" onClick={handleFGTS} type="submit">
              Consultar
            </button>
          </div>
        </div>

        <div className="container-parcela">
          <h2>Parcelas</h2>
        </div>
        <div className="container-lib">
          <div className="itens-lib">
            <div>
              <div>
                <label htmlFor="">1 ano:&ensp;</label>
                <span>01/03/2022</span>
              </div>
              <div>
                <label htmlFor="">Valor&ensp;</label>
                <span>R$ 2059.05</span>
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="">2 ano:&ensp;</label>
                <span>01/03/2022</span>
              </div>
              <div>
                <label htmlFor="">Valor&ensp;</label>
                <span>R$ 2059.05</span>
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="">3 ano:&ensp;</label>
                <span>01/03/2022</span>
              </div>
              <div>
                <label htmlFor="">Valor&ensp;</label>
                <span>R$ 2059.05</span>
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="">4 ano:&ensp;</label>
                <span>01/03/2022</span>
              </div>
              <div>
                <label htmlFor="">Valor&ensp;</label>
                <span>R$ 2059.05</span>
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="">5 ano:&ensp;</label>
                <span>01/03/2022</span>
              </div>
              <div>
                <label htmlFor="">Valor&ensp;</label>
                <span>R$ 2059.05</span>
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="">6 ano:&ensp;</label>
                <span>01/03/2022</span>
              </div>
              <div>
                <label htmlFor="">Valor&ensp;</label>
                <span>R$ 2059.05</span>
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="">7 ano:&ensp;</label>
                <span>01/03/2022</span>
              </div>
              <div>
                <label htmlFor="">Valor&ensp;</label>
                <span>R$ 2059.05</span>
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="">8 ano:&ensp;</label>
                <span>01/03/2022</span>
              </div>
              <div>
                <label htmlFor="">Valor&ensp;</label>
                <span>R$ 2059.05</span>
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="">9 ano:&ensp;</label>
                <span>01/03/2022</span>
              </div>
              <div>
                <label htmlFor="">Valor&ensp;</label>
                <span>R$ 2059.05</span>
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="">10 ano:&ensp;</label>
                <span>01/03/2022</span>
              </div>
              <div>
                <label htmlFor="">Valor&ensp;</label>
                <span>R$ 2059.05</span>
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="">11 ano:&ensp;</label>
                <span>01/03/2022</span>
              </div>
              <div>
                <label htmlFor="">Valor&ensp;</label>
                <span>R$ 2059.05</span>
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="">12 ano:&ensp;</label>
                <span>01/03/2022</span>
              </div>
              <div>
                <label htmlFor="">Valor&ensp;</label>
                <span>R$ 2059.05</span>
              </div>
            </div>
          </div>
        </div>

        <div className="container-content-fgts">
          <div className="container-content-label-fgts">
            <div>
              <label htmlFor="">Data:&ensp;</label>
              <span>06/12/2023</span>
            </div>
            <div>
              <label htmlFor="">Hora:&ensp;</label>
              <span>11:27:50</span>
            </div>
            <div>
              <label htmlFor="">Saldo Total:&ensp;</label>
              <span>R$ 2.617,50</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
