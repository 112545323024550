import axios from 'axios';

const url = "https://api.simulator.agillecred.com.br/loans/simulate";
const apiKey = "superseguro";

export const simulateLoan = async (loanData) => {
  try {
    const response = await axios.post(url, loanData, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey
      }
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao simular o empréstimo:", error);
    throw error;
  }
};
