import React from "react";
import InputMask from "react-input-mask";
import styles from "../ConsultaFacta.module.css";

const MaskedInput = ({ value, onChange }) => {
  return <InputMask
    mask="999.999.999-99"
    value={value}
    onChange={onChange}
    className={styles.Input}
    type="text"
    name=""
    id="input_cpf"
    placeholder="Insira o CPF"

  />;
};

export default MaskedInput;
