// SuccessModal.jsx
import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';

const SuccessModal = ({ isOpen, onClose, message }) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={isOpen => !isOpen && onClose()}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay"/>
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">Sucesso</Dialog.Title>
          <Dialog.Description>
            {message}
          </Dialog.Description>
          <Dialog.Close asChild>
            <button className="CloseButton"></button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default SuccessModal;
