import React, { useEffect, useState, useRef } from "react";
import "./styles.css";
import NavBar from "../Navbar/index";
import {
  ContainerEmail,
  TagEmail,
  Email,
  ContainerCargo,
  TagCargo,
  Cargo,
  ContainerInfos,
  TagInfos,
  Infos,
} from "./CSS";
import UseToken from "../../../hooks/UseToken";
import { equipesGET } from "../../../api/api-portal";
import { s3Client } from "../../../api/s3Config";
import { PutObjectCommand, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import axios from "axios";
import fotoDefault from "../../../img/avatar.png";

export default function Perfil(props) {
  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const [cargo] = useState(localStorage.getItem("cargo"));
  const [equipe, setEquipe] = useState("");
  const [newProfilePicture, setNewProfilePicture] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    document.title = "Perfil";
    equipes();

    const UUID = user[1];

    const findFileName = "ProfilePH" + UUID + ".png";

    const uploadParams = {
      Bucket: "perfisportalagille",
      Key: "profilePhotos/" + findFileName,
    };

    try {
      const command = new GetObjectCommand(uploadParams);

      var signedUrlPromise = getSignedUrl(s3Client, command, {
        expiresIn: "120000",
      });

      Promise.all([
        signedUrlPromise
          .then((response) => {
            const foto = response;
            axios.get(response)
            .then((response) => {
              setNewProfilePicture(foto);
            })
            .catch((error) => {
              setNewProfilePicture(fotoDefault);
            })
          })
      ]);
    } catch (error) {
      console.log(error);
      setNewProfilePicture(fotoDefault)
    }
  }, []);

  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    const UUID = user[1];
    const newFileName = "ProfilePH" + UUID + ".png";

    const uploadParams = {
      Bucket: "perfisportalagille",
      Key: "profilePhotos/" + newFileName,
      Body: file,
    };

    try {
      const data = await s3Client.send(new PutObjectCommand(uploadParams));
      console.log(data);
      alert("Foto upada com sucesso.");
    } catch (err) {
      console.log("Error", err);
    }
  };

  const handleUploadButtonClick = () => {
    inputRef.current.click();
  };

  async function equipes() {
    var tkn = UseToken();
    var equipes = await equipesGET(tkn);
    if(equipes){
      for (let index = 0; index < equipes.length; index++) {
        if (equipes[index][0] === String(user[6])) {
          setEquipe(equipes[index][1]);
        }
      }
    }
  }

  return (
    <>
      <NavBar />
      <section className="perfil-all">
        <section className="banner">
          <div className="container-profile">
            <h1 className="banner-title">Perfil de {user[3]} </h1>
          </div>
        </section>

        <main className="profile">
          <section className="profile-header">
            <div className="container-profile profile-header-resume-wrapper">
              <div className="profile-picture-container">
                <div className="HiddenPhoto">
                  <img src={newProfilePicture} alt='Profile Picture'/>
                </div>

                <button
                  className="upload-button"
                  onClick={handleUploadButtonClick}
                >
                  <i className="fas fa-camera"></i>
                </button>
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/gif"
                  style={{ display: "none" }}
                  onChange={handleProfilePictureChange}
                  ref={inputRef}
                />
              </div>
              <div className="profile-header-rolelabel"></div>
              <div className="profiile-bio-wrapper-container">
                <div className="profile-header-bio-wrapper">
                  <p className="profile-header-bio-title"> Perfil </p>
                  <div className="name-email-equipe">
                    <ContainerEmail>
                      <TagEmail> E-mail: </TagEmail>
                      <Email> {user[5]} </Email>
                    </ContainerEmail>
                    <ContainerCargo>
                      <TagCargo> Cargo: </TagCargo>
                      <Cargo> {cargo} </Cargo>
                    </ContainerCargo>
                    {equipe ? (
                      <ContainerInfos>
                        <TagInfos> Equipe: </TagInfos>
                        <Infos>{equipe}</Infos>
                      </ContainerInfos>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </section>
    </>
  );
}
