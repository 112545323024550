import React from "react";
import style from "./Loading.module.css";

export default function LoadingSpinnerV2() {
  return (
    <>
      <div className={style.spinner_container_v2}>
        <div className={style.loading_spinner_v2}></div>
      </div>
    </>
  );
}
