import { axiosInstance } from "../../../api/axios.js";
import { useState } from "react";
import UseToken from "../../../hooks/UseToken";
import NavBar from "../Navbar";
import styles from "./postagens.module.css";

export default function Post() {
  const [titulo, setTitulo] = useState("");
  const [frase, setFrase] = useState("");
  const [msgErrorOrNot, setmsgErrorOrNot] = useState("")

  function onChangeSubmit(e) {
    e.preventDefault();

    var data = JSON.stringify({
      titulo: titulo,
      conteudo: frase,
    });

    var config = {
      method: "post",
      url: "feed",
      headers: {
        Authorization:
          `Bearer ${UseToken()}` ,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axiosInstance(config)
      .then(function (response) {
        setmsgErrorOrNot(JSON.stringify(response.data.msg));
      })
      .catch(function (error) {
        setmsgErrorOrNot(error);
      });
      setTitulo("")
      setFrase("")
  }

  return (
    <>
      <NavBar />
      <h1>{msgErrorOrNot}</h1>
      <h1 className={styles.title_postagem}>Crie uma nova postagem</h1>
      <div className={styles.page_post}>
        <form>
          <input
            type="text"
            className={styles.tituloForm}
            placeholder="Digite o titulo da publicação aqui."
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
          />
          <textarea
            type="text"
            className={styles.textareaForm}
            placeholder="Digite a nova publicação aqui."
            value={frase}
            onChange={(e) => setFrase(e.target.value)}
          />
          <button className={styles.submit_button} onClick={onChangeSubmit} type="submit">
            Enviar
          </button>
        </form>
      </div>
    </>
  );
}
