import React, {useState, useEffect} from "react";
// import styles from "../../Facta/Facta.module.css";
import styles from '../TabelaPagbank/TablePagbank.module.css'
import { consultaOperacoesGET } from "../../../../../api/api-facta";
import { reformatDate } from "../js/ConvertDataNasc";
import UseToken from "../../../../../hooks/UseToken";
import { ContainerTable, TH, ThFinal, TD, TdFinal } from "./ContainerTable";

export default function Table(setTab) {

  const [tabelas, setTabelas] = useState([]);

  useEffect(() => {
    const fetchTabelas = async () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const tkn = UseToken();
      return await consultaOperacoesGET(tkn, setTab.valorAF, setTab.cpf, reformatDate(setTab.dataNasc), setTab.prazo);
    };

    fetchTabelas()
    .then((result) => {
      if (result) {
        setTabelas(result);
      }
    })
    .catch(console.error);
  });

  return (
    <ContainerTable>
      <table className={styles.tabela}>
        <thead>
          <tr>
            <TH>Tabela</TH>
            <TH>Tx.Juros</TH>
            <TH>Prazo</TH>
            <TH>Parcela</TH>
            <TH>Coef.</TH>
            <ThFinal>Vlr.Cliente</ThFinal>
          </tr>
        </thead>
        <tbody>
          {tabelas?.map((tabela) => (
            <tr key={tabela.codigoTabela}>
              <div className={styles.label_table}>
                <label htmlFor={tabela.codigoTabela}>
                  <input className={styles.input_table} type='radio' name="tabela" id={tabela.codigoTabela}
                  onClick={(e) => setTab.setTab([e.target.dataset.codtabela, e.target.dataset.taxa, e.target.dataset.parcela, e.target.dataset.prazo, e.target.dataset.vlr_cliente, e.target.dataset.coef])}
                  data-codTabela={tabela.codigoTabela}
                  data-taxa={tabela.taxa}
                  data-parcela={tabela.parcela}
                  data-prazo={tabela.prazo}
                  data-vlr_cliente={tabela.contrato}
                  data-coef={tabela.coeficiente}
                  />
                  {tabela.tabela}
                </label>
              </div>
              <TD>{tabela.taxa}</TD>
              <TD>{tabela.prazo}</TD>
              <TD>{tabela.parcela}</TD>
              <TD>{tabela.coeficiente}</TD>
              <TdFinal>{tabela.contrato}</TdFinal>
            </tr>
          ))}
        </tbody>
      </table>
    </ContainerTable>
  );
}
