import React, { useState, useEffect, useRef } from "react";
import NavBar from "../../../pages/Navbar";
import styles from "./Facta.module.css";
import {
  estadoCivilGET,
  estadoGET,
  orgaoEmissorGET,
  bancoGET,
  valorPatrimonioGET,
  tipoBeneficioGET,
  cidadeGET,
} from "../../../../api/metodosComplementares";
import { consultarCliente, cadastrarPropostaDigitada } from "../../../../api/api-portal";
import MaskedInput from "./js/Mask";
import {
  cadastroDadosPessoaisPOST,
  cadastroPropostaPOST,
  simulacaoValoresOperacaoPOST,
} from "../../../../api/api-facta";
import UseToken from "../../../../hooks/UseToken";
import Tabela from "../Facta/Tabela/Table";
import LoadingSpinner from "../../../../Components/LoadingComponents/Loading";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function DigitacaoFacta() {
  const [isLoading, setLoading] = useState(true);
  const [isLoadingConsulta, setLoadingConsulta] = useState(false);
  const [isLoadingCadastroProposta, setLoadingCadastroProposta] =
    useState(false);

  // dados complementares
  const [estadoCivis, setEstadoCivil] = useState([]);
  const [estados, setEstados] = useState([]);
  const [orgaos, setOrgaosEmissores] = useState([]);
  const [valores, setValoresPatrimonio] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [tipos, setTiposBene] = useState([]);
  const [cidadesNATURAL, setCidadesNATURAL] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [operacao, setOperacaoProposta] = useState([]);

  // Dados do Cliente
  const [cpf, setCPF] = useState("");
  const [valor, setValor] = useState("");
  const [prazo, setPrazo] = useState("84");
  const [dataNasc, setDataNasc] = useState("");
  const [renda, setRenda] = useState("5000");
  const [nome, setNome] = useState("");
  const [sexo, setSexo] = useState("");
  const [estadoCivil, setEstadoCivilCliente] = useState("");
  const [rg, setRG] = useState("");
  const [estadoRG, setEstadoRG] = useState("");
  const [orgaoEmissorRG, setOrgaoEmissorRG] = useState("");
  const [dataExpedicao, setDataExp] = useState("");
  const [estadoNatural, setEstadoNatural] = useState("");
  const [cidadeNatural, setCidadeNatural] = useState("");
  const [nacionalidade, setNacionalidade] = useState("1");
  const [paisOrigem] = useState("26");
  const [celular, setCelular] = useState("");
  const [cep, setCEP] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numEndereco, setNumEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [nomeMae, setNomeMae] = useState("");
  const [nomePai, setNomePai] = useState("");
  const [valorPatrimonio, setValorPatrimonio] = useState("");
  const [cliente_iletrado, setIletrado] = useState("N");
  const [banco, setBanco] = useState("");
  const [agencia, setAgencia] = useState("");
  const [conta, setConta] = useState("");
  const [matricula, setMatricula] = useState("");
  const [tipo_credito, setTipoCredito] = useState(1);
  const [estado_beneficio, setEstadoBene] = useState("");
  const [tipo_beneficio, setTipoBeneficio] = useState("");
  const [produto, setProduto] = useState(0);
  const [opcao_valor, setOpcaoValor] = useState(0);
  const [tipo_operacao, setTipoOperacao] = useState(0);
  const [validacao, setValidacao] = useState([""]);

  const refTopo = useRef();

  function limparInputs() {
    setValidacao([]);
    setOperacaoProposta([]);
    setCPF("");
    setValor("");
    setConta("");
    setMatricula("");
    setAgencia("");
    setBanco("");
    setTipoBeneficio("");
    setEstadoBene("");
    setBairro("");
    setCidade("");
    setEstado("");
    setNomeMae("");
    setNomePai("");
    setIletrado("N");
    setValorPatrimonio("");
    setEndereco("");
    setNumEndereco("");
    setCEP("");
    setCelular("");
    setEstadoNatural("");
    setCidadeNatural("");
    setNome("");
    setSexo("");
    setEstadoCivilCliente("");
    setDataExp("");
    setDataNasc("");
    setRenda("");
  }

  // Ao iniciar página, atualizar dropdowns
  useEffect(() => {
    document.title = "Digitação do Facta";
    refTopo.current.focus();
    const fetchData = async () => {
      const tkn = UseToken();
      return [
        await estadoCivilGET(tkn),
        await estadoGET(tkn),
        await orgaoEmissorGET(tkn),
        await valorPatrimonioGET(tkn),
        await bancoGET(tkn),
        await tipoBeneficioGET(tkn),
      ];
    };

    fetchData()
      .then((result) => {
        if (result) {
          setEstadoCivil(result[0]);
          setEstados(result[1]);
          setOrgaosEmissores(result[2]);
          setValoresPatrimonio(result[3]);
          setBancos(result[4]);
          setTiposBene(result[5]);
        }
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  // Atualizar dropdown de cidade natural
  useEffect(() => {
    if (estadoNatural) {
      const fetchCidades = async () => {
        const tkn = UseToken();
        return await cidadeGET(estadoNatural, tkn);
      };

      fetchCidades()
        .then((result) => {
          if (result) {
            setCidadesNATURAL(result);
            var rand = Math.floor(Math.random() * result.length);
            setCidadeNatural(result[rand - 1][1][0]);
          }
        })
        .catch(console.error);
    }
  }, [estadoNatural]);

  // Atualizar dropdown de cidade
  useEffect(() => {
    if (estado) {
      const fetchCidades = async () => {
        const tkn = UseToken();
        return await cidadeGET(estado, tkn);
      };

      fetchCidades()
        .then((result) => {
          if (result) {
            setCidades(result);
            var rand = Math.floor(Math.random() * result.length);
            setCidade(result[rand - 1][1][0]);
          }
        })
        .catch(console.error);
    }
  }, [estado]);

  // Realizar consulta de cliente com CPF
  useEffect(() => {
    if (cpf.includes("_") === false) {
      var cpf_alt = cpf.replaceAll(".", "");
      cpf_alt = cpf_alt.replaceAll("-", "");
      if (cpf_alt.length === 11) {
        setLoadingConsulta(true);
        const fetchCliente = async () => {
          const tkn = UseToken();
          return await consultarCliente(tkn, cpf_alt);
        };
        fetchCliente()
          .then((result) => {
            if (result.cliente) {
              setNome(result.cliente.NOME_CLIENTE);
              setRenda(result.cliente.RENDA ? result.cliente.RENDA : "5000");
              setSexo(result.cliente.SEXO);

              estadoCivis.forEach((estado) => {
                if (
                  estado[1].includes(result.cliente.ESTADO_CIVIL.toUpperCase())
                ) {
                  setEstadoCivilCliente(estado[0]);
                }
              });
              String.prototype.insertAt = function (index, str) {
                return this.slice(0, index) + str + this.slice(index);
              };
              setCEP(result.cliente.CEP.insertAt(5, "-"));
              setEndereco(result.cliente.ENDERECO);
              setBairro(result.cliente.BAIRRO);
              if(result.cliente.NUM_ENDERECO){
                result.cliente.NUM_ENDERECO.trim()
                ? setNumEndereco(result.cliente.NUM_ENDERECO.trim())
                : setNumEndereco("1"); 
              }
              setCelular(result.cliente.CELULAR);
              setDataNasc(result.cliente.DATA_NASCIMENTO);
              setAgencia(result.cliente.AGENCIA);

              estados.forEach((estado) => {
                if (estado[0] === result.cliente.UF) {
                  setEstado(estado[0]);
                }
              });

              orgaos.forEach((orgao) => {
                if (result.cliente.ORGAO_EMISSOR.includes(orgao[0])) {
                  setOrgaoEmissorRG(orgao[0]);
                }
              });

              setNomeMae(result.cliente.NOME_MAE);
              setNomePai(result.cliente.NOME_PAI);
              setDataExp(result.cliente.DATA_EMISSAO);
              setRG(result.cliente.RG);
              setTipoBeneficio(result.cliente.ESPECIE);
              setBanco(result.cliente.BANCO_CLIENTE_CODIGO);
              setConta(result.cliente.CONTA_CORRENTE_CLIENTE);
              setMatricula(result.cliente.MATRICULA_BANCO_CLIENTE);
              setLoadingConsulta(false);
            } else {
              if(result){
                alert(result);
              }
              setLoadingConsulta(false);
            }
          })
          .catch(console.error);
      }
    }
  }, [cpf]);

  // Formatar Celular, com DDD
  useEffect(() => {
    var cel = celular.replace(/\D/g, "");
    cel = cel.replace(/^(\d{3})(\d)/g, "($1) $2");
    cel = cel.replace(/(\d)(\d{4})$/, "$1-$2");
    setCelular(cel);
  }, [celular]);

  // Valores dos produtos Facta
  useEffect(() => {
    if (produto === "1") {
      setOpcaoValor(1);
      setTipoOperacao(1);
    }
    if (produto === "2"){
      setOpcaoValor(1);
      setTipoOperacao(0);
    }
  }, [produto, opcao_valor, tipo_operacao]);

  const handleFinalizarProposta = async (e) => {
    e.preventDefault();
    if (e.nativeEvent.submitter.textContent === "Finalizar Proposta") {
      if (operacao.length === 7) {
        setLoadingCadastroProposta(true);
        const tkn = UseToken();
        const cliente = {
          valor,
          cpf,
          prazo,
          dataNasc,
          nome,
          sexo,
          rg,
          renda,
          estadoRG,
          orgaoEmissorRG,
          estadoCivil,
          dataExpedicao,
          estadoNatural,
          cidadeNatural,
          estado,
          cidade,
          endereco,
          numEndereco,
          bairro,
          cep,
          nacionalidade,
          paisOrigem,
          nomeMae,
          nomePai,
          celular,
          valorPatrimonio,
          estado_beneficio,
          banco,
          agencia,
          conta,
          matricula,
          tipo_credito,
          tipo_beneficio,
          cliente_iletrado
        };
        console.log("cliente", cliente);
        console.log("operacao", operacao);
        const errors = [
          "ERR_NETWORK",
          "ERR_BAD_REQUEST",
          "ERR_FR_TOO_MANY_REDIRECTS",
        ];
        const result = await simulacaoValoresOperacaoPOST(
          tkn,
          produto,
          tipo_operacao,
          operacao,
          cliente
        );
        if (errors.includes(result.code)) {
          result.response.data.mensagem
            ? alert(result.response.data.mensagem)
            : alert(result.code);
        } else {
          const result_cliente = await cadastroDadosPessoaisPOST(
            tkn,
            result.id,
            cliente
          );
          if (errors.includes(result_cliente.code)) {
            alert(
              result_cliente.response.data.mensagem
                ? result_cliente.response.data.mensagem
                : result_cliente.code
            );
          } else {
            const proposta = await cadastroPropostaPOST(
              tkn,
              result.id,
              result_cliente.codigo
            );
            if (errors.includes(proposta.code)) {
              alert(
                proposta.response.data.error.mensagem
                  ? proposta.response.data.error.mensagem
                  : proposta.response.data.mensagem
              );
            } else {
              setValidacao(proposta.validacao);
              await cadastrarPropostaDigitada(
                tkn,[JSON.parse(localStorage.getItem("user"))[3],
                validacao[0], validacao[1], 
                nome, cpf]);
            }
          }
        }
        setLoadingCadastroProposta(false);
      } else {
        setLoadingCadastroProposta(false);
        alert("Faça a consulta de tabela para finalizar a proposta.");
      }
    }
  };

  const handleNovaProposta = async (e) => {
    e.preventDefault();
    refTopo.current.focus();
    limparInputs();
  };

  return (
    <>
      <NavBar />
      <div className={styles.porratoda}>
        <div className={styles.background}>
          <section className={styles.container_section}>
            <div className={styles.selecionar}>
              <div className={styles.itens_produto}>
                <label htmlFor="">Produto</label>
                <br />
                <select
                  className={styles.produto}
                  required
                  onChange={(e) => setProduto(e.target.value)}
                  name="Escolha de Produto"
                  id="select_produto"
                  value={produto}
                  ref={refTopo}
                >
                  <option value="" hidden></option>
                  <option value={1}> INSS </option>
                  <option value={2}> INSS Cartão Benefício </option>
                  <option value={3}> Auxílio Brasil </option>
                </select>
              </div>
              <div className={styles.itens_opValor}>
                <label htmlFor="">Opção de Valor</label>
                <br />
                <select
                  className={styles.OpcaoValor}
                  required
                  onChange={(e) => setOpcaoValor(e.target.value)}
                  name="Opção de Valor"
                  id="select_opcao_valor"
                  value={opcao_valor}
                >
                  <option value="" hidden></option>
                  <option value={1}> Contrato </option>
                  <option hidden={produto === "2" || produto === "1"} value={2}> Parcela </option>
                </select>
              </div>

              {produto === "1" ? (
                <div className={styles.itens_operacao}>
                  <label htmlFor="">Tipo de Operação</label>
                  <br />
                  <select
                    className={styles.TipoOperacao}
                    required
                    onChange={(e) => setTipoOperacao(e.target.value)}
                    name="Tipo de Operação"
                    id="select_tipo_operacao"
                    value={tipo_operacao}
                  >
                    {/* <option value="" hidden></option> */}
                    <option value={13}> Novo Digital </option>
                    {/* <option value={27}> Margem Compl. </option> */}
                  </select>
                </div>
              ) : (
                <span></span>
              )}
            </div>
          </section>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (produto === "1") & (opcao_valor !== 0) & (tipo_operacao !== 0) ||
          (produto === "2" || produto === "3") & (opcao_valor !== 0) ? (
          <section className={styles.banco_all}>
            <div className={styles.container_banco}>
              <fieldset>
                <legend className={styles.titulo_consulta}>
                  Consulta de Tabela
                </legend>
                <div className={styles.container_consulta}>
                  <div className={styles.bg_test}>
                    <div className={styles.test}>
                      <div className={styles.container_cpf}>
                        <label htmlFor="">CPF</label>
                        <br />
                        <MaskedInput
                          className={styles.inputs}
                          id="input_cpf"
                          placeholder="Insira o CPF"
                          name="CPF"
                          mask="999.999.999-99"
                          value={cpf}
                          onChange={(event) => setCPF(event.target.value)}
                          pattern="/^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/"
                          required
                        />
                      </div>
                      <div className={styles.container_valor}>
                        <label htmlFor="">Valor</label>
                        <br />
                        <input
                          className={styles.inputs}
                          type="number"
                          min="0"
                          step="0.01"
                          name="Valor"
                          id="input_valor"
                          placeholder="Valor"
                          value={valor}
                          required
                          onChange={(e) => setValor(e.target.value)}
                        />
                      </div>
                      <div className={styles.container_parcela}>
                        <label htmlFor="">Parcela</label>
                        <br />
                        <input
                          className={styles.inputs}
                          type="number"
                          min="0"
                          name="Prazo / Quantidade de Parcelas"
                          id="input_prazo"
                          placeholder="Prazo"
                          value={prazo}
                          required
                          onChange={(e) => setPrazo(e.target.value)}
                        />
                      </div>
                      <div className={styles.container_renda}>
                        <label htmlFor="">Renda</label>
                        <br />
                        <input
                          className={styles.inputs}
                          type="number"
                          min="0"
                          name="renda"
                          id="input_renda"
                          value={renda}
                          required
                          onChange={(e) => setRenda(e.target.value)}
                        />
                      </div>
                      <div className={styles.container_dataNasc}>
                        <label htmlFor="">Data de Nascimento</label>
                        <br />
                        <input
                          className={styles.inputs}
                          type="date"
                          name="Data de Nascimento"
                          id="input_data_nascimento"
                          placeholder="Data de Nascimento"
                          value={dataNasc}
                          required
                          onChange={(e) => setDataNasc(e.target.value)}
                          onInvalid={(e) =>
                            e.target.setCustomValidity(
                              "Preencha a data de nascimento"
                            )
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <Tabela
                        className={styles.tabelinhadejesus}
                        produto={produto}
                        opcao_valor={opcao_valor}
                        setTab={setOperacaoProposta}
                        valor={produto === '2' ? (valor * 0.05 * 27.5 * 0.7).toFixed(2) : valor}
                        cpf={cpf}
                        dataNasc={dataNasc}
                        prazo={prazo}
                        tipo_operacao={tipo_operacao ? tipo_operacao : 0}
                        renda={renda}
                      />
                      {isLoadingConsulta ? <LoadingSpinner /> : <span></span>}
                      {operacao[0] ? (
                        <>
                          <h3>Tabela Selecionada: {operacao[0]}</h3>
                          <h3>
                            Taxa: {operacao[2]} Parcela: {operacao[3]} Prazo:{" "}
                            {operacao[4]} Coeficiente: {operacao[6]} Contrato:{" "}
                            {operacao[5]}{" "}
                          </h3>
                        </>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>
                </div>
              </fieldset>

              {operacao.length === 7 ? (
                <form onSubmit={handleFinalizarProposta}>
                  {/* parte de cadastro de proposta */}
                  <div className={styles.container_proposta}>
                    <div className={styles.testee}>
                      <span className={styles.titulo_cadastro}>
                        Cadastro de Proposta
                      </span>
                      <div className={styles.cotainer_dados_clientes}>
                        <fieldset>
                          <legend className={styles.legend}>
                            Dados do Cliente
                          </legend>
                          <div className={styles.container_input_cadastro}>
                            <div className={styles.nome_renda_tell}>
                              <div className={styles.container_nome}>
                                <label htmlFor="">Nome Completo</label>
                                <br />
                                <input
                                  className={styles.inputs_cadastro_name}
                                  type="text"
                                  name="nome"
                                  id="input_nome"
                                  placeholder="Nome"
                                  value={nome}
                                  required
                                  onChange={(e) => setNome(e.target.value)}
                                />
                              </div>
                              <div className={styles.container_valorPatri}>
                                <label htmlFor="">Valor do Patrimônio</label>
                                <br />
                                <select
                                  className={styles.patrimonio}
                                  id={valores}
                                  required
                                  onChange={(e) =>
                                    setValorPatrimonio(e.target.value)
                                  }
                                >
                                  <option hidden value=""></option>
                                  {valores?.map((val) => (
                                    <option value={val[0]} key={val[0]}>
                                      {val[1]}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className={styles.container_telefone}>
                                <label htmlFor="">Telefone</label>
                                <br />
                                <input
                                  className={styles.tell}
                                  required
                                  type="tel"
                                  min="0"
                                  id="input_celular"
                                  value={celular}
                                  maxLength="16"
                                  pattern="\(\d{3}\)\s*\d{5}-\d{4}"
                                  onChange={(e) => setCelular(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className={styles.group_dropdown}>
                              {/* Sexo */}
                              <div className={styles.container_sexo}>
                                <label htmlFor="Sexo">Sexo</label>
                                <br />
                                <select
                                  value={sexo}
                                  className={styles.sexo}
                                  required
                                  onChange={(e) => setSexo(e.target.value)}
                                >
                                  <option value="" hidden></option>
                                  <option value="M"> Masculino </option>
                                  <option value="F"> Feminino </option>
                                </select>
                              </div>

                              {/* Nacionalidade */}
                              <div className={styles.nacionalidade}>
                                <div className={styles.brasileiro}>
                                  <input
                                    defaultChecked
                                    type="radio"
                                    name="nacionalidade"
                                    id="op1"
                                    value={1}
                                    onSelect={(e) =>
                                      setNacionalidade(e.target.value)
                                    }
                                  />
                                  <label htmlFor="op1"> Brasileiro </label>
                                </div>

                                <div className={styles.estrangeiro}>
                                  <input
                                    className={styles.input_radio_nacionalidade}
                                    type="radio"
                                    name="nacionalidade"
                                    id="op2"
                                    value={2}
                                    onSelect={(e) =>
                                      setNacionalidade(e.target.value)
                                    }
                                  />
                                  <label htmlFor="op2"> Estrangeiro </label>
                                </div>
                              </div>

                              {/* Estado Civis */}
                              <div className={styles.estado_civis}>
                                <div className={styles.container_estadoCivil}>
                                  <label htmlFor="">Estado Civil</label>
                                  <br />
                                  <select
                                    className={styles.estado_civil}
                                    id="estados-civis"
                                    required
                                    value={estadoCivil}
                                    onChange={(e) =>
                                      setEstadoCivilCliente(e.target.value)
                                    }
                                  >
                                    <option hidden value=""></option>
                                    {estadoCivis
                                      ?.sort((a, b) => (a[1] > b[1] ? 1 : -1))
                                      .map((estado) => (
                                        <option
                                          value={estado[0]}
                                          key={estado[0]}
                                        >
                                          {estado[1]}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>

                              <div className={styles.container_radio}>
                                <div className={styles.iletrado}>
                                  <input
                                    type="checkbox"
                                    name="cliente"
                                    id="iletrado"
                                    value={"S"}
                                    onSelect={(e) =>
                                      setIletrado(e.target.value)
                                    }
                                  />
                                  <label htmlFor="iletrado">
                                    Iletrado / <br /> Impossibilitado
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className={styles.container_rg_estado_data}>
                              <div className={styles.container_RG}>
                                <label htmlFor="RG">RG</label>
                                <br />
                                <input
                                  className={styles.input_rg}
                                  required
                                  type="text"
                                  name="RG"
                                  id=""
                                  placeholder="RG"
                                  value={rg}
                                  onChange={(e) => setRG(e.target.value)}
                                />
                              </div>
                              <div className={styles.container_orgaoEmissor}>
                                <label htmlFor="orgao-emissor">
                                  Orgão Emissor
                                </label>
                                <br />
                                <select
                                  className={styles.input_orgao_emissor}
                                  value={orgaoEmissorRG}
                                  id="orgao-emissor"
                                  required
                                  onChange={(e) =>
                                    setOrgaoEmissorRG(e.target.value)
                                  }
                                >
                                  <option hidden value=""></option>
                                  {orgaos
                                    ?.sort((a, b) => (a[0] > b[0] ? 1 : -1))
                                    .map((orgao) => (
                                      <option value={orgao[0]} key={orgao[0]}>
                                        {orgao[0]}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className={styles.container_estadoRG}>
                                <label htmlFor="estado-cliente">
                                  Estado do RG
                                </label>
                                <br />
                                <select
                                  className={styles.estado_rg}
                                  id="estado-cliente"
                                  required
                                  onChange={(e) => setEstadoRG(e.target.value)}
                                >
                                  <option hidden value=""></option>
                                  {estados
                                    ?.sort((a, b) => (a[0] > b[0] ? 1 : -1))
                                    .map((estado) => (
                                      <option
                                        value={estado[0]}
                                        key={rg + estado[0]}
                                      >
                                        {estado[0]}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className={styles.container_dataEmissao}>
                                <label htmlFor="input_data_emissao">
                                  Data de Emissão
                                </label>
                                <br />
                                <input
                                  className={styles.input_dataemissao}
                                  required
                                  type="date"
                                  name="emissao"
                                  id="input_data_emissao"
                                  placeholder="Data de Emissão"
                                  value={dataExpedicao}
                                  onChange={(e) => setDataExp(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className={styles.nome_paimae}>
                              <div className={styles.container_nomeMae}>
                                <label htmlFor="">Nome da Mãe</label>
                                <br />
                                <input
                                  className={
                                    styles.inputs_pai_mae_estado_cidade
                                  }
                                  type="text"
                                  required
                                  value={nomeMae}
                                  onChange={(e) => setNomeMae(e.target.value)}
                                />
                              </div>
                              <div className={styles.container_nomePai}>
                                <label htmlFor="">Nome do Pai</label>
                                <br />
                                <input
                                  className={
                                    styles.inputs_pai_mae_estado_cidade
                                  }
                                  required
                                  type="text"
                                  value={nomePai}
                                  onChange={(e) => setNomePai(e.target.value)}
                                />
                              </div>
                              <div className={styles.container_estadoNatural}>
                                <label htmlFor="">Estado Natural</label>
                                <br />
                                <select
                                  className={styles.inputs_pai_mae_estado}
                                  required
                                  onChange={(e) =>
                                    setEstadoNatural(e.target.value)
                                  }
                                >
                                  <option hidden value=""></option>
                                  {estados
                                    ?.sort((a, b) => (a[1] > b[1] ? 1 : -1))
                                    .map((estado) => (
                                      <option value={estado[0]} key={estado[0]}>
                                        {estado[1]}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className={styles.container_cidadeNatural}>
                                <label htmlFor="">Cidade Natural</label>
                                <br />
                                <select
                                  className={
                                    styles.inputs_pai_mae_estado_cidade
                                  }
                                  id="cidade-cliente"
                                  onChange={(e) =>
                                    setCidadeNatural(e.target.value)
                                  }
                                >
                                  <option hidden value=""></option>
                                  {cidadesNATURAL
                                    ?.sort((a, b) =>
                                      a[1][1].nome > b[1][1].nome ? 1 : -1
                                    )
                                    .map((cidadeN) => (
                                      <option
                                        value={cidadeN[1][0]}
                                        key={cidadeN[1][0]}
                                      >
                                        {cidadeN[1][1].nome}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>

                            <div className={styles.valor_tipodebene}>
                              <div className={styles.container_beneficio}>
                                <label htmlFor="">Benefício</label>
                                <br />
                                <select
                                  value={tipo_beneficio}
                                  required
                                  className={styles.dropdown_beneficio}
                                  onChange={(e) =>
                                    setTipoBeneficio(e.target.value)
                                  }
                                >
                                  <option hidden value=""></option>
                                  {tipos?.map((tipoBene) => (
                                    <option
                                      value={tipoBene[0]}
                                      key={tipoBene[0]}
                                      hover={tipoBene[1]}
                                    >
                                      {tipoBene[0] + " - " + tipoBene[1]}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className={styles.container_estadoBeneficio}>
                                <label htmlFor="">Estado do Benefício</label>
                                <br />
                                <select
                                  className={styles.dropdown_estado}
                                  required
                                  onChange={(e) =>
                                    setEstadoBene(e.target.value)
                                  }
                                >
                                  <option hidden value=""></option>
                                  {estados
                                    ?.sort((a, b) => (a[1] > b[1] ? 1 : -1))
                                    .map((estadoBenef) => (
                                      <option
                                        value={estadoBenef[0]}
                                        key={estadoBenef[0]}
                                      >
                                        {estadoBenef[1]}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>

                      <div className={styles.container_fieldset}>
                        <fieldset>
                          <legend> Endereço do Cliente </legend>
                          <div className={styles.container_endereco_cliente}>
                            <div className={styles.container_CEP}>
                              <label htmlFor="">CEP</label>
                              <br />
                              <MaskedInput
                                className={styles.inputUm}
                                mask="99999-999"
                                placeholder="CEP"
                                name="CEP"
                                value={cep}
                                onChange={(event) => setCEP(event.target.value)}
                                id="input_cep"
                                pattern="[0-9]{5}-[0-9]{3}"
                                oninvalid="alert('CEP incorreto.');"
                                required
                              />
                            </div>
                            <div className={styles.container_rua}>
                              <label htmlFor="">Rua</label>
                              <br />
                              <input
                                className={styles.inputs_residencia_rua}
                                required
                                type="text"
                                placeholder="Endereço"
                                value={endereco}
                                onChange={(e) => setEndereco(e.target.value)}
                              />
                            </div>
                            <div className={styles.container_bairro}>
                              <label htmlFor="">Bairro</label>
                              <br />
                              <input
                                className={styles.inputs_residencia}
                                required
                                type="text"
                                placeholder="Bairro"
                                value={bairro}
                                onChange={(e) => setBairro(e.target.value)}
                              />
                            </div>
                            <div className={styles.container_numero}>
                              <label htmlFor="">Número</label>
                              <br />
                              <input
                                className={styles.inputs_residencia}
                                required
                                type="number"
                                min="1"
                                placeholder="Número"
                                value={numEndereco}
                                onChange={(e) => setNumEndereco(e.target.value)}
                              />
                            </div>
                            <div className={styles.container_estado}>
                              <label htmlFor="">Estado</label>
                              <br />
                              <select
                                className={styles.endereco_estado_cidade}
                                required
                                value={estado}
                                onChange={(e) => setEstado(e.target.value)}
                              >
                                <option hidden value=""></option>
                                {estados
                                  ?.sort((a, b) => (a[1] > b[1] ? 1 : -1))
                                  .map((estado) => (
                                    <option value={estado[0]} key={estado[0]}>
                                      {estado[1]}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className={styles.container_cidade}>
                              <label htmlFor="">Cidade</label>
                              <br />
                              <select
                                className={styles.endereco_estado_cidade}
                                id="cidade-cliente"
                                onChange={(e) => setCidade(e.target.value)}
                              >
                                <option hidden value=""></option>
                                {cidades
                                  ?.sort((a, b) =>
                                    a[1][1].nome > b[1][1].nome ? 1 : -1
                                  )
                                  .map((cidadeCli) => (
                                    <option
                                      value={cidadeCli[1][0]}
                                      key={cidadeCli[1][0]}
                                    >
                                      {cidadeCli[1][1].nome}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      {/* Dados Bancários */}
                      <div className={styles.container_dados_bancarios}>
                        <fieldset>
                          <legend> Dados Bancários </legend>
                          <div className={styles.dados_bancarios_container}>
                            <div>
                              <label htmlFor="">Banco</label>
                              <br />
                              <select
                                value={banco}
                                className={styles.inputUm}
                                required
                                onChange={(e) => setBanco(e.target.value)}
                              >
                                <option hidden value=""></option>
                                {bancos?.map((banco) => (
                                  <option value={banco[0]} key={banco[0]}>
                                    {banco[0] + " - " + banco[1]}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className={styles.container_conta}>
                              <label htmlFor="">Conta</label>
                              <br />
                              <input
                                className={styles.inputsBanco}
                                required
                                type="text"
                                pattern="[0-9]*"
                                placeholder="Conta"
                                value={conta}
                                onChange={(e) => setConta(e.target.value)}
                              />
                            </div>
                            <div className={styles.container_agencia}>
                              <label htmlFor="">Agência</label>
                              <br />

                              <input
                                className={styles.inputsBanco}
                                required
                                type="number"
                                min="0"
                                placeholder="Agência"
                                value={agencia}
                                onChange={(e) => setAgencia(e.target.value)}
                              />
                            </div>
                            <div className={styles.container_matricula}>
                              <label htmlFor="">Matrícula</label>
                              <br />
                              <input
                                className={styles.inputsBanco}
                                required
                                type="number"
                                min="0"
                                placeholder="Matricula"
                                value={matricula}
                                onChange={(e) => setMatricula(e.target.value)}
                              />
                            </div>

                            <div className={styles.tipo_credito}>
                              <div>
                                <input
                                  className={styles.contaCorrente}
                                  checked
                                  type="radio"
                                  name="tipoConta"
                                  required
                                  id="contaCorrente"
                                  value={1}
                                  onChange={(e) =>
                                    setTipoCredito(e.target.value)
                                  }
                                />
                                <label htmlFor="contaCorrente">
                                  Conta Corrente
                                </label>
                              </div>

                              <div className={styles.div_cartaoMag}>
                                <input
                                  className={styles.cartaoMagnetico}
                                  type="radio"
                                  name="tipoConta"
                                  id="CartaoMagnetico"
                                  value={2}
                                  onChange={(e) =>
                                    setTipoCredito(e.target.value)
                                  }
                                />
                                <label htmlFor="CartaoMagnetico">
                                  Cartão Magnético
                                </label>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      {isLoadingCadastroProposta ? (
                        <LoadingSpinner />
                      ) : (
                        <div
                          className={styles.container_btn_finalizar_newProposta}
                        >
                          {!validacao[1] ? (
                            <div
                              className={
                                styles.container_btn_finalizar_proposta
                              }
                            >
                              <button
                                className={styles.btn_salvar}
                                type="submit"
                              >
                                Finalizar Proposta
                              </button>
                            </div>
                          ) : (
                            <>
                              <button
                                className={styles.btn_nova_proposta}
                                onClick={handleNovaProposta}
                              >
                                Nova Proposta
                              </button>
                            </>
                          )}
                        </div>
                      )}
                      {validacao[1] ? (
                        <div className={styles.container_btn}>
                          <p>Código Facta:</p>
                          <CopyToClipboard 
                            text={validacao[0]}
                            onCopy={() => {
                              alert("Código copiado!")
                            }}
                          >
                            <p className={styles.validacao}>{validacao[0]}</p>
                          </CopyToClipboard>
                          <p> - Link de Assinatura:</p>
                          <CopyToClipboard 
                            text={validacao[1]}
                            onCopy={() => {
                              alert("Link copiado!");
                            }}
                          >
                            <p className={styles.validacao}>{validacao[1]}</p>
                          </CopyToClipboard>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </form>
              ) : (
                <span></span>
              )}
            </div>
          </section>
        ) : (
          <span></span>
        )}
      </div>
    </>
  );
}
