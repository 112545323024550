import React, { useState, useEffect } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { fetchTabelas } from "../JS/fetchTabelas";
import { fetchBancoPort } from "../JS/fetchBancosPort";
import "./style.css";
import { postDados } from "../JS/incluir";
import UseCodAgilus from "../../../../hooks/UseCodAgilus";
import SuccessModal from "../ModalSucess";
import dataAtual from "../../../scripts/dataAtual.js";

export default function ModalManual({ isOpen, onClose, data }) {
  const [tabelas, setTabelas] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [bancoSelecionadoId, setBancoSelecionadoId] = useState("");
  const [banco, setBanco] = useState("");
  const [tabelaSelecionadaId, setTabelaSelecionadaId] = useState("");
  const [ddd, setDdd] = useState("");
  const [tel, setTel] = useState("");
  const [salario, setSal] = useState("");
  const [vendedor, setVendedor] = useState(null);
  // const [banco, setBanco] = useState('');
  const [contrato, setContrato] = useState("");
  const [vlParcela, setVlParcela] = useState("");
  const [saldo, setSaldo] = useState("");
  const [prazo, setPrazo] = useState("");
  const [taxa, setTaxa] = useState("");
  const [dataContrato, setDataContrato] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  
  

  console.log(data);
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTabelas();
      const bancosData = await fetchBancoPort();
      setTabelas(data);
      setBancos(bancosData);
      console.log(data);
    };

    const codAgilus = UseCodAgilus();
    setVendedor(codAgilus);
    fetchData();
  }, [data]);
  const handleDddChange = (event) => {
    const value = event.target.value;
    if (value.length <= 2 && /^[0-9]*$/.test(value)) {
      setDdd(value);
    }
  };
  const handleTelChange = (event) => {
    const value = event.target.value;
    if (value.length <= 9 && /^[0-9]*$/.test(value)) {
      setTel(value);
    }
  };
  const handleSelecaoTabela = (e) => {
    setTabelaSelecionadaId(e.target.value);
  };
  const handleSalarioChange = (event) => {
    setSal(event.target.value);
  };
  const handleContratoChange = (event) => {
    const value = event.target.value;
    // Usa uma expressão regular para verificar se o valor contém apenas números
    if (/^[0-9]*$/.test(value)) {
      setContrato(value);
    }
  };
  const handleParcelaChange = (event) => {
    const value = event.target.value;
    // Permite apenas números e ponto, e limita o comprimento a 7 caracteres
    if (/^[0-9.]*$/.test(value) && value.length <= 7) {
      setVlParcela(value);
    }
  };
  const handleSaldoChange = (event) => {
    const value = event.target.value;
    // Usa uma expressão regular para verificar se o valor contém apenas números
    if (/^[0-9]*$/.test(value)) {
      setSaldo(value);
    }
  };
  const handlePrazoChange = (event) => {
    const value = event.target.value;
    // Permite apenas números e limita a 2 caracteres
    if (/^[0-9]{0,2}$/.test(value)) {
      setPrazo(value);
    }
  };
  const handleTaxaChange = (event) => {
    const value = event.target.value;
    // Permite apenas números e ponto, e limita a 4 caracteres
    if (/^\d*\.?\d{0,2}$/.test(value) && value.length <= 4) {
      setTaxa(value);
    }
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const handleSubmit = async () => {
    const formData = {
      Nome: data.informacoesobrigatorias.nome,
      Cpf: data.informacoesobrigatorias.cpf,
      Matricula: data.informacoesobrigatorias.beneficio,
      TipoContrato: data.camposFixos.TipoContrato,
      TipoOperacao: data.camposFixos.TipoOperacao,
      DataContrato: await dataAtual(),
      ConvenioId: data.camposFixos.ConvenioId,
      BancoId: data.camposFixos.BancoIdFixo,
      TabelaId: tabelaSelecionadaId,
      PromotoraId: data.camposFixos.PromotoraId,
      ValorBruto: saldo,
      Prazo: prazo,
      ValorParcela: vlParcela,
      VendedorId: vendedor,
      DadosPortabilidade: {
        BancoId: bancoSelecionadoId,
        Saldo: saldo,
        PrazoRestante: prazo,
        NumeroContrato: contrato,
        ValorParcela: vlParcela,
      },
      DataNascimento: data.informacoesobrigatorias.dtNascimento,
      Especie: data.informacoesobrigatorias.especie,
      Salario: salario,
      NomeMae: data.informacoesobrigatorias.mae,
      TipoDocumento: data.camposFixos.TipoDocumento,
      NumeroDocumento: data.informacoesobrigatorias.cpf,
      OrgaoExpedidor: data.informacoesobrigatorias.estado,
      DataExpedicao: await dataAtual(),
      Naturalidade: data.camposFixos.Naturalidade,
      EstadoCivil: data.camposFixos.EstadoCivil,
      Sexo: data.informacoesobrigatorias.sexo,
      UfSolicitacaoBeneficio: data.informacoesobrigatorias.estado,
      DadosBancarios: {
        TipoConta: data.informacoesobrigatorias.tipoConta,
        BancoId: data.informacoesobrigatorias.codigoBancoObg,
        Agencia: data.informacoesobrigatorias.agencia,
        NumeroConta: data.informacoesobrigatorias.conta,
      },
      Endereco: {
        Rua: data.informacoesobrigatorias.endereco,
        Numero: "",
        Complemento: "",
        Bairro: data.informacoesobrigatorias.bairro,
        Cidade: data.informacoesobrigatorias.cidade,
        Uf: data.informacoesobrigatorias.estado,
        Cep: data.informacoesobrigatorias.cep,
      },
      Telefone: {
        Ddd: ddd,
        Numero: tel,
      },
    };

    try {
      console.log({
        banco,
        contrato,
        vlParcela,
        saldo,
        prazo,
        taxa,
        ddd,
        tel,
        salario,
      });

      const response = await postDados(formData);
      console.log("Dados salvos com sucesso:", response);
      setSuccessMessage(`Mensagem: '${response.Mensagem}', Id: ${response.Id}`);
      setIsSuccessModalOpen(true);
  
    } catch (error) {
      console.error("Erro ao salvar os dados:", error);
    }
  };
  return (
    <>
      <Dialog.Root
        open={isOpen}
        onOpenChange={(isOpen) => !isOpen && onClose()}
      >
        <Dialog.Trigger asChild>
          <button></button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="banco">
                Banco
              </label>
              <select
                className="Input"
                value={bancoSelecionadoId}
                onChange={(e) => setBancoSelecionadoId(e.target.value)}
              >
                <option value="">Selecione um banco</option>
                {bancos.map((banco) => (
                  <option key={banco.Id} value={banco.Id}>
                    {banco.Nome}
                  </option>
                ))}
              </select>
            </fieldset>

            <fieldset className="Fieldset">
              <label className="Label" htmlFor="banco">
                Contrato
              </label>
              <input
                className="Input"
                type="text"
                value={contrato}
                onChange={handleContratoChange}
                placeholder="Contrato"
              />
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="banco">
                Parcela
              </label>
              <input
                className="Input"
                id="parcela"
                type="text"
                value={vlParcela}
                onChange={handleParcelaChange}
                placeholder="Valor da Parcela"
              />
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="banco">
                Saldo
              </label>
              <input
                className="Input"
                type="number"
                value={saldo}
                onChange={handleSaldoChange}
                placeholder="Saldo"
              />
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="banco">
                Prazo
              </label>
              <input
                className="Input"
                id="prazo"
                type="text"
                value={prazo}
                onChange={handlePrazoChange}
                placeholder="Prazo"
                maxLength="2" // Restringe o comprimento do input a 2 dígito
              />
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="banco">
                Taxa
              </label>
              <input
                className="Input"
                id="taxa"
                type="text"
                value={taxa}
                onChange={handleTaxaChange}
                placeholder="Taxa (%)"
                maxLength="4" // Restringe o comprimento do input a 4 caracteres
              />
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="dataContrato">
                Data do Contrato
              </label>
              <input
                className="Input"
                type="date"
                id="dataContrato"
                value={dataContrato}
                onChange={(e) => setDataContrato(e.target.value)}
                placeholder="Selecione a data do contrato"
              />
            </fieldset>

            <fieldset className="Fieldset">
              <label className="Label" htmlFor="ddd">
                DDD
              </label>
              <input
                className="Input"
                type="text"
                value={ddd}
                onChange={handleDddChange}
                placeholder="DDD"
                pattern="\d{0,2}"
                maxLength="2"
              />
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="telefone">
                Telefone
              </label>
              <input
                className="Input"
                type="text"
                value={tel}
                onChange={handleTelChange}
                placeholder="Telefone"
                pattern="\d{0,9}"
                maxLength="9"
              />
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="Tabela banco">
                Tabela Bancaria
              </label>
              <select
                className="Input"
                id="tabelaBancaria"
                value={tabelaSelecionadaId}
                onChange={handleSelecaoTabela}
              >
                <option value="" disabled>
                  Selecione uma tabela
                </option>
                {tabelas.map((tabela) => (
                  <option key={tabela.Id} value={tabela.Id}>
                    {tabela.Nome}
                  </option>
                ))}
              </select>
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="salario">
                Salario
              </label>
              <input
                className="Input"
                type="text"
                value={salario}
                onChange={handleSalarioChange}
                placeholder="Salário"
              />
            </fieldset>

            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            ></div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <Cross2Icon />
              </button>
            </Dialog.Close>
            <div className="modal-actions">
            <button onClick={handleSubmit} className="Button green">
        Salvar
      </button>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
        
      />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}
