import React from "react";
import { useEffect, useState } from "react";
import NavBar from "../../../pages/Navbar";
import styles from "./Pagbank.module.css";
import Tabela from "../Pagbank/TabelaPagbank/Table";
import { simulacaoValoresOperacaoPOST } from "../../../../api/api-facta";
import MaskedInput from "../Pagbank/js/Mask";
import {
  estadoCivilGET,
  estadoGET,
  orgaoEmissorGET,
  bancoGET,
  valorPatrimonioGET,
  tipoBeneficioGET,
  cidadeGET,
} from "../../../../api/metodosComplementares";
import UseToken from "../../../../hooks/UseToken";
import { consultarCliente } from "../../../../api/api-portal";

export default function DigitacaoPagBank() {
  const [isLoading, setLoading] = useState(true);
  const [isLoadingConsulta, setLoadingConsulta] = useState(false);
  const [showContent, setShowContent] = useState(false);

  // dados complementares
  const [estadoCivis, setEstadoCivil] = useState([]);
  const [estados, setEstados] = useState([]);
  const [orgaos, setOrgaosEmissores] = useState([]);
  const [valores, setValoresPatrimonio] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [tipos, setTiposBene] = useState([]);
  const [cidadesNATURAL, setCidadesNATURAL] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [operacao, setOperacaoProposta] = useState([]);

  // Dados do Cliente
  const [cpf, setCPF] = useState("");
  const [valorAF, setValorAF] = useState("");
  const [prazo, setPrazo] = useState("84");
  const [dataNasc, setDataNasc] = useState("");
  const [nome, setNome] = useState("");
  const [sexo, setSexo] = useState("");
  const [estadoCivil, setEstadoCivilCliente] = useState("");
  const [rg, setRG] = useState("");
  const [estadoRG, setEstadoRG] = useState("");
  const [orgaoEmissorRG, setOrgaoEmissorRG] = useState("");
  const [dataExpedicao, setDataExp] = useState("");
  const [estadoNatural, setEstadoNatural] = useState("");
  const [cidadeNatural, setCidadeNatural] = useState("");
  const [nacionalidade, setNacionalidade] = useState("1");
  const [paisOrigem] = useState("26");
  const [celular, setCelular] = useState("");
  const [renda, setRenda] = useState("");
  const [cep, setCEP] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numEndereco, setNumEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [nomeMae, setNomeMae] = useState("");
  const [nomePai, setNomePai] = useState("");
  const [valorPatrimonio, setValorPatrimonio] = useState("");
  const [cliente_iletrado, setIletrado] = useState("N");
  const [banco, setBanco] = useState("");
  const [agencia, setAgencia] = useState("");
  const [conta, setConta] = useState("");
  const [matricula, setMatricula] = useState("");
  const [tipo_credito, setTipoCredito] = useState("");
  const [estado_beneficio, setEstadoBene] = useState("");
  const [tipo_beneficio, setTipoBeneficio] = useState("88");

  function handleContent() {
    setShowContent(!showContent);
  }

  function ExclusiveContent(infoTabela) {
    return (
      <Tabela
        setTab={infoTabela.setTab}
        valorAF={infoTabela.valorAF}
        cpf={infoTabela.cpf}
        dataNasc={infoTabela.dataNasc}
        prazo={infoTabela.prazo}
      />
    );
  }

  useEffect(() => {
    document.title = "Digitação PagSeguro";
    const fetchData = async () => {
      const tkn = UseToken();
      return [
        await estadoCivilGET(tkn),
        await estadoGET(tkn),
        await orgaoEmissorGET(tkn),
        await valorPatrimonioGET(tkn),
        await bancoGET(tkn),
        await tipoBeneficioGET(tkn),
      ];
    };

    fetchData()
      .then((result) => {
        if (result) {
          setEstadoCivil(result[0]);
          setEstados(result[1]);
          setOrgaosEmissores(result[2]);
          setValoresPatrimonio(result[3]);
          setBancos(result[4]);
          setTiposBene(result[5]);
        }
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (estadoNatural) {
      const fetchCidades = async () => {
        const tkn = UseToken();
        return await cidadeGET(estadoNatural, tkn);
      };

      fetchCidades()
        .then((result) => {
          if (result) {
            setCidadesNATURAL(result);
          }
        })
        .catch(console.error);
    }
  }, [estadoNatural]);

  useEffect(() => {
    if (estado) {
      const fetchCidades = async () => {
        const tkn = UseToken();
        return await cidadeGET(estado, tkn);
      };

      fetchCidades()
        .then((result) => {
          if (result) {
            setCidades(result);
          }
        })
        .catch(console.error);
    }
  }, [estado]);

  useEffect(() => {
    if (cpf.includes("_") == false) {
      var cpf_alt = cpf.replaceAll(".", "");
      cpf_alt = cpf_alt.replaceAll("-", "");
      if (cpf_alt.length == 11) {
        setLoadingConsulta(true);
        const fetchCliente = async () => {
          const tkn = UseToken();
          return await consultarCliente(tkn, cpf_alt);
        };
        fetchCliente()
          .then((result) => {
            if (result) {
              setNome(result.cliente.NOME_CLIENTE);
              setRenda(result.cliente.RENDA);
              setSexo(result.cliente.SEXO);

              estadoCivis.map((estado) => {
                if (
                  estado[1].includes(result.cliente.ESTADO_CIVIL.toUpperCase())
                ) {
                  setEstadoCivilCliente(estado[0]);
                }
              });

              setCEP(result.cliente.CEP);
              setEndereco(result.cliente.ENDERECO);
              setBairro(result.cliente.BAIRRO);
              setNumEndereco(result.cliente.NUM_ENDERECO);
              setCelular(result.cliente.CELULAR);
              setDataNasc(result.cliente.DATA_NASCIMENTO);
              setAgencia(result.cliente.AGENCIA);

              estados.map((estado) => {
                if (estado[0] == result.cliente.UF) {
                  setEstado(estado[0]);
                }
              });

              orgaos.map((orgao) => {
                if (result.cliente.ORGAO_EMISSOR.includes(orgao[0])) {
                  setOrgaoEmissorRG(orgao[0]);
                }
              });

              setNomeMae(result.cliente.NOME_MAE);
              setNomePai(result.cliente.NOME_PAI);
              setDataExp(result.cliente.DATA_EMISSAO);
              setRG(result.cliente.RG);
              setBanco(result.cliente.BANCO_CLIENTE_CODIGO);
              setConta(result.cliente.CONTA_CORRENTE_CLIENTE);
              setMatricula(result.cliente.MATRICULA_BANCO_CLIENTE);
              if (result.cliente.CONTA_POUPANCA == 0) {
                setTipoCredito(1);
              } else {
                setTipoCredito(2);
              }
              setLoadingConsulta(false);
            } else {
              alert("Falha na consulta de dados do Cliente com CPF dado.");
            }
          })
          .catch(console.error);
      }
    }
  }, [cpf]);

  useEffect(() => {
    var cel = celular.replace(/\D/g, "");
    cel = cel.replace(/^(\d{3})(\d)/g, "($1) $2");
    cel = cel.replace(/(\d)(\d{4})$/, "$1-$2");
    setCelular(cel);
  }, [celular]);

  return (
    <>
      <NavBar />
      <nav className={styles.banner}>
        <div className={styles.container_profile}>
          <h1 className={styles.banner_title}>Digitação Pagbank</h1>
        </div>
      </nav>

      <section className={styles.container_fundo}>
        <div className={styles.container_consulta_pagbank}>
          <span className={styles.titulo_consulta_pagbank}>
            Consulta de Cliente
          </span>
          <div className={styles.container_inputs_pagbank}>
            <div>
              <label htmlFor="CPF">CPF</label>
              <br />
              <MaskedInput
                value={cpf}
                onChange={(event) => setCPF(event.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="ValorAF">Valor da AF</label>
              <br />
              <input
                className={styles.inputs_consulta_pagbank_rigth}
                type="number"
                name=""
                id="ValorAF"
                value={valorAF}
                required
                onChange={(e) => setValorAF(e.target.value)}
              />
            </div>
            <div className={styles.container_Prazo}>
              <label htmlFor="Prazo">Prazo</label>
              <br />
              <input
                className={styles.inputs_consulta_pagbank}
                type="number"
                name=""
                id="Prazo"
                value={prazo}
                onChange={(e) => setPrazo(e.target.value)}
                required
              />
            </div>
            <div className={styles.container_data_nascimento}>
              <label htmlFor="DataNascimentto">Data de Nascimento</label>
              <br />
              <input
                className={styles.inputs_consulta_pagbank_rigth_date}
                type="date"
                name=""
                id="DataNascimentto"
                value={dataNasc}
                required
                onChange={(e) => setDataNasc(e.target.value)}
                onInvalid={(e) =>
                  e.target.setCustomValidity("Preencha a data de nascimento")
                }
              />
            </div>
            <div className={styles.container_btn_consulta_pagbank}>
              <button
                onClick={handleContent}
                className={styles.btn_consultar_pagbank}
                type="submit"
              >
                Consultar
              </button>
              <br />
            </div>
            {showContent && (
              <ExclusiveContent
                setTab={setOperacaoProposta}
                valorAF={valorAF}
                cpf={cpf}
                dataNasc={dataNasc}
                prazo={prazo}
              />
            )}
          </div>
        </div>

        {/* container de proposta */}
        <div className={styles.container_proposta_pagbank}>
          <span className={styles.titulo_proposta_pagbank}>
            Cadastro de Proposta
          </span>
          <div className={styles.container_input_cadastro_pagbank}>
            <div className={styles.container_nome_renda_tel}>
              <div className={styles.container_nome}>
                <label htmlFor="Nome">Nome</label>
                <br />
                <input
                  className={styles.nome_pagbank}
                  type="text"
                  name=""
                  id="Nome"
                />
              </div>
              <div>
                <label htmlFor="Renda">Renda</label>
                <br />
                <input
                  className={styles.renda_pagbank}
                  type="number"
                  name=""
                  id="Renda"
                />
              </div>
              <div>
                <label htmlFor="Tel">Celular</label>
                <br />
                <input
                  className={styles.celular_pagbank}
                  type="tel"
                  min="0"
                  id="Tel"
                  value={celular}
                  maxLength="16"
                  pattern="\(\d{3}\)\s*\d{5}-\d{4}"
                  onChange={(e) => setCelular(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.sexo_nacionalidade_iletrado_estacoCivil}>
              <div className={styles.dropdown_sexo}>
                <label htmlFor="Sexo">Sexo</label>
                <br />
                <select name="" id="Sexo">
                  <option hidden value=""></option>
                  <option value="">Feminino</option>
                  <option value="">Masculino</option>
                </select>
              </div>
              <div className={styles.nacionalidade}>
                <div className={styles.brasileiro}>
                  <input type="radio" name="nacionalidade" id="Brasileiro" />
                  <label htmlFor="Brasileiro">Brasileiro</label>
                  <br />
                  <input type="radio" name="nacionalidade" id="Estrangeiro" />
                  <label htmlFor="Estrangeiro">Estrangeiro</label>
                </div>
              </div>
              <div className={styles.estadoCivil}>
                <label htmlFor="estado-civis">Estado Civil</label>
                <br />
                <select
                  className={styles.estado_civil}
                  id="estados-civis"
                  required
                  value={estadoCivil}
                  onChange={(e) => setEstadoCivilCliente(e.target.value)}
                >
                  <option hidden value=""></option>
                  {estadoCivis?.map((estado) => (
                    <option value={estado[0]} key={estado[0]}>
                      {estado[1]}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.iletrado}>
                <input type="checkbox" name="" id="Iletrado" />
                <label htmlFor="Iletrado">
                  Iletrado /<br /> Impossibilitado
                </label>
              </div>
            </div>
          </div>

          <div
            className={styles.container_rg_orgaoEmissor_estadoRG_dataEmissao}
          >
            <div>
              <label htmlFor="RG">RG</label>
              <br />
              <input className={styles.RG} type="text" name="" id="RG" />
            </div>
            <div>
              <label htmlFor="">Orgão Emissor</label>
              <br />
              <select
                className={styles.input_orgao_emissor}
                value={orgaoEmissorRG}
                id="orgao-emissor"
                required
                onChange={(e) => setOrgaoEmissorRG(e.target.value)}
              >
                <option hidden value=""></option>
                {orgaos?.map((orgao) => (
                  <option value={orgao[0]} key={orgao[0]}>
                    {orgao[0]}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="">Estado do RG</label>
              <br />
              <select
                className={styles.estado_rg}
                id="estado-cliente"
                required
                onChange={(e) => setEstadoRG(e.target.value)}
              >
                <option hidden value=""></option>
                {estados?.map((estado) => (
                  <option value={estado[0]} key={rg + estado[0]}>
                    {estado[0]}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="DataEmissao">Data de Emissão</label>
              <br />
              <input
                className={styles.dataEmissao}
                type="date"
                name=""
                id="DataEmissao"
              />
            </div>
          </div>
          <div className={styles.mae_pai_valorPatrimonio}>
            <div>
              <label htmlFor="Mãe">Nome da Mãe</label>
              <br />
              <input className={styles.nome_mae} type="text" name="" id="Mãe" />
            </div>
            <div>
              <label htmlFor="Pai">Pai</label>
              <br />
              <input className={styles.nome_pai} type="text" name="" id="Pai" />
            </div>
            <div>
              <label htmlFor="">Valor do Patrimônio</label>
              <br />
              <select
                className={styles.valorPatrimonio}
                id={valores}
                required
                onChange={(e) => setValorPatrimonio(e.target.value)}
              >
                <option hidden value=""></option>
                {valores?.map((val) => (
                  <option value={val[0]} key={val[0]}>
                    {val[1]}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.estadoNatural_cidadeNatural}>
            <div>
              <label htmlFor="">Estado Natural</label>
              <br />
              <select
                className={styles.dropdown_estado_natural}
                required
                onChange={(e) => setEstadoNatural(e.target.value)}
              >
                <option hidden value=""></option>
                {estados?.map((estado) => (
                  <option value={estado[0]} key={estado[0]}>
                    {estado[1]}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="cidade-cliente">Cidade Natural</label>
              <br />
              <select
                className={styles.dropdown_cidade_natural}
                id="cidade-cliente"
                required
                onChange={(e) => setCidadeNatural(e.target.value)}
              >
                <option hidden value=""></option>
                {cidadesNATURAL?.map((cidadeN) => (
                  <option value={cidadeN[1][0]} key={cidadeN[1][0]}>
                    {cidadeN[1][1].nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.bene_estadoBene}>
            <div>
              <label htmlFor="">Benefício</label>
              <br />
              <select
                className={styles.beneficio}
                required
                onChange={(e) => setTipoBeneficio(e.target.value)}
              >
                <option hidden value="">
                  Benefícios
                </option>
                {tipos?.map((tipoBene) => (
                  <option
                    value={tipoBene[0]}
                    key={tipoBene[0]}
                    hover={tipoBene[1]}
                  >
                    {tipoBene[0] + " - " + tipoBene[1]}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="">Estado Benefício</label>
              <br />
              <select
                className={styles.estado_beneficio}
                required
                onChange={(e) => setEstadoBene(e.target.value)}
              >
                <option value="">Estado do Benefício</option>
                {estados?.map((estadoBenef) => (
                  <option value={estadoBenef[0]} key={estadoBenef[0]}>
                    {estadoBenef[1]}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Container Endereço do Cliente */}
          <span className={styles.titulo_endereço_pagbank}>
            Endereço do Cliente
          </span>
          {/* container de cima titutlo do endereço do cliente */}
          <div className={styles.endereco_cliente}>
            <div>
              <label htmlFor="CPE">CEP</label>
              <br />
              <input type="text" name="" id="CEP" />
            </div>
            <div>
              <label htmlFor="Endereço">Endereço</label>
              <br />
              <input type="text" name="" id="Endereço" />
            </div>
            <div>
              <label htmlFor="Bairro">Bairro</label>
              <br />
              <input type="text" name="" id="Bairro" />
            </div>
          </div>
          {/* estado e cidade do endereço do cliente */}
          <div className={styles.cidade_estado_endereco}>
            <div>
              <label htmlFor="Estado">Estado</label>
              <br />
              <select
                required
                value={estado}
                className={styles.inputs_residencia}
                onChange={(e) => setEstado(e.target.value)}
              >
                <option hidden value=""></option>
                {estados?.map((estado) => (
                  <option value={estado[0]} key={estado[0]}>
                    {estado[1]}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor=""></label>
              <br />
              <select
                className={styles.inputs_residencia}
                id="cidade-cliente"
                required
                onChange={(e) => setCidade(e.target.value)}
              >
                <option hidden value="">
                  Cidade
                </option>
                {cidades?.map((cidadeCli) => (
                  <option value={cidadeCli[1][0]} key={cidadeCli[1][0]}>
                    {cidadeCli[1][1].nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
