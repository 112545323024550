import React from "react";
import InputMask from "react-input-mask";
import styles from "../Pagbank.module.css";

const MaskedInput = ({ value, onChange }) => {
  return <InputMask 
    mask="999.999.999-99" 
    value={value} 
    onChange={onChange} 
    className={styles.inputs_consulta_pagbank}
    type="text"
    name=""
    id="input_cpf"
    />;
};

export default MaskedInput;
