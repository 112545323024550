import React, { useEffect, useState } from 'react';
import './style.css';
import { listarVendedores } from '../JS/consultVend';
import ModalComplemento from '../ModalComplemento'; // Importa o ModalComplemento
import ModalOut from '../ModalOut'; // Importa o ModalOut

const ModalVend = ({ isOpen, onClose, onConfirm, setResultado }) => {
  const [vendedores, setVendedores] = useState([]);
  const [selectedVendedor, setSelectedVendedor] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalComplementoOpen, setModalComplementoOpen] = useState(false);
  const [isModalOutOpen, setModalOutOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchVendedores = async () => {
      setLoading(true);
      setErrorMessage("");
      try {
        const data = await listarVendedores();
        setVendedores(data);
      } catch (error) {
        console.error("Erro ao carregar vendedores:", error);
        setErrorMessage("Erro ao carregar vendedores. Tente novamente.");
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchVendedores();
      setSelectedVendedor(""); // Limpa o vendedor selecionado ao abrir o modal
      setErrorMessage(""); // Limpa mensagens de erro ao abrir o modal
    }
  }, [isOpen]);

  const handleConfirm = () => {
    if (!selectedVendedor) {
      setErrorMessage("Selecione um vendedor antes de confirmar.");
      return;
    }
  
    setResultado((prevResultado) => {
      const novoResultado = {
        ...prevResultado,
        informacoesobrigatorias: {
          ...prevResultado.informacoesobrigatorias,
          vendedorId: selectedVendedor,
        },
      };
      console.log("Resultado após seleção do vendedor:", novoResultado);
      return novoResultado;
    });
  
    setModalComplementoOpen(true); // Abre o ModalComplemento ao confirmar o ModalVend
  };
  

  const handleComplementoConfirm = (complementoData) => {
    console.log("Complemento adicionado:", complementoData);
    setModalComplementoOpen(false); // Fecha o ModalComplemento
    setModalOutOpen(true); // Abre o ModalOut após o ModalComplemento ser confirmado
  };

  const handleOutConfirm = (outData) => {
    console.log("Informações do ModalOut:", outData);
    setModalOutOpen(false); // Fecha o ModalOut
    onConfirm(outData); // Continue a lógica ao confirmar, se necessário
    handleClose(); // Fecha o ModalVend após todos os processos
  };

  const handleClose = () => {
    setSelectedVendedor("");
    setErrorMessage("");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Seleção de Vendedor</h2>

        {loading ? (
          <div className="loading-spinner">
            <div className="spinner"></div>
            <p>Carregando vendedores...</p>
          </div>
        ) : (
          <div className="select-vendedor">
            <label htmlFor="vendedor-select">Escolha um Vendedor:</label>
            <select
              id="vendedor-select"
              className="custom-select"
              value={selectedVendedor}
              onChange={(e) => {
                setSelectedVendedor(e.target.value);
                setErrorMessage("");
              }}
              disabled={loading}
            >
              <option value="">Selecione...</option>
              {vendedores.map((vendedor) => (
                <option key={vendedor.Id} value={vendedor.Id}>
                  {vendedor.Nome}
                </option>
              ))}
            </select>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        )}

        <div className="modal-actions">
          <button className="btn-cancel" onClick={handleClose} disabled={loading}>
            Cancelar
          </button>
          <button
            className="btn-confirm"
            onClick={handleConfirm}
            disabled={loading || !selectedVendedor}
          >
            Confirmar
          </button>
        </div>

        {/* ModalComplemento */}
        <ModalComplemento
          isOpen={isModalComplementoOpen}
          onClose={() => setModalComplementoOpen(false)}
          onConfirm={handleComplementoConfirm}
          setResultado={setResultado}
        />

        {/* ModalOut */}
        <ModalOut
          isOpen={isModalOutOpen}
          onClose={() => setModalOutOpen(false)}
          onConfirm={handleOutConfirm}
          setResultado={setResultado}
        />
      </div>
    </div>
  );
};

export default ModalVend;

