import { axiosInstance } from "./axios.js";

export async function loginAPI(usr) {
  var data = JSON.stringify({
    user: usr.username,
    password: usr.password,
  });

  var config = {
    method: "post",
    url: "login",
    headers: {
      "Content-Type": "application/json",
      withCredentials: true,
    },
    data: data,
  };

  const result = await axiosInstance(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return result;
}

export async function registrarAPI(token, usr) {
  var data = JSON.stringify({
    "level": parseInt(usr.nivelUser),
    "user": usr.username,
    "name": usr.name,
    "email": usr.email,
    "password": usr.password,
    "confirmPassword": usr.confirmSenha,
    "equipe": usr.equipe
  });

  var config = {
    method: "post",
    url: "user",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axiosInstance(config)
    .then(function (response) {
      return JSON.parse(JSON.stringify(response));
    })
    .catch(function (error) {
      return error;
    });

  return result;
}

export async function listaUsuariosAPI(token) {
  var config = {
    method: "get",
    url: "user",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const result = await axiosInstance(config)
    .then(function (response) {
      var json = JSON.parse(JSON.stringify(response.data.users));
      return json;
    })
    .catch(function (error) {
      console.log(error);
    });

  return result;
}

export async function niveisGET(token) {
  var config = {
    method: "get",
    url: "agille/niveis",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const result = await axiosInstance(config)
    .then(function (response) {
      var list = JSON.parse(JSON.stringify(response.data.Niveis));
      return Object.entries(list[0]);
    })
    .catch(function (error) {
      console.log(error);
    });

  return result;
}

export async function infoUsuario(token, id) {
  var config = {
    method: "get",
    url: `user/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const result = await axiosInstance(config)
    .then(function (response) {
      var json = JSON.parse(JSON.stringify(response.data));
      return json;
    })
    .catch(function (error) {
      console.log(error);
    });

  return result;
}

export async function consultarCliente(token, cpf) {
  var config = {
    method: "get",
    url: `agilus/cliente/${cpf}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }
  };

  const result = await axiosInstance(config)
    .then(function (response) {
      var json = JSON.parse(JSON.stringify(response.data));
      return json;
    })
    .catch(function (error) {
      console.log(error);
      if(error.response.data.msg){
        return error.response.data.msg;
      }
      return null;
    });

  return result;
}

export async function equipesGET(token) {
  var config = {
    method: "get",
    url: "agille/equipes",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const result = await axiosInstance(config)
    .then(function (response) {
      return Object.entries(JSON.parse(JSON.stringify(response.data.equipes)));
    })
    .catch(function (error) {
      console.log(error);
    });

  return result;
}

export async function cadastrarPropostaDigitada(token, proposta) {
  var data = JSON.stringify({
    nome_analista: proposta[0],
    codigo_facta: proposta[1],
    link_facta: proposta[2],
    nome_cliente: proposta[3],
    cpf_cliente: proposta[4],
  });

  var config = {
    method: "post",
    url: "proposta",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  await axiosInstance(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data.msg));
      //return 1;
    })
    .catch(function (error) {
      console.error(error.response.data.error);
      //return 0;
    });
}

export async function extratoGET(cpf) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/extrato/${cpf}`,
  };

  const result = await axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if(error.response.data.msg){
        alert(error.response.data.msg);
      }
      return 0;
    });

  return result;
}

export async function deletarUsuario(token, id) {
  var config = {
    method: "delete",
    url: `user/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const deletado = await axiosInstance(config)
    .then(function (response) {
      return 1;
    })
    .catch(function (error) {
      console.log(error);
      return 0;
    });

  return deletado;
}

export async function cidadesCrefaz(token, sigla){
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `credfaz/cidade?estado=${sigla}`,
    headers: { 
      'Authorization': `Bearer ${token}`
    }
  };

  const result = await axiosInstance(config)
  .then(function (response) {
    return response.data
  })
  .catch(function (error) {
    console.error(error);
    return 0;
  });

  return result;
}

export async function propostaCredfaz(token, proposta){
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'credfaz/proposta',
    headers: { 
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data : proposta
  };

  const result = await axiosInstance.request(config)
  .then((response) => {
    console.log(response.data);
    return response.data;
  })
  .catch((error) => {
    console.log(error.response.data);
    //alert(error.response.data.msg);
  });

  return result;
}

export async function consultaSaque(token, ConvID = 161, cpf){
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `saque/master?ConvID=${ConvID}&cpf=${cpf}`,
    headers: { 
      'Authorization': `Bearer ${token}`,
    }
  };

  const result = await axiosInstance.request(config)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    if(error.response.data.msg){
      alert(error.response.data.msg);
    }
  });

  return result;
}