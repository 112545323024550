import React, { useState, useEffect, useRef } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { postDados } from "../JS/incluir";
import UseCodAgilus from "../../../../hooks/UseCodAgilus";
import SuccessModal from "../ModalSucess";
import dataAtual from "../../../scripts/dataAtual";
import "./style.css";
import { toast } from "react-toastify";
import axios from "axios";
import { Check, X } from "phosphor-react";

const bancos = require("../JS/bancos.json");

export default function ModalIncluirAF({ isOpen, onClose, data }) {
  const [ddd, setDdd] = useState("");
  const [tel, setTel] = useState("");
  const [salario, setSalario] = useState("");
  const [cep, setCep] = useState("");
  const [validCEP, setValidCEP] = useState(false);
  const [infoEndereco, setInfoEndereco] = useState({
    logradouro: "",
    bairro: "",
    complemento: "",
    localidade: "",
    uf: "",
  });
  const [codigoBancoObg, setCodigoBancoObg] = useState("");
  const [agencia, setAgencia] = useState("");
  const [conta, setConta] = useState("");
  const [tipoConta, setTipoConta] = useState("");
  const [orgaoExpedidor, setOrgaoExpedidor] = useState("");
  const [vendedor, setVendedor] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    if (data?.informacoesobrigatorias) {
      setModalData(data.informacoesobrigatorias);
      setCep(data.informacoesobrigatorias.cep);
      setSalario(data.informacoesobrigatorias.salario);
      if (!data.informacoesobrigatorias.orgaoExpedidor) {
        setOrgaoExpedidor("SSPSP");
      }
    }
  }, [data]);

  useEffect(() => {
    if (cep && cep.length === 8) {
      if (!/^\d+$/.test(cep)) {
        toast.error("CEP inválido. Por favor, tente novamente.", {
          timeOut: 3000,
        });
        setValidCEP(false);
      } else {
        axios(`https://viacep.com.br/ws/${cep}/json/`)
          .then((response) => {
            if (response.data.erro === "true") {
              throw new Error("CEP inválido.");
            }
            setInfoEndereco({
              logradouro: response.data.logradouro,
              bairro: response.data.bairro,
              complemento: response.data.complemento,
              localidade: response.data.localidade,
              uf: response.data.uf,
            });
            setValidCEP(true);
          })
          .catch((error) => {
            console.error(error.message);
            setValidCEP(false);
            toast.error("CEP inválido. Por favor, tente novamente.", {
              timeOut: 3000,
            });
          });
      }
    } else {
      setValidCEP(false);
    }
  }, [cep]);

  useEffect(() => {
    const codAgilus = UseCodAgilus();
    setVendedor(codAgilus);
  }, []);

  const handleCleanCEP = () => {
    setValidCEP(false);
    setCep("");
    setInfoEndereco({
      logradouro: "",
      bairro: "",
      complemento: "",
      localidade: "",
      uf: "",
    });
  };

  const handleInputChange = (setter) => (event) => {
    let { value } = event.target;
    if ([setCep, setConta, setAgencia].includes(setter)) {
      value = value.replace(/[^0-9]/g, "");
    } else if (setter === setTipoConta && value === "1") {
      setConta("");
    }
    setter(value);
  };

  const handleDddChange = (event) => {
    const value = event.target.value;
    if (value.length <= 2 && /^[0-9]*$/.test(value)) {
      setDdd(value);
    }
  };

  const handleTelChange = (event) => {
    const value = event.target.value;
    if (value.length <= 9 && /^[0-9]*$/.test(value)) {
      setTel(value);
    }
  };

  const validateFields = () => {
    if (!ddd || !tel || !salario || !vendedor) {
      toast.error("Todos os campos obrigatórios devem ser preenchidos.");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    isLoadingRef.current = true;
    setIsLoading(isLoadingRef.current);

    try {
      const resultados = [];

      if (!validateFields()) return;

      for (let index = 0; index < data.contratosSelecionados.length; index++) {
        const formData = {
          Nome: data.informacoesobrigatorias.nome,
          Cpf: data.informacoesobrigatorias.cpf,
          Matricula: data.informacoesobrigatorias.beneficio,
          TipoContrato: data.camposFixos.TipoContrato,
          TipoOperacao: data.camposFixos.TipoOperacao,
          DataContrato: await dataAtual(),
          ConvenioId: data.camposFixos.ConvenioId,
          BancoId: data.camposFixos.BancoIdFixo,
          TabelaId: data.contratosSelecionados[index].tabelaSelecionada,
          PromotoraId: data.camposFixos.PromotoraId,
          ValorBruto: data.informacoesobrigatorias.valorBruto,
          Prazo: data.contratosSelecionados[index].prazo,
          ValorParcela: data.contratosSelecionados[index].valorParcela,
          VendedorId: vendedor,
          DadosPortabilidade: {
            BancoId: data.contratosSelecionados[index].codigoBank,
            Saldo: data.contratosSelecionados[index].saldo,
            PrazoRestante: data.contratosSelecionados[index].prazoRestante,
            NumeroContrato: data.contratosSelecionados[index].numeroContrato,
            ValorParcela: data.contratosSelecionados[index].valorParcela,
          },
          DataNascimento: data.informacoesobrigatorias.dtNascimento,
          Especie: data.informacoesobrigatorias.especie,
          Salario: salario,
          NomeMae: data.informacoesobrigatorias.mae,
          TipoDocumento: data.camposFixos.TipoDocumento,
          NumeroDocumento: data.informacoesobrigatorias.cpf,
          OrgaoExpedidor: data.informacoesobrigatorias.estado,
          DataExpedicao: await dataAtual(),
          Naturalidade: data.camposFixos.Naturalidade,
          EstadoCivil: data.camposFixos.EstadoCivil,
          Sexo: data.informacoesobrigatorias.sexo,
          UfSolicitacaoBeneficio: data.informacoesobrigatorias.estado,
          DadosBancarios: {
            TipoConta: data.informacoesobrigatorias.tipoConta,
            BancoId: data.informacoesobrigatorias.codigoBancoObg,
            Agencia: data.informacoesobrigatorias.agencia,
            NumeroConta: data.informacoesobrigatorias.conta,
          },
          Endereco: {
            Rua: data.informacoesobrigatorias.endereco,
            Numero: "",
            Complemento: "",
            Bairro: data.informacoesobrigatorias.bairro,
            Cidade: data.informacoesobrigatorias.cidade,
            Uf: data.informacoesobrigatorias.estado,
            Cep: data.informacoesobrigatorias.cep,
          },
          Telefone: {
            Ddd: ddd,
            Numero: tel,
          },
        };

        if (infoEndereco.logradouro) {
          formData.Endereco.Rua = infoEndereco.logradouro;
          formData.Endereco.Complemento = infoEndereco.complemento;
          formData.Endereco.Bairro = infoEndereco.bairro;
          formData.Endereco.Cidade = infoEndereco.localidade;
          formData.Endereco.Uf = infoEndereco.uf;
          formData.Endereco.Cep = cep;
        }

        if (agencia && codigoBancoObg && tipoConta) {
          formData.DadosBancarios.Agencia = agencia;
          formData.DadosBancarios.NumeroConta = conta;
          formData.DadosBancarios.BancoId = parseInt(codigoBancoObg);
          formData.DadosBancarios.TipoConta = parseInt(tipoConta);
        }

        if (orgaoExpedidor) {
          formData.OrgaoExpedidor = orgaoExpedidor;
        }

        try {
          const response = await postDados(formData);
          console.log("Dados salvos com sucesso:", response);
          resultados.push({ index, success: true, response });
        } catch (error) {
          console.error("Erro ao salvar os dados:", error);
          resultados.push({ index, success: false, error });
        }
      }

      console.log("Resultados das solicitações:", resultados);

      resultados.forEach((result) => {
        if (result.success) {
          setSuccessMessage(
            `Proposta criada com sucesso: AF ${result.response.Id}, Detalhes: ${result.response.Mensagem}`
          );
          setIsSuccessModalOpen(true);
        } else {
          toast.error(
            `Erro ao salvar o contrato ${result.index + 1}: ${
              result.error.response.data.erro
            }`
          );
        }
      });
    } catch (error) {
      toast.error(
        "Erro ao salvar os dados. Por favor, tente novamente mais tarde."
      );
      console.error("Erro ao salvar os dados:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog.Root
        open={isOpen}
        onOpenChange={(isOpen) => !isOpen && onClose()}
      >
        <Dialog.Trigger asChild>
          <button></button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Dialog.Title className="DialogTitle">
              Dados Obrigatórios
            </Dialog.Title>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="ddd">
                DDD
              </label>
              <input
                className="Input"
                type="text"
                value={ddd}
                onChange={handleDddChange}
                placeholder="DDD"
                pattern="\d{0,2}"
                maxLength="2"
              />
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="telefone">
                Telefone
              </label>
              <input
                className="Input"
                type="text"
                value={tel}
                onChange={handleTelChange}
                placeholder="Telefone"
                pattern="\d{0,9}"
                maxLength="9"
              />
            </fieldset>
            {!modalData?.salario ? (
              <fieldset className="Fieldset">
                <label className="Label" htmlFor="salario">
                  Salário
                </label>
                <input
                  className="Input"
                  type="text"
                  value={salario}
                  onChange={handleInputChange(setSalario)}
                  placeholder="Salário"
                />
              </fieldset>
            ) : (
              <></>
            )}
            {(!modalData?.cep || modalData?.cep.length < 8) && (
              <>
                <fieldset className="Fieldset">
                  <label className="Label" htmlFor="cep">
                    CEP
                  </label>
                  <input
                    className="Input"
                    type="text"
                    value={cep}
                    onChange={handleInputChange(setCep)}
                    placeholder="CEP"
                    pattern="\d{0,8}"
                    maxLength="8"
                    disabled={cep && cep.length === 8}
                  />
                  {validCEP ? (
                    <Check size={32} weight="bold" color="green" />
                  ) : (
                    <X size={32} weight="bold" color="red" />
                  )}
                  <button className="Button" onClick={handleCleanCEP}>
                    Limpar
                  </button>
                </fieldset>
              </>
            )}
            {!modalData?.orgaoExpedidor && (
              <fieldset className="Fieldset">
                <label className="Label" htmlFor="orgaoExpedidor">
                  Orgão
                </label>
                <input
                  className="Input"
                  type="text"
                  value={orgaoExpedidor}
                  onChange={handleInputChange(setOrgaoExpedidor)}
                  placeholder="Orgão Expedidor"
                />
              </fieldset>
            )}
            {(!modalData?.agencia ||
              !modalData?.conta ||
              !modalData?.codigoBancoObg) && (
              <>
                <fieldset className="Fieldset">
                  <label className="Label" htmlFor="tipoConta">
                    Tipo de Conta
                  </label>
                  <select
                    className="Input"
                    value={tipoConta}
                    onChange={handleInputChange(setTipoConta)}
                  >
                    <option value="">Selecione o tipo de conta</option>
                    <option value="0">Conta Corrente</option>
                    <option value="1">Cartão Magnético</option>
                  </select>
                </fieldset>
                <fieldset className="Fieldset">
                  <label className="Label" htmlFor="banco">
                    Banco
                  </label>
                  <select
                    className="Input"
                    value={codigoBancoObg}
                    onChange={handleInputChange(setCodigoBancoObg)}
                    disabled={!tipoConta}
                  >
                    <option value="">Selecione o Banco</option>
                    {bancos
                      .sort((a, b) => a.CodigoBanco - b.CodigoBanco)
                      .map((banco) => (
                        <option key={banco.Id} value={banco.Id}>
                          {banco.CodigoBanco} -{" "}
                          {banco.Nome.replace(" S.A.", "").toUpperCase()}
                        </option>
                      ))}
                  </select>
                </fieldset>
                <fieldset className="Fieldset">
                  <label className="Label" htmlFor="agencia">
                    Agência
                  </label>
                  <input
                    className="Input"
                    type="text"
                    value={agencia}
                    onChange={handleInputChange(setAgencia)}
                    placeholder="Agência"
                    disabled={!tipoConta}
                  />
                </fieldset>
                <fieldset className="Fieldset">
                  <label className="Label" htmlFor="conta">
                    Conta
                  </label>
                  <input
                    className="Input"
                    type="text"
                    value={conta}
                    onChange={handleInputChange(setConta)}
                    placeholder="Conta"
                    disabled={tipoConta === "1" || !tipoConta}
                  />
                </fieldset>
              </>
            )}
            <div className="modal-actions">
              <button
                className="Button green"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? "Salvando..." : "Salvar"}
              </button>
              <SuccessModal
                isOpen={isSuccessModalOpen}
                onClose={() => setIsSuccessModalOpen(false)}
                message={successMessage}
              />
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <Cross2Icon />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}
