import styled from 'styled-components'

export const Button = styled.button`
  cursor: pointer;
  text-transform: uppercase;
  padding: 8px 40px;
  border-radius: 10px;
  border: 0;
  background-color: #3761d4;
  color: #fff;
  margin-top: 1rem;
  transition: 0.3s ease-in-out;
  margin-right: 30px;
  outline: none;
  &:hover {
    background-color: #0044ff;
    transition: 0.3s ease-in-out;
  }
`
export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
`
