import React from "react";

import "./styles.css";
import Cadastro from '../Registro/Registrar';


export default function Modal({id='modal', onClose= () => {}, children }) {
  const fecharModal = (e) => {
    if(e.target.id === id) onClose();
  };

  return (
    <>
     <div id={id} className='modal' onClick={fecharModal}>
      <div className='container'>
         <button className='close'onClick={onClose}/>
         <div className="content">{children}</div>
            <Cadastro />
      </div>
    </div>
    </>
  );
}


