// JS/fetchTabelas.js

import { agilusAPI } from "../../../../api/axios";

export const fetchBancoPort = async () => {
  const url =
    "Banco/ListarBancosParaSelect";

  try {
    const response = await agilusAPI.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Houve um erro ao buscar as tabelas:", error);
  }
};
