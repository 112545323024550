import React, { useState, useEffect, useRef } from "react";
import styles from "../../Facta/Facta.module.css";
import { consultaOperacoesGET } from "../../../../../api/api-facta";
import { reformatDate } from "../js/ConvertDataNasc";
import UseToken from "../../../../../hooks/UseToken";
import { ContainerTable, TH, ThFinal, TD, TR, TdFinal } from "./ContainerTable";
import { ContainerButton, Button } from "../ContainerButton";

export default function Table(setTab) {
  const [errMsg, setErrMsg] = useState("");
  const errRef = useRef();
  const [tabelas, setTabelas] = useState([]);

  function handleConsulta() {
    setErrMsg("");
    const fetchTabelas = async () => {
      const tkn = UseToken();
      return await consultaOperacoesGET(
        tkn,
        setTab.produto,
        setTab.opcao_valor,
        setTab.valor,
        setTab.cpf,
        reformatDate(setTab.dataNasc),
        setTab.prazo,
        setTab.tipo_operacao,
        setTab.renda,
        setErrMsg
      );
    };

    fetchTabelas()
      .then((result) => {
        if (result) {
          setTabelas(result);
        }else{
          setTabelas([]);
        }
      })
      .catch(console.error);
  }

  return (
    <>
      <ContainerTable>
        <table className={styles.tabela}>
          <thead>
            <tr>
              <TH>Tabela</TH>
              <TH>Tx.Juros</TH>
              <TH>Prazo</TH>
              <TH>Parcela</TH>
              <TH>Coef.</TH>
              <ThFinal>Vlr.Cliente</ThFinal>
            </tr>
          </thead>
          <tbody>
            {tabelas?.map((tabela) => (
              <tr key={tabela.codigoTabela}>
                <div className={styles.label_table}>
                  <label htmlFor={tabela.codigoTabela}>
                    <input
                      className={styles.input_table}
                      type="radio"
                      name="tabela"
                      id={tabela.codigoTabela}
                      onClick={(e) =>
                        setTab.setTab([
                          e.target.dataset.nometabela,
                          e.target.dataset.codigotabela,
                          e.target.dataset.taxa,
                          e.target.dataset.parcela,
                          e.target.dataset.prazo,
                          e.target.dataset.vlr_cliente,
                          e.target.dataset.coef,
                        ])
                      }
                      data-codigoTabela={tabela.codigoTabela}
                      data-taxa={tabela.taxa}
                      data-parcela={tabela.parcela}
                      data-prazo={tabela.prazo}
                      data-vlr_cliente={tabela.contrato}
                      data-coef={tabela.coeficiente}
                      data-nomeTabela={tabela.tabela}
                    />
                    {tabela.tabela}
                  </label>
                </div>
                <TD>{tabela.taxa}</TD>
                <TD>{tabela.prazo}</TD>
                <TD>{tabela.parcela}</TD>
                <TD>{tabela.coeficiente}</TD>
                <TdFinal>{tabela.contrato}</TdFinal>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTable>
      <p ref={errRef} className={styles.msgerro} aria-live="assertive">
        {errMsg}
      </p>
      <ContainerButton>
        <Button onClick={handleConsulta}>Consultar</Button>
      </ContainerButton>
    </>
  );
}
