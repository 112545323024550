import React, { useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import "./styles.css";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import "./styles.css";
import { Link } from "react-router-dom";

const Background = styled.div`
  top: 300px;
  right: 0px;
  width: 60%;
  height: 60%;
  background: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ModalWrapper = styled.div`
  width: 400px;
  height: 100%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #262626;
  color: #fff;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  border: 0;
  bottom: 150px;
  border-top: 3px solid #f25c05;

  @media screen and (max-width: 768px) {
    width: 320px;
    top: 37px;
    right: 64px;
  }
`;

const ModalContent = styled.div`
  padding: 1.5em;

  p {
    margin-bottom: 1rem;
  }
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: 30px;
  width: 32px;
  height: 32px;
  padding: 0;

  @media screen and (max-width: 768px) {
    right: 20px;
  }
`;

export default function ModalConfig({ showModalConfig, setShowModalConfig }) {
  const animation = useSpring({
    config: {
      duration: 300,
    },
    opacity: showModalConfig ? 1 : 0,
    transform: showModalConfig ? `translateY(0%)` : `translateY(-100%)`,
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModalConfig) {
        setShowModalConfig(false);
      }
    },
    [setShowModalConfig, showModalConfig]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return (
    <>
      {showModalConfig ? (
        <Background>
          <animated.div style={animation}>
            <ModalWrapper showModalConfig={showModalConfig}>
              <ModalContent>
                <div className="config_all">
                  <div className="container_config">
                    <Link className="link" to="/post">
                      Criar Postagem
                    </Link>
                  </div>
                  <div className="container_config">
                    <Link className="link" to="/frase">
                      Atualizar Frase
                    </Link>
                  </div>
                  <div className="container_config">
                    <Link className="link" to="/usuarios">
                      Atualizar Usuários
                    </Link>
                  </div>
                </div>
              </ModalContent>
              <CloseModalButton
                arial-label="Close modal"
                onClick={() => setShowModalConfig((prev) => !prev)}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
