import "./style.css";
import React, { useState, useEffect } from "react";
import { fetchBancos } from "../JS/fetchBancos";
import { fetchTabelas } from "../JS/fetchTabelas";
import { simulateLoan } from '../JS/simulateLoan.js';
import ModalIncluirAF from "../ModalIncluirAF";
import ModalManual from "../ModalManual";
import ModalSimulation from "../ModalSimulation/"; // Certifique-se de ajustar o caminho conforme necessário
import ModalDoc from "../ModalDoc/";
import ModalVend from "../ModalComplemento/";
import ModalComplemento from "../ModalComplemento/";
import tiposDeConta from "../Dados Estaticos/tiposConta.js";
import mapeamentoSexo from "../Dados Estaticos/sexosID.js";
import camposFixos from "../Dados Estaticos/camposFixos.js";
const bancos = require("../JS/bancos.json");

const obterValorSexo = (sexo) => {
  return mapeamentoSexo.hasOwnProperty(sexo) ? mapeamentoSexo[sexo] : null;
};

const obterInformacoesObrigatorias = (
  dados,
  valorSexo,
  codigoBancoObg,
  tipoConta
) => {
  return {
    nome: dados.name,
    cpf: dados.documentNumber,
    beneficio: dados.benefitNumber,
    especie: dados.typeCode,
    mae: dados.motherName,
    sexo: valorSexo,
    dtNascimento: new Date(dados.birthDate),
    endereco: dados.address.address,
    bairro: dados.address.district,
    cidade: dados.address.city,
    estado: dados.address.state,
    cep: dados.address.zipCode,
    agencia: dados.bankAccount.agency,
    conta: dados.bankAccount.number,
    codigoBancoObg,
    tipoConta,
    nomeBancoConta: dados.bankAccount.bankName,
    estadoBeneficio: dados.address.state,
    valorBruto: dados.summary.benefitAmount,
    salario: dados.summary.baseValue,
  };
};

const filtrarContratos = (contrato, filtroBanco, filtroSituacao) => {
  if (
    filtroBanco &&
    contrato.bankName.toLowerCase().indexOf(filtroBanco.toLowerCase()) === -1
  ) {
    return false;
  }
  if (
    filtroSituacao &&
    contrato.status.toLowerCase().indexOf(filtroSituacao.toLowerCase()) === -1
  ) {
    return false;
  }
  return true;
};

const ordenarContratos = (contratos, ordenacao) => {
  switch (ordenacao) {
    case "parcela":
      return [...contratos].sort(
        (a, b) => a.installmentValue - b.installmentValue
      );
    case "saldo":
      return [...contratos].sort((a, b) => a.loanValue - b.loanValue);
    case "taxa":
      return [...contratos].sort((a, b) => a.rate - b.rate);
    default:
      return contratos;
  }
};

export default function TablePromoSys({ dados }) {
  const [tabelas, setTabelas] = useState([]);
  const [filtroBanco, setFiltroBanco] = useState("");
  const [filtroSituacao, setFiltroSituacao] = useState("");
  const [ordenacao, setOrdenacao] = useState(null);
  const [checkedItems, setCheckedItems] = useState(new Map());
  const [mapaBancos, setMapaBancos] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContrato, setSelectedContrato] = useState(null);
  const [isModalManualOpen, setIsModalManualOpen] = useState(false);
  const [isModalSimulationOpen, setModalSimulationOpen] = useState(false); // Moved inside the component
  const [documentoInfo, setDocumentoInfo] = useState(null);
  const [isModalDocOpen, setIsModalDocOpen] = useState(false);
  const [resultado, setResultado] = useState({
    informacoesobrigatorias: {},
    contratosSelecionados: [], // Iniciando com array vazio
    camposFixos: {},
  });
  const [tabelasSelecionadas, setTabelasSelecionadas] = useState(new Map());
  const [isModalComplementoOpen, setIsModalComplementoOpen] = useState(false);
  
  

  const handleOpenModalDoc = (info) => {
    setDocumentoInfo(info);
    setIsModalDocOpen(true);
  };

  const handleConfirmSimulation = async (resultadoAtualizado) => {
    const contratos = resultadoAtualizado.contratosSelecionados;
    if (!contratos || contratos.length === 0) {
      console.error("Nenhum contrato selecionado.");
      return;
    }
  
    const contratoSelecionado = contratos[0]; // Sempre acessando o primeiro item do array
  
    if (!contratoSelecionado) {
      console.error("Contrato selecionado está indefinido.");
      return;
    }
  
    const tabelaSelecionada = tabelasSelecionadas.get(contratoSelecionado.numeroContrato); // Obtém o objeto com productCode e idAgille
  
    if (!tabelaSelecionada) {
      console.error("Nenhuma tabela selecionada.");
      alert("Por favor, selecione uma tabela antes de confirmar.");
      return;
    }
  
    // Atualizar o loanData com os dados do contratoSelecionado e da tabela (productCode)
    const loanData = {
      productCode: tabelaSelecionada.productCode, // Usa o productCode correto da tabela selecionada
      originalContract: {
        lenderCode: contratoSelecionado.bankCode || "", // Código do banco
        number: contratoSelecionado.numeroContrato || "", // Número do contrato
        installmentValue: contratoSelecionado.valorParcela || 0, // Valor da parcela
        installmentsRemaining: contratoSelecionado.prazoRestante || 0, // Prazo restante
        loanBalanceValue: contratoSelecionado.saldo || 0, // Saldo do empréstimo
      },
    };
  
    console.log("Enviando loanData para simulação:", loanData);

    try {
      const response = await simulateLoan(loanData);
      console.log("Resposta da simulação:", response);
    
      // Não precisamos mais comparar os valores, apenas seguimos com o fluxo normal
      console.log("Simulação realizada com sucesso. Prosseguindo...");
    
      // Fecha o ModalSimulation e abre o ModalDoc
      setModalSimulationOpen(false); // Fecha o ModalSimulation
      setIsModalDocOpen(true); // Abre o ModalDoc
    } catch (error) {
      console.error("Erro ao simular o empréstimo:", error);
      alert("Erro ao simular o empréstimo. Por favor, tente novamente.");
    }
    

    // simulando corretamente
    // try {
    //   const response = await simulateLoan(loanData);
    //   console.log("Resposta da simulação:", response);
  
    //   const simulationInstallmentValue = response.installmentValue;
    //   const originalInstallmentValue = response.originalContract.installmentValue;
  
    //   if (simulationInstallmentValue <= originalInstallmentValue) {
    //     console.log("Simulação válida. Prosseguindo...");
    //     setModalSimulationOpen(false); // Fecha o ModalSimulation
    //     setIsModalDocOpen(true); // Abre o ModalDoc
    //   } else {
    //     console.error("O valor da simulação é maior que o valor original.");
    //     alert("O valor da simulação é maior que o valor original. Por favor, revise os valores.");
    //   }
    // } catch (error) {
    //   console.error("Erro ao simular o empréstimo:", error);
    // }
  };
  
  
  const handleSelectTabela = (numeroContrato, tabelaSelecionada) => {
    setTabelasSelecionadas((prevTabelas) => {
      const newTabelas = new Map(prevTabelas);
      newTabelas.set(numeroContrato, {
        productCode: tabelaSelecionada.productCode,
        idAgille: tabelaSelecionada.idAgille,
      });
      return newTabelas;
    });
  };

  const handleComplementoConfirm = (complementoData) => {
    setResultado((prevResultado) => ({
      ...prevResultado,
      informacoesobrigatorias: {
        ...prevResultado.informacoesobrigatorias,
        ddd: complementoData.ddd,
        telefone: complementoData.telefone,
      },
    }));
    setIsModalComplementoOpen(false);
  };

  const handleCloseModalManual = () => setIsModalManualOpen(false);
  const handleOpenModalComplemento = () => {
    setIsModalComplementoOpen(true); // Abre o ModalComplemento
  };

  const handleConfirmVendedor = (vendedorData) => {
    // Salva o vendedor e, depois, abre o ModalComplemento
    setResultado((prevResultado) => ({
      ...prevResultado,
      informacoesobrigatorias: {
        ...prevResultado.informacoesobrigatorias,
        vendedorId: vendedorData.vendedorId,
      },
    }));

    handleOpenModalComplemento(); // Abre o modal de complemento após salvar o vendedor
  };

  useEffect(() => {
    const buscarBancosETabelas = async () => {
      const [bancosData, tabelasData] = await Promise.all([
        fetchBancos(),
        fetchTabelas(),
      ]);
      const mapa = bancosData.reduce((acc, banco) => {
        acc[banco.CodigoBanco] = banco.Id;
        return acc;
      }, {});
      setMapaBancos(mapa);
      setTabelas(tabelasData);
    };
    console.log(resultado)

    buscarBancosETabelas();
  }, [resultado]);

  const handleCheckboxChange = (contrato) => {
    setCheckedItems((prevItems) => {
      const updatedItems = new Map(prevItems);
      const isChecked = updatedItems.get(contrato) || false; // contrato.number
      updatedItems.set(contrato, !isChecked); // Alterna o estado contrato.number

      if (!isChecked) {
        setSelectedContrato(contrato); // Armazena o contrato no estado
        setModalSimulationOpen(true); // Abre o modal de simulação
      }

      return updatedItems;
    });
  };

  const handleSalvarSelecionados = async () => {
    const valorSexo = obterValorSexo(dados.sex);
    let bancoObrigatorio = false;
  
    if (dados.bankAccount?.bankCode) {
      bancoObrigatorio = bancos.find(
        (banco) => banco.CodigoBanco === dados.bankAccount.bankCode
      );
    }
  
    const codigoBancoObg = bancoObrigatorio ? bancoObrigatorio.Id : null;
    const tipoConta =
      tiposDeConta[dados.paymentMethodName] !== undefined
        ? tiposDeConta[dados.paymentMethodName]
        : 0;
  
    const informacoesobrigatorias = obterInformacoesObrigatorias(
      dados,
      valorSexo,
      codigoBancoObg,
      tipoConta
    );
  
    console.log("Checked Items:", Array.from(checkedItems));
    console.log("Tabelas Selecionadas:", tabelasSelecionadas);
  
    const contratosSelecionados = Array.from(checkedItems)
      .filter(([key, value]) => value)
      .map(([key]) => {
        const contrato = dados.loanContracts.find((c) => c.number === key);
  
        if (!contrato) {
          console.error(`Contrato não encontrado para o número ${key}`);
          return null;
        }
  
        console.log("Contrato encontrado:", contrato);
  
        const bancoContrato = bancos.find(
          (banco) => banco.CodigoBanco === contrato.bankCode
        );
        const codigoBank = bancoContrato ? bancoContrato.Id : null;
        const tabelaSelecionada = tabelasSelecionadas.get(contrato.number);
  
        if (!tabelaSelecionada) {
          console.warn(
            `Tabela não selecionada para o contrato ${contrato.number}`
          );
        }
  
        return {
          numeroContrato: contrato.number,
          bancoContrato: contrato.bankName,
          dataContrato: dados.beginDate,
          orgaoExpedidor: dados.paymentState,
          valorParcela: contrato.installmentValue.toFixed(2),
          saldo: contrato.loanValue.toFixed(2),
          prazoRestante: contrato.remainingInstallments,
          prazo: contrato.installments,
          taxa: contrato.rate.toFixed(2) + "%",
          codigoBank,
          bankCode: contrato.bankCode,
          tabelaSelecionada, // Adicione a tabela selecionada ao contrato
        };
      })
      .filter(Boolean); // Remove null values if contrato is not found
  
    const novoResultado = {
      informacoesobrigatorias,
      contratosSelecionados, // Isso vai garantir que seja sempre um array
      camposFixos,
    };
  
    console.log("Novo resultado:", novoResultado);
    setResultado(novoResultado);
  
    // Retornar o resultado atualizado para usar diretamente em handleConfirmSimulation
    return novoResultado;
  };
  
  

  const handleSalvarManual = () => {
    setIsModalManualOpen(true);

    const valorSexo = obterValorSexo(dados.sex);
    const bancoObrigatorio = bancos.find(
      (banco) => banco.CodigoBanco === dados.bankAccount.bankCode
    );
    const codigoBancoObg = bancoObrigatorio ? bancoObrigatorio.Id : null;
    const tipoConta =
      tiposDeConta[dados.paymentMethodName] !== undefined
        ? tiposDeConta[dados.paymentMethodName]
        : 0;

    const informacoesobrigatorias = obterInformacoesObrigatorias(
      dados,
      valorSexo,
      codigoBancoObg,
      tipoConta
    );

    const novoResultado = {
      informacoesobrigatorias,
      camposFixos,
    };

    setResultado(novoResultado);
    console.log(novoResultado);
  };

  const contratosFiltrados = dados.loanContracts.filter((contrato) =>
    filtrarContratos(contrato, filtroBanco, filtroSituacao)
  );
  const contratosOrdenados = ordenarContratos(contratosFiltrados, ordenacao);

  return (
    <>
      <table className="table-promosys">
        <thead className="thead-promosys">
          <tr className="tr-promosys">
            <th className="th-promosys">Contrato</th>
            <th className="th-promosys">Banco</th>
            <th className="th-promosys">Valor Parcela</th>
            <th className="th-promosys">Saldo</th>
            <th className="th-promosys">Prazo</th>
            <th className="th-promosys">Taxa</th>
            <th className="th-promosys">Simulação</th>
          </tr>
        </thead>
        <tbody className="tbody-promosys">
          {contratosOrdenados.map((contrato, index) => (
            <tr key={index}>
              <td className="td-promosys">{contrato.number}</td>

              <td className="td-promosys">{contrato.bankName}</td>

              <td className="td-promosys">
                R$ {contrato.installmentValue.toFixed(2)}
              </td>
              <td className="td-promosys">
                R$ {contrato.loanValue.toFixed(2)}
              </td>
              <td className="td-promosys">
                {contrato.remainingInstallments}/{contrato.installments}
              </td>
              <td className="td-promosys">{contrato.rate.toFixed(2)}%</td>
              <td className="td-promosys">
                <button
                  className={`button-toggle ${
                    checkedItems.get(contrato.number) ? "active" : ""
                  }`}
                  onClick={() => handleCheckboxChange(contrato.number)}
                >
                  {checkedItems.get(contrato.number) ? "Cancelar" : "Simular"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="filters">
        {/* <button onClick={handleSalvarManual} className="Button violet">
          Manual
        </button> */}
      </div>

      <ModalIncluirAF
        data={resultado}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onModalSubmit={() => setIsModalOpen(false)}
      />
      <ModalManual
        data={resultado}
        isOpen={isModalManualOpen}
        onClose={handleCloseModalManual}
      />

      {selectedContrato && (
        <ModalSimulation
          data={resultado}
          isOpen={isModalSimulationOpen}
          onClose={() => setModalSimulationOpen(false)}
          onConfirm={handleConfirmSimulation} // Chama a função que abre o ModalDoc
          handleSalvarSelecionado={handleSalvarSelecionados}
          contratoNumber={selectedContrato}
          tabelas={tabelas}
          handleSelectTabela={handleSelectTabela}
        />
      )}

      <ModalDoc
        data={resultado}
        isOpen={isModalDocOpen}
        onClose={() => setIsModalDocOpen(false)}
        onConfirm={() => setIsModalDocOpen(false)} // Fecha o ModalDoc ao confirmar
        documentoInfo={documentoInfo}
        setResultado={setResultado}
      />

      {/* ModalVend */}
      {isModalOpen && (
        <ModalVend
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleSalvarSelecionados}
          setResultado={setResultado}
          resultado={resultado} // Passa o resultado
        />
      )}

      {isModalComplementoOpen && (
        <ModalComplemento
          isOpen={isModalComplementoOpen}
          onClose={() => setIsModalComplementoOpen(false)}
          onConfirm={handleComplementoConfirm}
          setResultado={setResultado} // Verifique que esta linha está correta
          resultado={resultado}
        />
      )}
    </>
  );
}
