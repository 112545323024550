import React from "react";
import InputMask from "react-input-mask";

const MaskedInput = ({ value, onChange, disabled, mask, name, id, placeholder, className, pattern }) => {
  return (
    <InputMask
      className={className}
      mask={mask}
      value={value}
      onChange={onChange}
      type="text"
      name={name}
      id={id}
      placeholder={placeholder}
      disabled={disabled}
      pattern={pattern}
      required
    />
  );
};

export default MaskedInput;
