// Create service client module using ES6 syntax.
import { S3Client } from "@aws-sdk/client-s3";
// Set the AWS Region.
const REGION = "sa-east-1";
// Create an Amazon S3 service client object.
const s3Client = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: "AKIASE5CLM74Y3GWQNGY",
    secretAccessKey: "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",
  },
});
export { s3Client };
