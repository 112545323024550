import React from 'react'
import './style.css'
// import logoAgille from '../../../img/Logo.Agille.png'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

export default function NaoEncontrado() {
  useEffect(() => {
    document.title = 'Página não encontrada'
  }, [])

  return (
    <>
      <div id="error-page">
        <div className="content">
          <h2 className="header" data-text="404">
            404
          </h2>
          <h4 data-text="Opps! Página não Encontrada">Opps! Página não Encontrada</h4>
          <p>
            Desculpe, a página que você está procurando não existe. Se você acha
            que algo não está funcionando, reporte a um superior.
          </p>
          <div className="btns">
            <Link to="/home">Voltar para Home</Link>
          </div>
        </div>
      </div>
    </>
  )
}
