// JS/fetchBancos.js

import { agilusAPI } from "../../../../api/axios";

export const fetchBancos = async () => {
  try {
    const url = "Banco/ListarBancosParaSelect";
    const response = await agilusAPI.get(url);
    return response.data;
  } catch (error) {
    console.error("Houve um erro ao buscar as tabelas:", error);
  }
};
