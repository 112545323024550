import React, { useState, useEffect, useRef } from "react";
import "./styles.css";
import { equipesGET, niveisGET, registrarAPI } from "../../../api/api-portal";
import UseToken from "../../../hooks/UseToken";

export default function Cadastro() {
  // api
  const [niveis, setNiveis] = useState([]);
  const [equipes, setEquipes] = useState([]);

  // ref
  const [errMsg, setErrMsg] = useState("");
  const errRef = useRef();
  const userRef = useRef();

  // novo usuário
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmSenha, setCSenha] = useState("");
  const [nivelUser, setNivelUser] = useState("");
  const [equipe, setEquipe] = useState("");

  useEffect(() => {
    userRef.current.focus();
    const fetchData = async () => {
      const niveis = await niveisGET(UseToken());
      const equipes = await equipesGET(UseToken());
      if (niveis) {
        setNiveis(niveis);
      }
      if (equipes) {
        setEquipes(equipes);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [username, password, email, confirmSenha, name, nivelUser, equipe]);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await registrarAPI(
        UseToken(),
        {name, username, email, nivelUser, password, confirmSenha, equipe}
      );

      if (result.status === 201) {
        setCSenha("");
        setEmail("");
        setName("");
        setUsername("");
        setPassword("");
        setNivelUser("");
        setEquipe("");
        setTimeout(() => {
          setErrMsg(result.data.msg);
        }, 1000);
      } else {
        setErrMsg(result.response.data.msg);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <section className="registro_all">
        <form className="formulario" onSubmit={handleOnSubmit}>
          <span className="titulo_register">Criar Usuário</span>
          <div className="formulario_container">
            <div className="nome">
              <input
                type="text"
                name="nome"
                required
                placeholder="Insira o Nome"
                ref={userRef}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="usuario">
              <input
                type="text"
                name="usuario"
                required
                placeholder="Insira o Usuário"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="email">
              <input
                type="email"
                name="email"
                required
                placeholder="Insira o E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                title="Insira o e-mail corporativo"
              />
            </div>
            <div>
              <span className="tipo-operador">Tipo de Operador</span>
              <select id="tipo-operador" required defaultValue={"DEFAULT"} value={nivelUser}
                onChange={(e) =>
                  setNivelUser(e.target.value)
                }
              >
                <option value=""></option>
                {niveis?.map((nivel) => (
                  <option value={nivel[0]} key={nivel[0]}>
                    {nivel[0]} - {nivel[1]}
                  </option>
                ))}
              </select>
              <span className="tipo-operador">Equipe</span>
              <select id="equipe"
                onChange={(e) =>
                  setEquipe(e.target.value)
                }
              >
                <option value=""></option>
                {equipes?.map((equipe) => (
                  <option value={equipe[0]} key={equipe[0]}>
                    {equipe[0]} - {equipe[1]}
                  </option>
                ))}
              </select>
            </div>
            <div className="senha">
              <input
                type="password"
                name="senha"
                placeholder="Insira sua Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="confirm-senha">
              <input
                type="password"
                name="confirmSenha"
                placeholder="Confirme sua Senha"
                value={confirmSenha}
                onChange={(e) => setCSenha(e.target.value)}
                required
              />
            </div>
            <p
              ref={errRef}
              className="errmsg"
              id="errmsg"
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <div className="cadastrar">
              <button className="btn_cadastro" type="submit" value="Cadastrar">
                Cadastrar
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
