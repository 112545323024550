import React from "react";
import InputMask from "react-input-mask";
import './style.css'

const MaskedInput = ({ value, onChange }) => {
   return <InputMask
      mask="999.999.999-99"
      value={value}
      onChange={onChange}
      className='input-fgts'
      type="text"
      name=""
      id="input_cpf"
      placeholder="Insira o CPF"

   />;
};

export default MaskedInput;
