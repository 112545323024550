import axios from 'axios';

// Função para fazer a requisição GET para a URL especificada
export const fetchProducts = async () => {
  try {
    const response = await axios.get('https://api.simulator.agillecred.com.br/loans/products', {
      headers: {
        'x-api-key': 'superseguro', // Inclui a chave da API correta no cabeçalho
        'Accept': 'application/json', // Certifique-se de aceitar JSON na resposta
      },
    });
    
    return response.data; // Retorna os dados da resposta
  } catch (error) {
    console.error('Erro ao buscar os produtos:', error); // Mostra o erro no console para debugar
    throw error; // Repassa o erro para ser tratado no componente que chamar essa função
  }
};
