import React, { useState, useEffect } from 'react';
import './style.css';
import incluirQuali from '../JS/IncluirQuali';  // Certifique-se de que o caminho está correto
import incluirAgille from '../JS/incluirAgille'; // Incluindo a nova função para enviar ao Agille

const siglasEstadosDoBrasil = [
  'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA',
  'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
];

const ModalOut = ({ isOpen, onClose, setResultado }) => {
  const [cep, setCep] = useState('');
  const [endereco, setEndereco] = useState('');
  const [bairro, setBairro] = useState('');
  const [numero, setNumero] = useState('');
  const [estado, setEstado] = useState('');
  const [sexo, setSexo] = useState('');
  const [mae, setMae] = useState('');
  const [loading, setLoading] = useState(false); // Controle de carregamento

  // Limpa os campos quando o modal é fechado
  useEffect(() => {
    if (!isOpen) {
      setCep('');
      setEndereco('');
      setBairro('');
      setNumero('');
      setEstado('');
      setSexo('');
      setMae('');
    }
  }, [isOpen]);

  const handleConfirm = async () => {
    // Verifica se todos os campos foram preenchidos
    if (!cep || !endereco || !bairro || !numero || !estado || !sexo || !mae) {
      alert('Por favor, preencha todos os campos antes de confirmar.');
      return;
    }

    // Atualiza o resultado global com os novos dados de endereço
    if (typeof setResultado === "function") {
      const novoResultado = await new Promise((resolve) => {
        setResultado((prevResultado) => {
          const atualizado = {
            ...prevResultado,
            informacoesobrigatorias: {
              ...prevResultado.informacoesobrigatorias,
              cep,
              endereco,
              bairro,
              numero,
              estado,
              sexo,
              mae,
            },
          };
          resolve(atualizado); // Retorna o novo estado para a próxima operação
          return atualizado;
        });
      });

      console.log("Resultado atualizado após ModalOut:", novoResultado);

      setLoading(true);  // Ativa o estado de carregamento

      try {
        // Primeira requisição para o incluirQuali
        const respostaQuali = await incluirQuali(novoResultado);
        console.log('Dados enviados com sucesso para Quali:', respostaQuali);
        
        // Em seguida, a requisição para o incluirAgille, passando o `responseQuali`
        const respostaAgille = await incluirAgille(novoResultado, respostaQuali);
        console.log('Dados enviados com sucesso para Agille:', respostaAgille);

        alert('Dados enviados com sucesso para ambos Quali e Agille!');
      } catch (error) {
        console.error('Erro ao enviar os dados:', error);
        alert('Erro ao enviar os dados para Quali ou Agille.');
      } finally {
        setLoading(false);  // Desativa o estado de carregamento
        onClose();  // Fecha o modal após o envio dos dados
      }
    } else {
      console.error("setResultado não é uma função");
    }
  };

  const handleCepChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    if (value.length <= 8) { // Limita a 8 dígitos
      setCep(value);
    }
  };

  const handleNumeroChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    if (value.length <= 5) { // Limita a 5 dígitos
      setNumero(value);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Informações de Endereço</h2>

        <div className="input-group">
          <label htmlFor="cep">CEP:</label>
          <input
            type="text"
            id="cep"
            value={cep}
            onChange={handleCepChange} // Aceita apenas números e limita a 8 dígitos
            placeholder="Digite o CEP"
            maxLength="8"
          />
        </div>

        <div className="input-group">
          <label htmlFor="endereco">Endereço:</label>
          <input
            type="text"
            id="endereco"
            value={endereco}
            onChange={(e) => setEndereco(e.target.value)}
            placeholder="Digite o endereço"
          />
        </div>

        <div className="input-group">
          <label htmlFor="bairro">Bairro:</label>
          <input
            type="text"
            id="bairro"
            value={bairro}
            onChange={(e) => setBairro(e.target.value)}
            placeholder="Digite o bairro"
          />
        </div>

        <div className="input-group">
          <label htmlFor="numero">Número:</label>
          <input
            type="text"
            id="numero"
            value={numero}
            onChange={handleNumeroChange} // Aceita apenas números e limita a 5 dígitos
            placeholder="Digite o número"
            maxLength="5"
          />
        </div>

        <div className="input-group">
          <label htmlFor="estado">Estado:</label>
          <select id="estado" value={estado} onChange={(e) => setEstado(e.target.value)}>
            <option value="">Selecione o estado</option>
            {siglasEstadosDoBrasil.map((sigla) => (
              <option key={sigla} value={sigla}>
                {sigla}
              </option>
            ))}
          </select>
        </div>

        <div className="input-group">
          <label htmlFor="mae">Nome da Mãe:</label>
          <input
            type="text"
            id="mae"
            value={mae}
            onChange={(e) => setMae(e.target.value)}
            placeholder="Digite o nome da mãe"
          />
        </div>

        <div className="input-group">
          <label htmlFor="sexo">Sexo:</label>
          <select id="sexo" value={sexo} onChange={(e) => setSexo(e.target.value)}>
            <option value="">Selecione</option>
            <option value="Masculino">Masculino</option>
            <option value="Feminino">Feminino</option>
          </select>
        </div>

        <div className="modal-actions">
          <button className="btn-cancel" onClick={onClose} disabled={loading}>
            Cancelar
          </button>
          <button className="btn-confirm" onClick={handleConfirm} disabled={loading}>
            {loading ? 'Enviando...' : 'Confirmar'}
          </button>
        </div>

        {/* Modal de carregamento */}
        {loading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <p>Enviando dados, por favor, aguarde...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalOut;
