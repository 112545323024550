import styled from "styled-components";
// container com a sombra em volta da tabela de usuario
export const ContainerShadow = styled.div`
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
`;
export const ContainerAll = styled.div`
  width: 100%;
  height: 89vh;
  background-color: #f3f4f6;
  font-family: Arial, Helvetica, sans-serif;
`;
export const ContainerPessoa = styled.div`
  width: 35%;
  height: auto;
  padding: 1em;
  background-color: #e2e2e2;
  border: 0;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  color: #424242;
  display: block;
`;
export const PessoaAll = styled.div`
  text-align: center;
  flex-wrap: wrap;
  padding: 1em;
`;
export const ContainerFoto = styled.div`
  width: 100%;
  height: 100%;
`;
export const FotoPessoa = styled.img`
  /* background-color: #01012b; */
  background-color: transparent;
  object-fit: cover;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  margin-bottom: 15px;
`;
export const Nome = styled.span`
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  font-family: sans-serif;
  font-weight: 500;
`;
export const ContainerEmail = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const TagEmail = styled.span`
  display: flex;
  font-weight: bold;
  font-size: 1.15rem;
  font-family: var(--font-secundaria);
`;
export const Email = styled.span`
  font-size: 20px;
  font-family: var(--font-secundaria);
`;
export const ContainerCargo = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`;
export const TagCargo = styled.span`
  display: flex;
  font-weight: bold;
  font-size: 1.15rem;
  font-family: var(--font-secundaria);
`;
export const Cargo = styled.span`
  font-size: 20px;
  font-family: var(--font-secundaria);
`;
export const ContainerSupervisor = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
`;
export const TagSupervisor = styled.span`
  display: flex;
  font-weight: bold;
  font-size: 1.15rem;
  font-family: var(--font-secundaria);
`;
export const Supervisor = styled.span`
  margin-left: 25px;
  font-size: 20px;
  font-family: var(--font-secundaria);
`;
export const ContainerInfos = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
`;
export const TagInfos = styled.span`
  display: flex;
  font-weight: bold;
  font-size: 1.15rem;
  font-family: var(--font-secundaria);
`;
export const Infos = styled.span`
  margin-left: 25px;
  font-size: 20px;
  font-family: var(--font-secundaria);
`;
export const ContainerTeste = styled.div`
  width: 100%;
  height: 50%;
  background-color: #ccc;
`;
