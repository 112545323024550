import { useEffect } from "react";
import { useState } from "react";
import { consultarProposta } from "../../../api/api-facta";
import UseToken from "../../../hooks/UseToken";
import NavBar from "../../pages/Navbar";
import styles from "./ConsultaFacta.module.css";
import LogoFacta from "../../../img/logo-facta.png";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import MaskedInput from "./js/Mask";
import { useParams } from "react-router-dom";

export default function ConsultaFacta2() {
  const {cpf} = useParams();
  const [cpf_value, setCPF] = useState("");
  const [proposta, setProposta] = useState();
  const [itemAtivo, setItemAtivo] = useState(1);
  const [carregar, setCarregar] = useState(false);

  useEffect(() => {
    document.title = "Consulta de Proposta";
    if(cpf != null && cpf.length === 11){
      setCPF(cpf);
      setTimeout(() => {
        setCarregar(true);
        handleConsultaProposta();
        setCarregar(false);
      }, 500);
    }
  }, []);

  const handleConsultaProposta = async (e) => {
    if(e){
      e.preventDefault();
    };
    setCarregar(true);
    if (cpf_value.includes("_") === false) {
      var cpf_alt = cpf_value.replaceAll(".", "").replaceAll("-", "");
      const fetchData = async () => {
        if(cpf){
          if(cpf.length === 11 && /^[0-9]+$/.test(cpf)){
            return await consultarProposta(UseToken(), cpf);
          }
        }else if(cpf_alt){
          return await consultarProposta(UseToken(), cpf_alt);
        }
      };
      fetchData()
        .then((result) => {
          if (result && result.length >= 1) {
            setItemAtivo(1);
            setProposta(result);
          } else if (result.response.data.mensagem) {
            alert(result.response.data.mensagem);
          } else {
            alert("Erro no servidor. Tente novamente mais tarde.");
          }
        })
        .catch(function (error) {
          console.error(error);
        });
      setCarregar(false);
    } else {
      alert("Insira um CPF valido.");
    }
    setCarregar(false);
  };

  const handleItemMinus = (_e) => {
    setItemAtivo(itemAtivo - 1);
  };

  const handleItemPlus = (_e) => {
    setItemAtivo(itemAtivo + 1);
  };
  return (
    <>
      <NavBar carregar={carregar} />
      <section className={styles.container_geral_consultaFacta2}>
        <div className={styles.container_img_search_facta2}>
          <div className={styles.container_logo_facta2}>
            <img
              className={styles.logo_facta2}
              src={LogoFacta}
              alt="Logo do Banco Facta"
            />
          </div>
          <div className={styles.container_title_facta2}>
            <span>Consulta</span>
            <br />
            <span>Facta</span>
          </div>
          <form className={styles.container_input_facta2}>
            <label className={styles.label_facta2} htmlFor="CPF">
              CPF
            </label>
            <br />
            <MaskedInput
              type="search"
              value={cpf_value}
              onChange={(event) => setCPF(event.target.value)}
            />
            <div className={styles.container_btn_consultar_facta2}>
              <button type="submit" onClick={handleConsultaProposta}>
                Consultar
              </button>
            </div>
          </form>
        </div>
        {proposta?.length >= 1 ? (
          <div className={styles.container_content_table_facta2}>
            <button
              className={styles.icon_next_back}
              type="submit"
              onClick={handleItemMinus}
              disabled={proposta?.length - (proposta?.length - 1) == itemAtivo}
            >
              {<MdOutlineArrowBackIosNew />}
            </button>
            {/* conteudo da tabela */}
            <div className={styles.container_table_facta2}>
              <table>
                <thead>
                  <tr>
                    <th> Data Movimento</th>
                    <th> Hora Movimento</th>
                    <th> Averbador</th>
                    <th> Cliente</th>
                    <th> Número Contrato (Agilus)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={styles.firstTD}>
                      {proposta ? proposta[itemAtivo - 1].data_movimento : ""}
                    </td>
                    <td className={styles.firstTD}>
                      {proposta
                        ? proposta[itemAtivo - 1].hora_movimento_ocorrencia
                        : ""}
                    </td>
                    <td className={styles.firstTD}>
                      {proposta ? proposta[itemAtivo - 1].averbador : ""}
                    </td>
                    <td className={styles.firstTD}>
                      {proposta ? proposta[itemAtivo - 1].cliente : ""}
                    </td>
                    <td className={styles.firstTD}>
                      {proposta ? proposta[itemAtivo - 1].codigo_af : ""}
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th>Convênio</th>
                    <th>CPF</th>
                    <th>Data Digitação</th>

                    <th>Valor Prestação</th>
                    <th>Número Prestação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{proposta ? proposta[itemAtivo - 1].convenio : ""}</td>
                    <td>{proposta ? proposta[itemAtivo - 1].cpf : ""}</td>
                    <td>
                      {proposta ? proposta[itemAtivo - 1].data_digitacao : ""}
                    </td>
                    <td>
                      {proposta ? proposta[itemAtivo - 1].vlrprestacao : ""}
                    </td>
                    <td>
                      {proposta ? proposta[itemAtivo - 1].numeroprestacao : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <button
              className={styles.icon_next_back}
              onClick={handleItemPlus}
              disabled={proposta?.length == itemAtivo}
            >
              {" "}
              {<MdOutlineArrowForwardIos />}{" "}
            </button>
          </div>
        ) : (
          <span>{}</span>
        )}
        {proposta?.length >= 1 ? (
          <div className={styles.container_button_backNext}>
            <button className={styles.contador_page}>
              {itemAtivo} / {proposta?.length}
            </button>
          </div>
        ) : (
          <span></span>
        )}
      </section>
    </>
  );
}
