// JS/fetchTabelas.js

import { agilusAPI } from "../../../../api/axios";

export const fetchTabelas = async () => {
  const url =
    "ProdutoConsignado/ListarParaVenda?convenio=43380&banco=145933&tipoTabela=3";

  try {
    const response = await agilusAPI.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Houve um erro ao buscar as tabelas:", error);
  }
};
