import axios from 'axios';

export async function getVersionManifest(){
    // get the version field from the manifest.json file
    try {
        const response = await axios.get('http://193.203.183.114:1990/manifest.json');
        return response.data.version;
    } catch (error) {
        console.error(error);
    }
}
