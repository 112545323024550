const camposFixos = {
	TipoContrato: 3,
	TipoOperacao: 2,
	PromotoraId: 146,
	PromotoraIdAgille: 319,
	Naturalidade: "Brasileira",
	EstadoCivil: 0,
	ConvenioId: 43041,
	TipoDocumento: 0,
	BancoIdFixo: 137683,
	BancoIdQuali: 137683,
	PromotoraQuali : 319,
};

export default camposFixos;