import "./style.css";
import Navbar from "../../pages/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { cidadesCrefaz, propostaCredfaz } from "../../../api/api-portal";
import UseToken from "../../../hooks/UseToken";

export default function Crefaz() {
  const [estados, setEstados] = useState([]);
  const [carregar, setCarregar] = useState(false);
  const [estado, setEstado] = useState("");
  const [cidades, setCidades] = useState([]);
  const [ocupacao, setOcupacao] = useState("");
  const [consulta, setConsulta] = useState([]);
  const [celular, setCelular] = useState("");

  //formatar celular com DDD
  useEffect(() => {
    var cel = celular.replace(/\D/g, "");
    cel = cel.replace(/^(\d{3})(\d)/g, "($1) $2");
    cel = cel.replace(/(\d)(\d{4})$/, "$1-$2");
    setCelular(cel);
  }, [celular]);

  const handleProposta = async (_e) => {
    setCarregar(true);
    var proposta = JSON.stringify({
      nome: document.getElementById("nome").value,
      cpf: document.getElementById("cpf").value,
      nascimento: document.getElementById("data").value,
      telefone: document.getElementById("telefone").value,
      ocupacaoId: ocupacao,
      cep: document.getElementById("cep").value,
      cidadeId: document.getElementById("cidade").value,
      bairro: document.getElementById("bairro").value,
      logradouro: document.getElementById("logradouro").value,
    });
    if (Object.values(proposta).every(Boolean)) {
      var result = await propostaCredfaz(UseToken(), proposta);
      if (!!result) {
        setConsulta(result);
      }
    }
    setCarregar(false);
  };

  useEffect(() => {
    document.title = "Consulta Credfaz";
    setCarregar(true);
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados/")
      .then((response) => {
        response.data.forEach((estado) => {
          delete estado.regiao;
        });
        response.data.sort(function (a, b) {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        });
        setEstados(response.data);
      });
    setCarregar(false);
  }, []);

  useEffect(() => {
    if (!!estado) {
      const fetchCidades = async () => {
        setCidades(await cidadesCrefaz(UseToken(), estado));
      };
      fetchCidades();
    }
  }, [estado]);

  return (
    <>
      <Navbar carregar={carregar} />
      <section className="container-crefaz">
        <div className="container-title-crefaz">
          <h2 className="title-crefaz">Consulta Crefaz</h2>
        </div>
        <div className="container-geral-crefaz">
          <form action="#">
            <div className="user__details">
              <div className="input__box">
                <span className="details">Nome</span>
                <input type="text" id="nome" required />
                <i aria-hidden="true" className="fa-solid fa-user"></i>
              </div>
              <div className="input__box-cpf">
                <span className="details">CPF</span>
                <input type="text" id="cpf" required />
                <i aria-hidden="true" className="fa-solid fa-id-card"></i>
              </div>
              <div className="input__box">
                <span className="details">Data de Nascimento</span>
                <input type="date" id="data" required />
                <i aria-hidden="true" className="fa-solid fa-calendar-days"></i>
              </div>
              <div className="input__box">
                <span className="details">Telefone</span>
                <input
                  // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  id="telefone"
                  type="tel"
                  min="0"
                  value={celular}
                  maxLength="16"
                  pattern="(\d{3})\s*\d{5}-\d{4}"
                  onChange={(e) => setCelular(e.target.value)}
                />
                <i aria-hidden="true" className="fa-solid fa-phone"></i>
              </div>
              <div className="input__box">
                <span className="details">Ocupação</span>
                <select
                  className="details"
                  name=""
                  id="ocupacao"
                  onChange={(e) => setOcupacao(e.target.value)}
                >
                  <option required hidden value=""></option>
                  <option value="1">Assalariado</option>
                  <option value="2">Funcionário Público</option>
                  <option value="3">Aposentado</option>
                  <option value="4">Pensionista</option>
                  <option value="5">Autônomo / Sem Vínculo Empregatício</option>
                  <option value="6">Profissional Liberal</option>
                  <option value="7">Empresário / Proprietário</option>
                  <option value="8">Outros</option>
                </select>
              </div>

              <div className="input__box">
                <span className="details">Estado</span>
                <select
                  className="details"
                  name=""
                  id="estado"
                  onChange={(e) => setEstado(e.target.value)}
                >
                  <option required hidden value=""></option>
                  {estados?.map((estado) => (
                    <option
                      value={estado.sigla}
                      key={estado.id}
                      label={estado.nome}
                    >
                      {estado.sigla}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input__box">
                <span className="details">Cidade</span>
                <select
                  className="details"
                  name=""
                  id="cidade"
                  disabled={!estado}
                >
                  <option required hidden value=""></option>
                  {cidades?.map((cidade) => (
                    <option value={cidade.id} key={cidade.codigo}>
                      {cidade.nome}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input__box">
                <span className="details">CEP</span>
                <input type="text" id="cep" />
                <i aria-hidden="true" className="fa-solid fa-location-dot"></i>
              </div>
              <div className="input__box">
                <span className="details">Bairro</span>
                <input type="text" id="bairro" />
                <i aria-hidden="true" className="fa-solid fa-location-dot"></i>
              </div>
              <div className="input__box-cpf">
                <span className="details">Logradouro</span>
                <input type="text" id="logradouro" />
                <i aria-hidden="true" className="fa-solid fa-road"></i>
              </div>
            </div>
            <div className="button">
              <input
                type="submit"
                value="Consultar"
                onClick={handleProposta}
                disabled={!!carregar}
              />
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
