import axios from 'axios';

const incluirQuali = async (novoResultado) => {
  try {
    const config = {
      headers: {
        'x-api-key': 'superseguro',
        'Content-Type': 'application/json',
      },
    };

    // Certifique-se de que todas as propriedades estão presentes no novoResultado
    const infoObrigatorias = novoResultado?.informacoesobrigatorias || {};
    const contratosSelecionados = novoResultado?.contratosSelecionados || [];
    const camposFixos = novoResultado?.camposFixos || {};

    // Construindo o JSON conforme o modelo fornecido
    const dadosParaEnvio = {
      name: infoObrigatorias.nome,
      documentNumber: infoObrigatorias.cpf,
      benefitNumber: infoObrigatorias.beneficio,
      benefitTypeCode: infoObrigatorias.especie,
      birthDate: infoObrigatorias.dtNascimento?.toISOString(), // Verifica se existe e converte para formato ISO
      motherName: infoObrigatorias.mae,
      maritalStatus: infoObrigatorias.estadoCivil || "Solteiro", // Padrão para Solteiro, ajuste conforme necessário
      sex: infoObrigatorias.sexo,
      benefitAmount: infoObrigatorias.valorBruto || 0,
      phoneNumber: `${infoObrigatorias.ddd}${infoObrigatorias.telefone}`, // Juntando DDD e telefone
      email: infoObrigatorias.email, // Ajustar conforme necessário
      address: {
        street: infoObrigatorias.endereco,
        number: infoObrigatorias.numero,
        complement: infoObrigatorias.complemento || "Casa", // Ajustar se necessário
        district: infoObrigatorias.bairro,
        city: infoObrigatorias.cidade,
        state: infoObrigatorias.estado,
        zipCode: infoObrigatorias.cep,
      },
      bankAccount: {
        bankCode: contratosSelecionados[0]?.bankCode,
        agency: infoObrigatorias.agencia,
        accountNumber: infoObrigatorias.conta,
      },
      proposalNumber: contratosSelecionados[0]?.numeroContrato,  // Usando o primeiro contrato selecionado
      contractNumber: '',  // Usando o primeiro contrato selecionado
      signatureLink: infoObrigatorias.signatureLink,
      extraFields: {}, // Ajustar conforme necessário
      document: {
        type: "RG",
        documentNumber: infoObrigatorias.cpf,
        number: infoObrigatorias.rg,
        issuingDate: infoObrigatorias.dtEmissao , // Data padrão, ajuste conforme necessário
        issuingEntity: infoObrigatorias.orgaoExpedidor || "SSP/SP",
        issuingState: infoObrigatorias.estado,
        extraFields: {
          tabelaAgilus: contratosSelecionados[0]?.tabelaSelecionada.idAgille,
        },
        fileFrontId: infoObrigatorias.idFrente,
        fileBackId: infoObrigatorias.idVerso,
        statusCode: 1,
      },
      simulation: {
        productCode: contratosSelecionados[0]?.tabelaSelecionada.productCode , // Produto da simulação
        originalContract: {
          lenderCode: contratosSelecionados[0]?.bankCode,
          number: contratosSelecionados[0]?.numeroContrato,
          installmentValue: parseFloat(contratosSelecionados[0]?.valorParcela),
          installmentsRemaining: parseInt(contratosSelecionados[0]?.prazoRestante, 10),
          loanBalanceValue: parseFloat(contratosSelecionados[0]?.saldo),
        },
      },
      statusCode: 1, // Código de status
    };

    console.log('Dados para envio:', dadosParaEnvio);  // Debug para verificar o JSON final

    // Fazendo o POST para a API com os dados
    const response = await axios.post(
      'https://api.simulator.agillecred.com.br/loans/register',
      dadosParaEnvio,
      config
    );

    // Retornando a resposta da API
    return response.data;
  } catch (error) {
    console.error('Erro ao incluir qualificação:', error.response?.data || error.message || error.detail);
    throw error;
  }
};

export default incluirQuali;
