import NavBar from "../Navbar";
import styles from "./roteiro.module.css"
import data from "./bancos.json"
// import logoInbursa from './inbursa-logo.png'

// const logos = {
//     Inbursa: logoInbursa
// }

export default function Roteiros() {

    function createLink(id) {
        return `https://drive.google.com/file/d/${id}/view?usp=sharing`
    }

    document.title = "Roteiros";

    return (
        <>
            <NavBar />
            <div className={styles.title}>
                <h1 className={styles.titleName}>Roteiros</h1>
            </div>

            {data.map((banco) => (
                <>
                {/* <div className={styles.logo}>
                    <img className={styles.imgLogo} src={logos[banco.nome]} alt={banco.nome}></img>
                </div> */}
                <div className={styles.pdfs}>
                    {banco.roteiros.filter(roteiro => roteiro.nome !== "Digitação").map((roteiro) => (
                        <div className={styles.pdfItem}>
                            <h2 className={styles.pdfNome}>
                                <a target="_blank" href={createLink(roteiro.id)} className={styles.pdfNome} rel="noreferrer">
                                    {roteiro.nome}
                                </a>
                            </h2>
                                <p className={styles.pdfDesc}>
                                    {roteiro.desc}
                                </p>
                        </div>
                    ))}

                    {banco.roteiros.filter(roteiro => roteiro.nome === "Digitação").map((roteiro) => (
                        <div className={styles.pdfItem}>
                            <h2 className={styles.pdfNome}>{roteiro.nome}</h2>
                            <p className={styles.pdfDesc}>{roteiro.desc}</p>
                            <ul className={styles.List}>
                            {roteiro.tipos.map((tipo) => (
                                <li>
                                    <a target="_blank" href={createLink(tipo.id)} className={styles.pdfNome} rel="noreferrer">
                                        {tipo.nome}
                                    </a>
                                </li>
                            ))}
                            </ul>
                        </div>
                    ))}
                </div>
                </>
            ))}
        </>
    )
}