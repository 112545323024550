import moment from "moment";
import 'moment/locale/pt-br'
import React, { Component } from "react";
import "./feed.css";
moment.locale('pt-br')

class Feed extends Component {
  render() {
    return (
      <div className="Initial">
        <h2 className="animate-charcter">Feed de Noticias</h2>
        <div className="feed">
          <div className="post">
            {this.props?.posts?.map((post) => (
              <div key={post._id}>
                <h1>{post.titulo}</h1>
                <span className="Conteudo">{post.conteudo}</span>
                <p>{moment(post.createdAt).format('LLL')}</p>
                <hr />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Feed;
